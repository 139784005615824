export const GET_ADDRESS_PAYMENT_REQUEST = 'GET_ADDRESS_PAYMENT_REQUEST';
export const GET_ADDRESS_PAYMENT_SUCCESS = 'GET_ADDRESS_PAYMENT_SUCCESS';
export const GET_ADDRESS_PAYMENT_FAILURE = 'GET_ADDRESS_PAYMENT_FAILURE';

const initialState = {
  addressItem: null,
  loading: false,
  errors: null,
};

const getChannelPayment = ({ brandId, walletId, channelId }) => ({
  type: GET_ADDRESS_PAYMENT_REQUEST,
  brandId,
  walletId,
  channelId,
});

export const actions = {
  getChannelPayment,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADDRESS_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ADDRESS_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        addressItem: action.addressItem,
      };
    case GET_ADDRESS_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
