import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { array, object } from 'prop-types';
import { includes } from 'lodash';

const propTypes = {
  roles: array,
  user: object,
};

const defaultProps = {
  roles: [],
  user: {},
};

const RBACRoute = ({ roles, user, ...props }) => {
  if (user) {
    return includes(roles, user.role)
      ? <Route {...props} />
      : <Redirect to="/wallets" />;
  }
  return null;
};

RBACRoute.propTypes = propTypes;
RBACRoute.defaultProps = defaultProps;

export default RBACRoute;
