import React, { useCallback, useState, useEffect } from 'react';
import { bool, func, object, arrayOf } from 'prop-types';
import Button from 'tc-biq-design-system/build/Button';
import { t, Trans } from '@lingui/macro';
import Modal from 'tc-biq-design-system/build/Modal';
import Space from 'tc-biq-design-system/build/Space';
import Input from 'tc-biq-design-system/build/Input';
import Select from 'tc-biq-design-system/build/Select';
import Divider from 'App/components/Divider';
import { exchangePairs } from 'Wallet/components/ExchangeModal/enums';
import { currencySymbols } from 'Wallet/constants';
import exchangeService from 'Wallet/services/exchange';
import { formatDate } from 'App/helpers/date';

import './ExchangeModal.scss';

const propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  i18n: object.isRequired,
  wallet: object.isRequired,
  wallets: arrayOf(object).isRequired,
  currentBrand: object.isRequired,
};

const ExchangeModal = (
  {
    visible,
    onClose,
    i18n,
    wallet,
    wallets,
    currentBrand,
  },
) => {
  const formatWalletLabel = useCallback(item => `${item.currency} (${currencySymbols[item.currency]}${item.available})`);

  const defaultValues = {
    from: { ...wallet, label: formatWalletLabel(wallet), value: wallet.id },
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [isExchangeSuccessful, setExchangeSuccess] = useState(false);
  const [exchangeInfo, setExchangeInfo] = useState(null);
  const [channel, setChannel] = useState(null);
  const [hasInsufficientBalanceError, setHasInsufficientBalanceError] = useState(false);

  useEffect(() => calculateExchange(), [formValues.amount, formValues.from, formValues.to]);
  useEffect(() => {
    if (isExchangeSuccessful && exchangeInfo && process.env.PUSHER) {
      if (!channel) {
        /* global pusher */
        const foo = pusher.subscribe(exchangeInfo.id);
        setChannel(exchangeInfo.id);
        foo.bind('Exchange', (data) => {
          setExchangeInfo({ ...data });
        });
      }
    }
  }, [isExchangeSuccessful]);
  useEffect(() => {
    if (parseFloat(formValues.amount) > parseFloat(formValues.from.available)) {
      setHasInsufficientBalanceError(true);
    } else {
      setHasInsufficientBalanceError(false);
    }
  }, [formValues.amount, formValues.from.available]);

  const updateFieldValue = (name, value) => setFormValues(prevState => ({
    ...prevState,
    [name]: value,
  }));

  const getExchangeRate = useCallback(() => {
    const base = formValues.from && formValues.from.currency;
    const quote = formValues.to && formValues.to.currency;
    if (!base || !quote) return;
    exchangeService.getRates(base, quote).then((response) => {
      const { data } = response;
      setFormValues(prevState => ({ ...prevState, rate: data.bid }));
    });
  }, [formValues.from, formValues.to]);

  const calculateExchange = useCallback(() => {
    const from_currency = formValues.from && formValues.from.currency;
    const to_currency = formValues.to && formValues.to.currency;
    const { amount } = formValues;
    if (!from_currency || !to_currency || amount <= 0) {
      getExchangeRate();
      return;
    }

    exchangeService.calculateExchange(from_currency, to_currency, amount).then((response) => {
      const { data } = response;
      setFormValues(prevState => ({ ...prevState, rate: data.rate, fee: data.fee_amount, to_receive: data.to_receive }));
    });
  });

  const resetModal = () => {
    setExchangeInfo(null);
    setFormValues(defaultValues);
    setExchangeSuccess(false);
    setChannel(null);
    onClose();
  };

  const footerRender = submitHandler => (
    !isExchangeSuccessful ? (
      <div className="withdraw-footer">
        <Button color="ghost" onClick={onClose}>
          <Trans>Discard</Trans>
        </Button>

        <Button onClick={submitHandler} disabled={hasInsufficientBalanceError}>
          <Trans>Exchange</Trans>
        </Button>
      </div>
    ) : (
      <div>
        <Button onClick={resetModal}>
          <Trans>Close</Trans>
        </Button>
      </div>
    )
  );

  const getExchangeOptions = useCallback(({ options }) => (options.map(item => (
    { ...item,
      label: formatWalletLabel(item),
      value: item.id,
    }))), []);

  const getAvailableExchangeCurrencies = useCallback(({ fromWallet, toWallet }) => (
    exchangePairs[fromWallet.currency].includes(toWallet.currency)
  ), []);

  const onSubmit = useCallback(() => {
    exchangeService.createItem(currentBrand.id, {
      from: formValues.from.id,
      to: formValues.to.id,
      exchange_amount: formValues.amount,
    }).then((response) => {
      const { data } = response;
      setExchangeInfo({ ...data });
      setExchangeSuccess(true);
    }, () => {
      setExchangeSuccess(true);
    });
  }, [formValues]);

  return (
    <Modal
      visible={visible}
      icon="Marketing"
      title="Exchange"
      confirmText={i18n._(t`Exchange`)}
      footerRender={() => footerRender(onSubmit)}
    >

      {!isExchangeSuccessful && (
        <div>
          <Select
            name="from"
            placeholder={i18n._(t`From`)}
            label={i18n._(t`From`)}
            clearable={false}
            options={getExchangeOptions({
              options: [wallet],
            })}
            type="single"
            required
            value={formValues.from}
            onChange={item => updateFieldValue('from', item)}
          />

          <Space size={11} />

          <Select
            name="to"
            placeholder={i18n._(t`To`)}
            label={i18n._(t`To`)}
            clearable={false}
            options={getExchangeOptions({
              options: wallets.filter(toWallet => getAvailableExchangeCurrencies({
                fromWallet: formValues.from,
                toWallet,
              })),
            })}
            type="single"
            value={formValues.to}
            onChange={item => updateFieldValue('to', item)}
            required
          />

          <Space size={11} />

          <Input
            name="amount"
            type="number"
            placeholder={`0.00 ${formValues.from.currency}`}
            label={i18n._(t`Amount`)}
            value={formValues.amount}
            onChange={e => updateFieldValue('amount', e.target.value)}
            hasError={hasInsufficientBalanceError}
            helpText={
              hasInsufficientBalanceError
                ? i18n._(t`Insufficient balance`)
                : ''
            }
            required
            disabled={!formValues.from || !formValues.to}
          />

          <Space size={16} />

          <div className="modal__info">
            <div className="modal__item">
              <div className="modal__item__title"><Trans>Exchange rate</Trans></div>

              <div className="modal__item__value">{formValues.rate || '0.00'}</div>
            </div>
          </div>

          <div className="modal__info">
            <div className="modal__item">
              <div className="modal__item__title"><Trans>Exchange fee</Trans></div>

              <div className="modal__item__value">{formValues.fee || '0.00'}</div>
            </div>
          </div>

          <Divider spaceBottom={10} spaceTop={24} />

          <div className="modal__info">
            <div className="modal__item">
              <div className="modal__item__title"><Trans>Amount received</Trans></div>

              <div className="modal__item__value modal__item__big">
                {'~'}
                {formValues.amount && formValues.to_receive}
                {' '}
                {formValues.to && formValues.to.currency}
              </div>
            </div>
          </div>
        </div>
      )}

      {isExchangeSuccessful && (
        <div className="modal__info">
          {exchangeInfo.status === 'filled' && (
            <div
              style={{
                fontSize: '45px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '50px',
              }}
            >
              <svg
                data-v-4b4681fb=""
                viewBox="0 0 93 93"
                xmlns="http://www.w3.org/2000/svg"
                data-v-faa58b52=""
                style={{
                  stroke: '#23d655',
                  width: '1em',
                  height: '1em',
                  fill: 'none',
                }}
              >
                <circle opacity=".9" cx="46.5" cy="46.5" r="45.5" strokeWidth="4" />
                <path
                  d="M70 30L38.68 64 24 49.87"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span style={{ fontSize: '30px', paddingLeft: '10px' }}>Exchange successful</span>
            </div>
          )}

          {exchangeInfo.status !== 'filled' && (
            <div
              className="lds-roller"
              style={{
                fontSize: '45px',
                marginBottom: '50px',
              }}
            >
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          )}

          <div className="modal__item">
            <div className="modal__item__title">From</div>
            <div className="modal__item__value">
              {exchangeInfo.from_currency}
              {' '}
              Wallet
            </div>
          </div>

          <div className="modal__item">
            <div className="modal__item__title">To</div>
            <div className="modal__item__value">
              {exchangeInfo.to_currency}
              {' '}
              Wallet
            </div>
          </div>

          <div className="modal__item">
            <div className="modal__item__title">Requested amount</div>
            <div className="modal__item__value">
              {exchangeInfo.exchange_amount}
              &nbsp;
              {formValues.from.currency}
            </div>
          </div>

          <div className="modal__item">
            <div className="modal__item__title">Status</div>
            <div className="modal__item__value">{exchangeInfo.status}</div>
          </div>

          <div className="modal__item">
            <div className="modal__item__title">Created at</div>
            <div className="modal__item__value">{formatDate(exchangeInfo.created_at)}</div>
          </div>
        </div>
      )}
    </Modal>
  );
};

ExchangeModal.propTypes = propTypes;

export default ExchangeModal;
