export const GET_QRCODE_REQUEST = 'GET_QRCODE_REQUEST';
export const GET_QRCODE_SUCCESS = 'GET_QRCODE_SUCCESS';
export const GET_QRCODE_FAILURE = 'GET_QRCODE_FAILURE';

export const GET_QRCODE_INFO_REQUEST = 'GET_QRCODE_INFO_REQUEST';
export const GET_QRCODE_INFO_SUCCESS = 'GET_QRCODE_INFO_SUCCESS';
export const GET_QRCODE_INFO_FAILURE = 'GET_QRCODE_INFO_FAILURE';

export const CONFIRM_QRCODE_REQUEST = 'CONFIRM_QRCODE_REQUEST';
export const CONFIRM_QRCODE_SUCCESS = 'CONFIRM_QRCODE_SUCCESS';
export const CONFIRM_QRCODE_FAILURE = 'CONFIRM_QRCODE_FAILURE';

export const DELETE_MFA_REQUEST = 'DELETE_MFA_REQUEST';
export const DELETE_MFA_SUCCESS = 'DELETE_MFA_SUCCESS';
export const DELETE_MFA_FAILURE = 'DELETE_MFA_FAILURE';

const getQRCodeInfo = () => ({
  type: GET_QRCODE_INFO_REQUEST,
});

const getQRCode = () => ({
  type: GET_QRCODE_REQUEST,
});

const confirmQRCode = token => ({
  type: CONFIRM_QRCODE_REQUEST,
  token,
});

const deleteQRCode = ({ token, id }) => ({
  type: DELETE_MFA_REQUEST,
  token,
  id,
});

export const actions = {
  getQRCode,
  confirmQRCode,
  deleteQRCode,
  getQRCodeInfo,
};

const initialState = {
  code: null,
  info: null,
  confirm: false,
  loading: false,
  errors: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_QRCODE_REQUEST:
    case GET_QRCODE_INFO_REQUEST:
    case CONFIRM_QRCODE_REQUEST:
    case DELETE_MFA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_QRCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload,
      };
    case GET_QRCODE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case CONFIRM_QRCODE_SUCCESS:
    case DELETE_MFA_SUCCESS:
      return {
        ...state,
        loading: false,
        confirm: action.payload,
      };
    case GET_QRCODE_FAILURE:
    case GET_QRCODE_INFO_FAILURE:
    case CONFIRM_QRCODE_FAILURE:
    case DELETE_MFA_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
