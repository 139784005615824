import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withI18n } from '@lingui/react';

import { actions as userActions } from 'User/redux/models/user';
import Profile from './Profile';

const mapStateToProps = ({ auth: { user }, userItem }) => ({
  user,
  loading: userItem.submitLoading,
  errors: userItem.submitErrors,
  submitStatus: userItem.submitStatus,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    updateUser: userActions.updateUser,
    clearUser: userActions.clearUser,
  },
  dispatch),
});


export default compose(
  withI18n(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Profile);
