import { compose } from 'redux';
import { connect } from 'react-redux';
import { withI18n } from '@lingui/react';

import InvoiceSidepanel from './InvoiceSidepanel';

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default compose(
  withI18n(),
  connect(mapStateToProps),
)(InvoiceSidepanel);
