import React from 'react';
import { object, string, func } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import useTable from 'App/components/Table';
// import SwitchCell from 'App/components/TableCells/SwitchCell';
import ActionCellRenderer from 'App/components/TableCells/ActionCell';
import CurrencyCell from 'App/components/TableCells/CurrencyCell';
import { notifier } from 'tc-biq-design-system/build/Notification';
import BankAccountService from '../../../services/bankAccount';

const TABLE_ID = 'BANK_ACCOUNT_TABLE';

const propTypes = {
  resource: string.isRequired,
  i18n: object.isRequired,
  onEdit: func.isRequired,
  currentBrand: object,
  query: object.isRequired,
  actions: object.isRequired,
};

const defaultProps = {
  currentBrand: null,
};

const BankAccountsTable = ({ resource, i18n, onEdit, currentBrand, query, actions }) => {
  const [Table] = useTable(TABLE_ID, resource);
  // const accountActiveToggle = () => {};
  const columnDefs = [
    /* {
      title: '',
      key: 'is_active',
      width: '70px',
      render: data => (SwitchCell({
        data,
        handleClick: accountActiveToggle,
      })),
    }, */
    {
      title: i18n._(t`Bank Name`),
      key: 'bank_name',
    },
    {
      title: i18n._(t`Bank Address`),
      key: 'bank_address',
    },
    {
      title: i18n._(t`Account Holder Name`),
      key: 'account_holder_name',
    },
    {
      title: i18n._(t`IBAN / Account Number`),
      key: 'IBAN',
    },
    {
      title: i18n._(t`Currency`),
      key: 'currency',
      render: data => <CurrencyCell currency={data.currency} />,
    },
    {
      title: i18n._(t`Description`),
      key: 'description',
    },
    {
      title: '',
      key: 'action',
      suppressSizeToFit: true,
      width: '100px',
      render: data => (
        ActionCellRenderer({
          onDelete,
          onEdit,
          data,
        })
      ),
    },
  ];

  const fields = [
    {
      key: 'bank_name',
      input: 'string',
      type: 'string',
      label: 'Bank Name',
      id: 'bank_name',
    },
    {
      key: 'account_holder_name',
      input: 'string',
      type: 'string',
      label: 'Account Holder Name',
      id: 'account_holder_name',
    },
    {
      key: 'IBAN',
      input: 'string',
      type: 'string',
      label: 'IBAN',
      id: 'IBAN',
    },
    {
      key: 'currency',
      input: 'string',
      type: 'string',
      label: 'Currency',
      id: 'currency',
    },
    {
      key: 'description',
      input: 'string',
      type: 'string',
      label: 'Description',
      id: 'description',
    },
  ];

  // TODO: Handle delete without refetching entire table.
  const onDelete = (id) => {
    BankAccountService.deleteItem({
      bid: currentBrand.id,
      aid: id,
    })
      .then(() => {
        notifier.success(<Trans>Bank account was deleted successfully</Trans>);
        actions.fetchTableData(query);
      })
      .catch(() => {
        notifier.error(<Trans>Delete failed</Trans>);
      });
  };

  return (
    <Table
      columnDefs={columnDefs}
      fields={fields}
      filterable
    />
  );
};

BankAccountsTable.propTypes = propTypes;
BankAccountsTable.defaultProps = defaultProps;

export default BankAccountsTable;
