/* eslint-disable camelcase */
export const displayName = (user) => {
  const { last_name, first_name, username } = user || {};
  if (username) {
    if (first_name && last_name) {
      return `${first_name} ${last_name}`;
    }
    return username;
  }
  return null;
};

export const getInitials = (user) => {
  const name = displayName(user);
  if (name) return name.split(' ').map(s => s[0]).join('');
  return ' ';
};
