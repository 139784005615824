import { withI18n } from '@lingui/react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { actions as authActions } from 'Auth/redux/models/auth';
import { actions as brandActions } from 'Brand/redux/models/brand';
import MainSidebar from './MainSidebar';

const mapStateToProps = ({ brand: { brands, currentBrand } }) => ({
  brands,
  currentBrand,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      logout: authActions.logout,
      getBrands: brandActions.getBrands,
      setCurrentBrand: brandActions.setCurrentBrand,
    },
    dispatch,
  ),
});

export default compose(
  withI18n(),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MainSidebar);
