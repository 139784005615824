import { all } from 'redux-saga/effects';

import { authSaga } from 'Auth/redux/sagas/auth';
import { brandItemSaga } from 'Brand/redux/sagas/brandItem';
import { brandSaga } from 'Brand/redux/sagas/brand';
import { walletSaga } from 'Wallet/redux/sagas/wallet';
import { userSaga } from 'User/redux/sagas/user';
import { addressSaga } from 'Wallet/redux/sagas/address';
import { addressPaymentSaga } from 'Wallet/redux/sagas/addressPayment';
import { apiKeySaga } from 'Brand/redux/sagas/apiKey';
import { bankAccountSaga } from 'BankAccount/redux/sagas/bankAccount';
import { bankAccountItemSaga } from 'BankAccount/redux/sagas/bankAccountItem';
import { withdrawSaga } from 'Wallet/redux/sagas/withdraw';
import { twoFaAuthSaga } from 'User/redux/sagas/qrCode';
import { exchangeSaga } from 'Wallet/redux/sagas/exchange';
import { tableSagaGenerator } from '../../components/Table/generators/saga';


export default function* rootSaga() {
  yield all([
    ...authSaga,
    ...brandSaga,
    ...walletSaga,
    ...brandItemSaga,
    ...userSaga,
    ...addressSaga,
    ...addressPaymentSaga,
    ...apiKeySaga,
    ...bankAccountSaga,
    ...bankAccountItemSaga,
    ...withdrawSaga,
    ...twoFaAuthSaga,
    ...exchangeSaga,
    ...tableSagaGenerator('BRANDS_TABLE'),
    ...tableSagaGenerator('ADDRESSES_TABLE'),
    ...tableSagaGenerator('ADDRESS_PAYMENT_TABLE'),
    ...tableSagaGenerator('USERS_TABLE'),
    ...tableSagaGenerator('API_KEYS_TABLE'),
    ...tableSagaGenerator('BANK_ACCOUNT_TABLE'),
    ...tableSagaGenerator('AUDIT_LOGS_TABLE'),
    ...tableSagaGenerator('WALLET_TRANSACTIONS_TABLE'),
    ...tableSagaGenerator('TRANSACTION_HISTORY_ADDRESS_PAYMENTS_TABLE'),
    ...tableSagaGenerator('TRANSACTION_HISTORY_BANK_WITHDRAWALS_TABLE'),
    ...tableSagaGenerator('TRANSACTION_HISTORY_CRYPTO_WITHDRAWALS_TABLE'),
    ...tableSagaGenerator('TRANSACTION_HISTORY_INVOICES_TABLE'),
    ...tableSagaGenerator('PENDING_WITHDRAWALS_TABLE'),
  ]);
}
