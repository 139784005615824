import { actionTypesGenerator } from '../../../helpers/redux';

/**
 * Generate table action types
 * @param {string} key
 *
 * @returns {object} actionTypes
 */
export const tableActionTypesGenerator = key => actionTypesGenerator(key, [
  'FETCH_TABLE_DATA_REQUEST',
  'FETCH_TABLE_DATA_PENDING',
  'FETCH_TABLE_DATA_SUCCESS',
  'FETCH_TABLE_DATA_FAILURE',
  'SET_TABLE_URL',
  'SET_TABLE_CURRENT_PAGE',
  'SET_TABLE_PAGE_SIZE',
]);

/**
 * Table model initial state
 */
const initialState = {
  data: [],
  loading: false,
  error: null,
  url: '',
  // pagination
  total: 0,
  pageSize: 15,
  currentPage: 1,
};

/**
 * Table actions generator
 *
 * @param {string} key
 */
export const tableActionsGenerator = (key) => {
  const {
    FETCH_TABLE_DATA_REQUEST,
    FETCH_TABLE_DATA_PENDING,
    FETCH_TABLE_DATA_SUCCESS,
    FETCH_TABLE_DATA_FAILURE,
    SET_TABLE_URL,
    SET_TABLE_CURRENT_PAGE,
    SET_TABLE_PAGE_SIZE,
  } = tableActionTypesGenerator(key);

  const fetchTableData = query => ({ type: FETCH_TABLE_DATA_REQUEST, query });
  const fetchTableDataPending = () => ({ type: FETCH_TABLE_DATA_PENDING });
  const fetchTableDataSuccess = (data, query) => ({ type: FETCH_TABLE_DATA_SUCCESS, data, query });
  const fetchTableDataFailure = error => ({ type: FETCH_TABLE_DATA_FAILURE, error });

  const setTableUrl = url => ({ type: SET_TABLE_URL, url });
  const setTableCurrentPage = currentPage => ({ type: SET_TABLE_CURRENT_PAGE, currentPage });
  const setTablePageSize = pageSize => ({ type: SET_TABLE_PAGE_SIZE, pageSize });

  return {
    fetchTableData,
    fetchTableDataPending,
    fetchTableDataSuccess,
    fetchTableDataFailure,
    setTableUrl,
    setTableCurrentPage,
    setTablePageSize,
  };
};

/**
 * Table reducer generator
 *
 * @description Used in global reducer with table specific key.
 * Check this file `App/redux/reducers.js`
 * @example `
  export default combineReducers({
    ...
    tables: combineReducers({
      BRANDS_TABLE: tableReducerGenerator('BRANDS_TABLE'),
    }),
  });
 * `
 * @param {string} key
 */
export const tableReducerGenerator = key => (state = initialState, action) => {
  const {
    FETCH_TABLE_DATA_PENDING,
    FETCH_TABLE_DATA_SUCCESS,
    FETCH_TABLE_DATA_FAILURE,
    SET_TABLE_URL,
    SET_TABLE_CURRENT_PAGE,
    SET_TABLE_PAGE_SIZE,
  } = tableActionTypesGenerator(key);

  switch (action.type) {
    case FETCH_TABLE_DATA_PENDING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data.results,
        total: action.data.count,
        query: action.query,
      };
    case FETCH_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_TABLE_URL:
      return {
        ...state,
        url: action.url,
      };
    case SET_TABLE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case SET_TABLE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.pageSize,
      };
    default:
      return state;
  }
};
