import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import App from 'App/components/App';
import store, { history } from 'App/redux/store';
import { smartlook, pusher } from 'widgets';

const app = document.getElementById('app');

if (process.env.SMARTLOOK) {
  document.head.appendChild(smartlook);
}

if (process.env.PUSHER) {
  document.head.appendChild(pusher);
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  app,
);
