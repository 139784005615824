import React from 'react';
import { object, func } from 'prop-types';
import Toggle from 'tc-biq-design-system/build/Toggle';

import 'tc-biq-design-system/build/Toggle.css';
import './SwitchCell.scss';

const propTypes = {
  data: object.isRequired,
  handleClick: func.isRequired,
};

const SwitchCell = ({ data, handleClick }) => (
  <div>
    <Toggle
      checked={data.is_active}
      onClick={() => handleClick(data.id, data.is_active)}
    />
  </div>
);

SwitchCell.propTypes = propTypes;

export default SwitchCell;
