import http from 'App/http';

export const resources = {
  brands: () => 'brand/',
  brandItem: id => `brand/${id}/`,
  availableBrands: () => 'available_brands/',
};

const getAll = () => http.get(resources.brands());
const getAllAvailable = () => http.get(resources.availableBrands());
const getItem = id => http.get(resources.brandItem(id));
const updateItem = props => http.put(resources.brandItem(props[0]), props[1]);
const createItem = data => http.post(resources.brands(), data);

const persist = brand => localStorage.setItem('selectedBrand', JSON.stringify(brand));
const getPersisted = () => JSON.parse(localStorage.getItem('selectedBrand'));

export default {
  getAll,
  getItem,
  updateItem,
  createItem,
  getAllAvailable,
  persist,
  getPersisted,
};
