import http from 'App/http';

export const resources = {
  wallets: bid => `/brand/${bid}/wallet/`,
  wallet: (bid, wid) => `/brand/${bid}/wallet/${wid}/`,
  transactions: (bid, wid) => `/brand/${bid}/wallet/${wid}/transaction/`,
  channels: (bid, wid) => `/brand/${bid}/wallet/${wid}/channel/`,
};

const getAll = bid => http.get(resources.wallets(bid));

const get = (bid, wid) => http.get(resources.wallet(bid, wid));

const getAllTransactions = (bid, wid) => http.get(resources.transactions(bid, wid));

const getAllTransactionOptions = (bid, wid) => http.options(resources.transactions(bid, wid));

const getWalletChannels = (bid, wid) => http.get(resources.channels(bid, wid));

export default {
  get,
  getAll,
  getAllTransactions,
  getAllTransactionOptions,
  getWalletChannels,
};
