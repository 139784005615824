import React, { useState, useCallback } from 'react';
import { bool, func, string, object } from 'prop-types';
import Modal from 'tc-biq-design-system/build/Modal';
import Button from 'tc-biq-design-system/build/Button';
import InvoiceService from 'App/services/invoice';
import { Trans } from '@lingui/macro';
import { notifier } from 'tc-biq-design-system/build/Notification';

import './KeepAsCryptoModal.scss';

const propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  brand: string.isRequired,
  invoice: object.isRequired,
  keepOverage: bool.isRequired,
};

const KeepAsCryptoModal = ({ visible, onClose, brand, invoice, keepOverage }) => {
  const [destinationAddress, setDestinationAddress] = useState('');

  const closeModal = () => {
    setDestinationAddress('');
    onClose();
  };

  const onSubmit = useCallback(() => {
    const resource = keepOverage
      ? InvoiceService.keepOverageAsCrypto
      : InvoiceService.keepAsCrypto;
    resource(brand, invoice.id).then(() => {
      notifier.success('Funds kept successfully');
    }, () => {
      notifier.error('Funds keeping failed');
    }).finally(() => {
      closeModal();
    });
  }, [destinationAddress]);

  const footerRender = submitHandler => (
    <div className="withdraw-footer">
      <Button color="ghost" onClick={closeModal}>
        <Trans>Cancel</Trans>
      </Button>

      <Button onClick={submitHandler}>
        <Trans>Keep</Trans>
      </Button>
    </div>
  );

  const amount = keepOverage ? invoice.overage : (invoice.deposited_amount - invoice.deposit_fee).toFixed(8);

  return (
    <Modal
      visible={visible}
      icon="Payments"
      title="Keep as crypto"
      closable
      onCloseIconClick={onClose}
      footerRender={() => footerRender(onSubmit)}
    >
      <div className="modal__item">
        <div className="modal__item__title">Keep amount:</div>
        <div className="modal__item__value">{amount} {invoice.pay_currency}</div>
      </div>
    </Modal>
  );
};

KeepAsCryptoModal.propTypes = propTypes;

export default KeepAsCryptoModal;
