export const getFirstBrand = brands => brands.reduce((prev, current) => {
  if (!prev) return current;
  return new Date(current.created_at).getTime()
      < new Date(prev.created_at).getTime()
    ? current
    : prev;
});

export const getInitials = (name) => {
  const nameArray = name.split(' ').slice(0, 2);
  return nameArray.map(item => item[0].toUpperCase()).join('');
};

export const nullable = (subRequirement) => {
  const check = (required, props, key, ...rest) => {
    if (props[key] === null) {
      return null;
    }
    const sub = required ? subRequirement.isRequired : subRequirement;
    return sub(props, key, ...rest);
  };
  const fn = check.bind(null, false);
  fn.isRequired = check.bind(null, true);
  return fn;
};

export const stringTruncate = (string, start = 0, end = 0) => {
  if (string && start&& end) {
    if (string.length <= start + end) {
      return string;
    }
    return `${string.substring(0, start)}...${string.substring(string.length - end)}`;
  }
  return string;
};

export const truncateText = (string, end) => {
  if (string) {
    if (string.split(' ').length > 1) {
      return string;
    } else {
      return `${string.substring(0, end)}...`;
    }
  }
  return string;
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
  }
  return '';
};

export const downloadResponse = (response) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(
    new Blob([response.data], { type: 'text/csv;charset=utf-8' }),
  );
  link.download = 'export.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
