const SET_LANGUAGE = 'SET_LANGUAGE';

const initialState = {
  lang: 'en',
};

const setLanguage = lang => ({
  type: SET_LANGUAGE,
  lang,
});

export const actions = {
  setLanguage,
};

export default (state = initialState, { type, lang }) => {
  switch (type) {
    case SET_LANGUAGE:
      return { ...state, lang };
    default:
      return state;
  }
};
