import { bindActionCreators, compose } from 'redux';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { actions as apiKeysActions } from 'Brand/redux/models/apiKey';
import ApiKeysModal from './ApiKeysModal';

const mapStateToProps = ({ apiKey: { apiKey } }) => ({
  apiKey,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...apiKeysActions,
    },
    dispatch,
  ),
});

export default compose(
  withI18n(),
  connect(mapStateToProps, mapDispatchToProps),
)(ApiKeysModal);
