import http from 'App/http';

export const resources = {
  exchange: bid => `brand/${bid}/exchange/`,
  rates: () => 'rate/',
  calculate: (amount, from_currency, to_currency) => `exchange/calculate/?amount=${amount}&from_currency=${from_currency}&to_currency=${to_currency}`,
};

const createItem = (brand, payload) => http.post(resources.exchange(brand), { ...payload });

const getRates = (base, quote) => http.get(resources.rates(), {
  params: { base, quote },
});

const calculateExchange = (from_currency, to_currency, amount) => http.get(resources.calculate(
  amount, from_currency, to_currency,
), {});

export default {
  createItem,
  getRates,
  calculateExchange,
};
