import React from 'react';
import Icon from 'tc-biq-design-system/build/Icon';
import { string, func, node } from 'prop-types';

import './ButtonLink.scss';

const propTypes = {
  icon: string,
  onClick: func.isRequired,
  children: node.isRequired,
};

const defaultProps = {
  icon: null,
};

const ButtonLink = ({ icon, onClick, children, ...props }) => (
  <div
    tabIndex={0}
    role="button"
    className="text-primary-500 button-link"
    onClick={onClick}
    {...props}
  >
    {icon && (
    <div className="icon-container">
      <Icon name={icon} colorName="text-primary-500" />
    </div>
    )}
    {children}
  </div>
);

ButtonLink.propTypes = propTypes;
ButtonLink.defaultProps = defaultProps;

export default ButtonLink;
