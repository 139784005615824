import React, { useState, useEffect, useCallback } from 'react';
import { object } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import Modal from 'tc-biq-design-system/build/Modal';
import InfoBox from 'tc-biq-design-system/build/InfoBox';
import Button from 'tc-biq-design-system/build/Button';
import Checkbox from 'tc-biq-design-system/build/Checkbox';

import 'tc-biq-design-system/build/Modal.css';
import 'tc-biq-design-system/build/InfoBox.css';

import './ApiKeysModal.scss';

const propTypes = {
  apiKey: object,
  actions: object.isRequired,
  i18n: object.isRequired,
};

const defaultProps = {
  apiKey: null,
};

const ApiKeysModal = ({ apiKey, actions, i18n }) => {
  const [checked, setChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (apiKey && apiKey.secret && apiKey.key) setOpenModal(true);
  }, [apiKey]);

  const closeModal = useCallback(() => {
    actions.clearApiKey();
    setOpenModal(false);
  }, []);

  const toggleCheck = useCallback(() => {
    setChecked(!checked);
  }, []);

  const ApiFooter = () => (
    <div>
      <Button color={!checked ? 'ghost' : 'primary'} disabled={!checked} onClick={closeModal}><Trans>Close Window</Trans></Button>
    </div>
  );

  return (
    <Modal
      visible={openModal}
      title="API key created successfully"
      icon="Approve"
      type="success"
      size="large"
      footerRender={ApiFooter}
    >
      <InfoBox header={i18n._(t`Warning: You will see the key only once`)} type="warning">
        <Trans>
        As a safely measure, we will not show this key again. Make sure to save it.
        </Trans>
      </InfoBox>
      <div>
        <div className="api-key__item">
          <small><Trans>API Key</Trans></small>
          <div>
            { apiKey && apiKey.key }
          </div>
        </div>
        <div className="api-key__item">
          <small><Trans>API Secret</Trans></small>
          <div>
            { apiKey && apiKey.secret }
          </div>
        </div>
      </div>
      <Checkbox onChange={toggleCheck} checked={checked}>
        <Trans>
        I saved the key and I&apos;m aware that as a safety measure, we wil not show this key again
        </Trans>
      </Checkbox>
    </Modal>
  );
};

ApiKeysModal.propTypes = propTypes;
ApiKeysModal.defaultProps = defaultProps;

export default ApiKeysModal;
