import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { object } from 'prop-types';
import Header from 'App/components/Header/Header';
import Button from 'tc-biq-design-system/build/Button';
import { Row, Col } from 'tc-biq-design-system/build/Grid';
import UsersTable from './UsersTable';
import { resources as userResources } from '../../services/user';
import UserItem from './UserItem';
import FaIcon from '../../../App/components/FaIcon';

const propTypes = {
  i18n: object.isRequired,
};

const Users = ({ i18n }) => {
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(null);

  const editUser = (id) => {
    setOpen(true);
    setUserId(id);
  };

  const inviteUser = () => {
    setOpen(true);
    setUserId(null);
  };

  const onCloseIconClick = () => {
    setOpen(false);
    setUserId(null);
  };

  return (
    <div>
      <Header
        title={i18n._(t`Users`)}
        icon={<FaIcon round size="regular" name="User" color="text-neutral-50" bgColor="bgc-primary-500" />}
        actions={<Button onClick={inviteUser}><Trans>Invite User</Trans></Button>}
      />
      <Row>
        <Col>
          <UsersTable resource={userResources.users()} editUser={editUser} />
        </Col>
      </Row>
      <UserItem
        userId={userId}
        open={open}
        onCloseIconClick={onCloseIconClick}
      />
    </div>
  );
};

Users.propTypes = propTypes;

export default Users;
