import React from 'react';
import { string } from 'prop-types';
import Select from 'tc-biq-design-system/build/Select';
import TextInput from './TextInput';
import DateInput from './DateInput';
import NumberInput from './NumberInput';

const propTypes = {
  type: string.isRequired,
  operator: string.isRequired,
};

const getInputComponent = (type) => {
  switch (type) {
    case 'date':
      return DateInput;
    case 'string':
      return TextInput;
    case 'number':
      return NumberInput;
    case 'select':
      return Select;
    default:
      return null;
  }
};

const getPropsForInputType = (type, props) => {
  switch (type) {
    case 'date':
      return { ...props };
    default:
      return { ...props };
  }
};

const FilterInput = ({ type, operator, ...props }) => {
  const Component = getInputComponent(type);
  const inputProps = getPropsForInputType(type, props);

  return (
    <Component
      {...inputProps}
    />
  );
};

FilterInput.propTypes = propTypes;

export default FilterInput;
