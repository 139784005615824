import { bindActionCreators, compose } from 'redux';
import { withI18n } from '@lingui/react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { tableActionsGenerator } from 'App/components/Table/generators/model';
import UsersTable from './UsersTable';

const actions = tableActionsGenerator('USERS_TABLE');

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...actions,
  },
  dispatch),
});

const mapStateToProps = ({ tables: { USERS_TABLE: { query, pageSize, currentPage } } }) => ({
  query,
  pageSize,
  currentPage,
});

export default compose(
  withI18n(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UsersTable);
