import { bindActionCreators, compose } from 'redux';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { actions as userActions } from 'User/redux/models/user';
import { tableActionsGenerator } from 'App/components/Table/generators/model';

import UserItem from './UserItem';

const actions = tableActionsGenerator('USERS_TABLE');

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getUser: userActions.getUser,
    updateUser: userActions.updateUser,
    inviteUser: userActions.inviteUser,
    clearUser: userActions.clearUser,
    ...actions,
  },
  dispatch),
});

const mapStateToProps = (
  {
    userItem,
  },
) => ({
  ...userItem,
});

export default compose(
  withI18n(),
  connect(mapStateToProps, mapDispatchToProps),
)(UserItem);
