import { bindActionCreators, compose } from 'redux';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { actions as apiKeysActions } from 'Brand/redux/models/apiKey';
import { tableActionsGenerator } from 'App/components/Table/generators/model';
import ApiKeysTable from './ApiKeysTable';

const actions = tableActionsGenerator('API_KEYS_TABLE');

const mapStateToProps = ({ tables: { API_KEYS_TABLE: { data, query } } }) => ({
  data, query,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...apiKeysActions,
      ...actions,
    },
    dispatch,
  ),
});

export default compose(
  withI18n(),
  connect(mapStateToProps, mapDispatchToProps),
)(ApiKeysTable);
