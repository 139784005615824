import { compose, bindActionCreators } from 'redux';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { actions as bankAccountActions } from 'BankAccount/redux/models/bankAccount';
import { actions as exchangeActions } from 'Wallet/redux/models/exchange';
import ExchangeModal from './ExchangeModal';


const mapStateToProps = (
  {
    brand: { currentBrand },
    bankAccount: { bankAccountList },
    exchange: { exchange, errors, loading },
    wallet: { wallets, wallet },
  },
) => ({
  bankAccountList,
  errors,
  loading,
  currentBrand,
  exchange,
  wallet,
  wallets,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...bankAccountActions,
      ...exchangeActions,
    },
    dispatch,
  ),
});

export default compose(
  withI18n(),
  connect(mapStateToProps, mapDispatchToProps),
)(ExchangeModal);
