export const GET_BRAND_WALLETS_REQUEST = 'GET_BRAND_WALLETS_REQUEST';
export const GET_BRAND_WALLETS_SUCCESS = 'GET_BRAND_WALLETS_SUCCESS';
export const GET_BRAND_WALLETS_FAILURE = 'GET_BRAND_WALLETS_FAILURE';

export const GET_WALLET_REQUEST = 'GET_WALLET_REQUEST';
export const GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS';
export const GET_WALLET_FAILURE = 'GET_WALLET_FAILURE';

export const GET_WALLET_TRANSACTIONS_REQUEST = 'GET_WALLET_TRANSACTIONS_REQUEST';
export const GET_WALLET_TRANSACTIONS_SUCCESS = 'GET_WALLET_TRANSACTIONS_SUCCESS';
export const GET_WALLET_TRANSACTIONS_FAILURE = 'GET_WALLET_TRANSACTIONS_FAILURE';

export const GET_WALLET_ADDRESSES_REQUEST = 'GET_WALLET_ADDRESSES_REQUEST';
export const GET_WALLET_ADDRESSES_SUCCESS = 'GET_WALLET_ADDRESSES_SUCCESS';
export const GET_WALLET_ADDRESSES_FAILURE = 'GET_WALLET_ADDRESSES_FAILURE';

export const RESET_WALLET = 'RESET_WALLET';

const initialState = {
  // Wallets
  wallets: [],
  loading: false,
  errors: null,

  // Wallet
  wallet: null,
  loadingWallet: false,
  walletErrors: null,

  // Transactions
  transactions: [],
  loadingTransactions: false,
  transactionsErrors: null,

  addresses: [],
  loadingAddresses: false,
  addressesErrors: null,
};

const getBrandWallets = bid => ({
  type: GET_BRAND_WALLETS_REQUEST,
  bid,
});

const getWallet = (bid, wid) => ({
  type: GET_WALLET_REQUEST,
  bid,
  wid,
});

const getWalletTransactions = (bid, wid) => ({
  type: GET_WALLET_TRANSACTIONS_REQUEST,
  bid,
  wid,
});

const getWalletChannels = (bid, wid) => ({
  type: GET_WALLET_ADDRESSES_REQUEST,
  bid,
  wid,
});

const resetWallet = () => ({
  type: RESET_WALLET,
});

export const actions = {
  getBrandWallets,
  getWallet,
  getWalletTransactions,
  resetWallet,
  getWalletChannels,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // GET_BRAND_WALLETS
    case GET_BRAND_WALLETS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BRAND_WALLETS_SUCCESS:
      return {
        ...state,
        loading: false,
        wallets: action.payload,
      };
    case GET_BRAND_WALLETS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    // GET_WALLET
    case GET_WALLET_REQUEST:
      return {
        ...state,
        loadingWallet: true,
      };
    case GET_WALLET_SUCCESS:
      return {
        ...state,
        loadingWallet: false,
        wallet: action.payload,
      };
    case GET_WALLET_FAILURE:
      return {
        ...state,
        loadingWallet: false,
        walletErrors: action.payload,
      };
    // GET_WALLET_TRANSACTIONS
    case GET_WALLET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loadingTransactions: true,
      };
    case GET_WALLET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadingTransactions: false,
        transactions: action.payload,
      };
    case GET_WALLET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loadingTransactions: false,
        walletErrors: action.payload,
      };
    // GET_WALLET_ADDRESSES
    case GET_WALLET_ADDRESSES_REQUEST:
      return {
        ...state,
        loadingAddresses: true,
      };
    case GET_WALLET_ADDRESSES_SUCCESS:
      return {
        ...state,
        loadingAddresses: false,
        addresses: action.payload,
      };
    case GET_WALLET_ADDRESSES_FAILURE:
      return {
        ...state,
        loadingAddresses: false,
        addressesErrors: action.payload,
      };
    // RESET WALLET
    case RESET_WALLET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
