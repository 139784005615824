import React, { useEffect, useCallback, useMemo } from 'react';
import Sidebar from 'tc-biq-design-system/build/Sidebar';
import { Link } from 'react-router-dom';
import { t } from '@lingui/macro';
import { object, array } from 'prop-types';
import { includes } from 'lodash';

import BrandService from 'Brand/services/brand';
import logoimg from '../../../assets/images/logo.png';
import SidebarFooter from './SidebarFooter';
import SidebarBrands from './SidebarBrands';
import ProfileLink from './ProfileLink/ProfileLink';

const LogoSmall = () => <img src="https://dummyimage.com/40x40/000/fff" alt="small logo" />;
const LogoFull = () => <img style={{ height: '50px' }} src={logoimg} alt="Logo" />;

const generateItems = i18n => [
  {
    label: i18n._(t`Wallets`),
    icon: 'Account',
    route: '/wallets',
    roles: ['admin', 'operator', 'accountant'],
  },
  {
    label: i18n._(t`Transaction History`),
    icon: 'Notes',
    roles: ['admin', 'accountant', 'operator'],
    subItems: [
      {
        label: i18n._(t`Channel Payments`),
        route: '/transaction-history/channel-payments',
        roles: ['admin', 'accountant', 'operator'],
      },
      {
        label: i18n._(t`Bank Withdrawals`),
        route: '/transaction-history/bank-withdrawals',
        roles: ['admin', 'accountant', 'operator'],
      },
      {
        label: i18n._(t`Crypto Withdrawals`),
        route: '/transaction-history/crypto-withdrawals',
        roles: ['admin', 'accountant', 'operator'],
      },
      {
        label: i18n._(t`Invoice History`),
        route: '/transaction-history/invoices',
        roles: ['admin', 'accountant', 'operator'],
      },
    ],
  },
  {
    label: i18n._(t`System`),
    icon: 'Settings',
    roles: ['admin'],
    subItems: [
      {
        label: i18n._(t`Bank accounts`),
        route: '/system/bank-accounts',
        roles: ['admin'],
      },
      {
        label: i18n._(t`Brands`),
        route: '/system/brands',
        roles: ['admin'],
      },
      {
        label: i18n._(t`Users`),
        route: '/system/users',
        roles: ['admin'],
      },
      {
        label: i18n._(t`Audit Logs`),
        route: '/system/audit-logs',
        roles: ['admin'],
      },
    ],
  },
];

const propTypes = {
  i18n: object.isRequired,
  actions: object.isRequired,
  brands: array,
  currentBrand: object,
  location: object.isRequired,
  history: object.isRequired,
  user: object.isRequired,
};

const defaultProps = {
  brands: null,
  currentBrand: null,
};

const MainSidebar = ({ i18n, actions, brands, currentBrand, user, location, history }) => {
  const items = generateItems(i18n).filter(item => includes(item.roles, user.role));

  useEffect(() => {
    actions.getBrands(true);
  }, []);

  const chooseBrand = useCallback((brand) => {
    actions.setCurrentBrand(brand);
    BrandService.persist(brand);
    if (location.pathname !== '/wallets') history.push('/wallets');
  }, [location, history]);

  const defaultItems = useMemo(() => items.reduce((acc, item) => {
    const route = location.pathname.split('/').slice(0, 3).join('/');
    if (acc) return acc;
    if (item.route === route) {
      return { item };
    }

    if (item.subItems) {
      const sub = item.subItems.reduce((sacc, subItem) => {
        if (sacc) return sacc;
        if (subItem.route === route) {
          return { item, subItem };
        }

        return null;
      }, null);

      if (sub) return sub;
    }

    return null;
  }, null), []);

  return (
    <Sidebar
      hideCollapse
      items={items}
      LogoFull={LogoFull}
      LogoSmall={LogoSmall}
      initialActive={{
        ...(defaultItems && defaultItems.item ? { item: defaultItems.item.label } : { item: '' }),
        ...(defaultItems && defaultItems.subItem && { subItem: defaultItems.subItem.label }),
      }}
      header={(
        <ProfileLink location={location} link="/user/me" user={user} />
      )}
      footer={(
        <div>
          <SidebarBrands
            brands={brands}
            currentBrand={currentBrand}
            chooseBrand={chooseBrand}
          />
          <SidebarFooter onClick={actions.logout} text={i18n._(t`Sign out`)} />
        </div>
      )}
    >
      {(NavItem, route, key) => (route ? (
        <Link to={route} key={key}>
          <NavItem />
        </Link>
      ) : (
        <NavItem key={key} />
      ))
      }
    </Sidebar>
  );
};

MainSidebar.propTypes = propTypes;
MainSidebar.defaultProps = defaultProps;
export default MainSidebar;
