import React from 'react';
import Button from 'tc-biq-design-system/build/Button';
import { Row, Col } from 'tc-biq-design-system/build/Grid';
import { object } from 'prop-types';
import Header from 'App/components/Header';
import BrandsTable from './BrandsTable';
import { resources as brandResources } from '../../services/brand';
import FaIcon from '../../../App/components/FaIcon';

const propTypes = {
  history: object.isRequired,
};

const Brands = ({ history }) => {
  const addNewBrand = () => {
    history.push('/system/brands/new');
  };

  return (
    <div>
      <Header
        title="Brands"
        actions={<Button onClick={addNewBrand}>Add Brand</Button>}
        icon={<FaIcon round size="regular" name="Account" color="text-neutral-50" bgColor="bgc-primary-500" />}
      />
      <Row>
        <Col>
          <BrandsTable resource={brandResources.brands()} />
        </Col>
      </Row>
    </div>
  );
};

Brands.propTypes = propTypes;

export default Brands;
