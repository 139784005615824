import React from 'react';
import Spinner from 'tc-biq-design-system/build/Spinner';
import { bool, any, string } from 'prop-types';
import classNames from 'classnames';

import './Page.scss';

const propTypes = {
  loading: bool,
  children: any,
  header: any,
  className: string,
  wrapped: bool,
};

const defaultProps = {
  loading: false,
  children: null,
  header: null,
  className: '',
  wrapped: false,
};

// eslint-disable-next-line react/prop-types
const Footer = ({ children }) => <div className="orb-page__footer">{children}</div>;
// eslint-disable-next-line react/prop-types
const Content = ({ children }) => <div className="orb-page__content">{children}</div>;

/**
 * Global page compoment
 * Use header param only when you have a static header
 * if header is dynamic put it in the page body.
 */
const Page = ({ loading, header, children, className, wrapped }) => (
  <div className={classNames('orb-page', className, { 'orb-page--wrapped': wrapped })}>
    {header && header}
    {loading ? (
      <div className="orb-page__loader"><Spinner /></div>
    ) : (
      children
    )}
  </div>
);

Page.Footer = Footer;
Page.Content = Content;
Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
