import React, { useEffect, useCallback, useState } from 'react';
import { bool, func, object, arrayOf } from 'prop-types';
import Modal from 'tc-biq-design-system/build/Modal';
import { t, Trans } from '@lingui/macro';
import Space from 'tc-biq-design-system/build/Space';
import Divider from 'App/components/Divider';
import Button from 'tc-biq-design-system/build/Button';
import Input from 'tc-biq-design-system/build/Input';
import Select from 'tc-biq-design-system/build/Select';
import { currencySymbols } from 'Wallet/constants';
import withdrawService from 'Wallet/services/withdraw';
import { formatDate } from 'App/helpers/date';

import './WithdrawModal.scss';

const propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  i18n: object.isRequired,
  actions: object.isRequired,
  bankAccountList: arrayOf(object).isRequired,
  currentBrand: object,
  wallets: arrayOf(object).isRequired,
  wallet: object.isRequired,
};

const defaultProps = {
  currentBrand: null,
};

const WithdrawModal = (
  {
    visible,
    onClose,
    i18n,
    actions,
    bankAccountList,
    currentBrand,
    wallets,
    wallet,
  },
) => {
  const formatWalletLabel = useCallback(item => `${item.currency} (${currencySymbols[item.currency]}${item.available})`);

  const defaultValues = {
    from: { ...wallet, label: formatWalletLabel(wallet), value: wallet.id },
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [isWithdrawalSuccessful, setWithdrawalSuccess] = useState(false);
  const [withdrawalInfo, setWithdrawalInfo] = useState({});
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    if (isWithdrawalSuccessful && withdrawalInfo && process.env.PUSHER) {
      if (!channel) {
        /* global pusher */
        const foo = pusher.subscribe(withdrawalInfo.id);
        setChannel(withdrawalInfo.id);
        foo.bind('Withdrawal', (data) => {
          setWithdrawalInfo({ ...data });
        });
      }
    }
  }, [isWithdrawalSuccessful]);

  const updateFieldValue = (name, value) => setFormValues(prevState => ({
    ...prevState,
    [name]: value,
  }));

  const onFromValueChange = (name, value) => {
    updateFieldValue(name, value);

    if (formValues.to && formValues.to.currency !== value.currency) {
      updateFieldValue('to', null);
    }
  };

  useEffect(() => {
    if (currentBrand) {
      actions.getAccountList(currentBrand.id);
      actions.getBrandWallets(currentBrand.id);
    }
  }, [currentBrand]);

  const resetModal = () => {
    setWithdrawalInfo({});
    setWithdrawalSuccess(false);
    onClose();
  };

  const footerRender = submitHandler => (
    !isWithdrawalSuccessful ? (
      <div className="withdraw-footer">
        <Button color="ghost" onClick={onClose}>
          <Trans>Discard</Trans>
        </Button>
        <Button onClick={submitHandler} disabled={formValues.amount > formValues.from.available}>
          <Trans>Withdraw</Trans>
        </Button>
      </div>
    ) : (
      <div>
        <Button onClick={resetModal}>
          <Trans>Close</Trans>
        </Button>
      </div>
    )
  );

  const getWalletOptions = useCallback(({ options }) => (options.map(item => (
    {
      ...item,
      label: formatWalletLabel(item),
      value: item.id,
    })).filter(item => item.currency === 'EUR')), []);

  const getBankAccountOptions = useCallback(({ options }) => (options.map(item => (
    item.currency === formValues.from.currency && {
      ...item,
      label: `${item.description} (${item.IBAN} - ${item.currency})`,
      value: item.id,
    }))), [formValues]);

  const onSubmit = useCallback(() => {
    const payload = {
      from: formValues.from.id,
      to: formValues.to.id,
      amount: formValues.amount,
    };

    withdrawService.createItem(currentBrand.id, payload)
      .then((response) => {
        const { data } = response;
        setWithdrawalInfo({ ...data });
        setWithdrawalSuccess(true);
      }, () => {
        setWithdrawalSuccess(true);
      });
  }, [formValues]);

  return (
    <>
      <Modal
        visible={visible}
        title={i18n._(t`Bank Withdrawal`)}
        confirmText={i18n._(t`Approve Withdrawal`)}
        icon="Withdrawal"
        footerRender={() => footerRender(onSubmit)}
      >

        {!isWithdrawalSuccessful && (
          <div>
            <Select
              name="from"
              placeholder={i18n._(t`From`)}
              label={i18n._(t`From`)}
              options={getWalletOptions({
                options: wallets,
              })}
              value={formValues.from}
              required
              clearable={false}
              onChange={item => onFromValueChange('from', item)}
              type="single"
            />

            <Space size={11} />

            <Select
              name="to"
              placeholder={i18n._(t`To`)}
              label={i18n._(t`To`)}
              options={getBankAccountOptions({
                options: bankAccountList,
              }).filter(bankAccount => bankAccount)}
              type="single"
              clearable={false}
              onChange={item => updateFieldValue('to', item)}
              required
              value={formValues.to}
            />

            <Space size={11} />

            <Input
              name="amount"
              type="number"
              placeholder="0.00"
              label={i18n._(t`Amount`)}
              value={formValues.amount}
              onChange={e => updateFieldValue('amount', e.target.value)}
              hasError={formValues.amount > formValues.from.available}
              helpText={
                formValues.amount > formValues.from.available
                  ? i18n._(t`Insufficient balance`)
                  : null
              }
            />

            <Space size={11} />

            <Divider spaceBottom={10} spaceTop={24} />

            <div className="modal__info">
              <div className="modal__item">
                <div className="modal__item__title"><Trans>Amount received</Trans></div>
                <div className="modal__item__value modal__item__big">
                  {formValues.amount}
                  {' '}
                  {formValues.from && formValues.from.currency}
                </div>
              </div>
            </div>
          </div>
        )}

        {isWithdrawalSuccessful && (
          <div className="modal__info">
            <div
              style={{
                fontSize: '45px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '50px',
              }}
            >
              <svg
                data-v-4b4681fb=""
                viewBox="0 0 93 93"
                xmlns="http://www.w3.org/2000/svg"
                data-v-faa58b52=""
                style={{
                  stroke: '#23d655',
                  width: '1em',
                  height: '1em',
                  fill: 'none',
                }}
              >
                <circle opacity=".9" cx="46.5" cy="46.5" r="45.5" strokeWidth="4" />
                <path
                  d="M70 30L38.68 64 24 49.87"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span style={{ fontSize: '30px', paddingLeft: '10px' }}>Request placed successfully</span>
            </div>

            <div className="modal__item">
              <div className="modal__item__title">Transaction ID</div>
              <div className="modal__item__value">{withdrawalInfo.id}</div>
            </div>

            <div className="modal__item">
              <div className="modal__item__title">From</div>
              <div className="modal__item__value">
                {withdrawalInfo.from_currency}
                &nbsp;
                Wallet
              </div>
            </div>

            <div className="modal__item">
              <div className="modal__item__title">To</div>
              <div className="modal__item__value">
                {withdrawalInfo.to_currency}
                &nbsp;
                Bank Account
              </div>
            </div>

            <div className="modal__item">
              <div className="modal__item__title">Amount</div>
              <div className="modal__item__value">
                {withdrawalInfo.amount}
                &nbsp;
                {formValues.from.currency}
              </div>
            </div>

            <div className="modal__item">
              <div className="modal__item__title">Status</div>
              <div className="modal__item__value">{withdrawalInfo.status}</div>
            </div>

            <div className="modal__item">
              <div className="modal__item__title">Created at</div>
              <div className="modal__item__value">{formatDate(withdrawalInfo.created_at)}</div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

WithdrawModal.propTypes = propTypes;
WithdrawModal.defaultProps = defaultProps;

export default WithdrawModal;
