import { put, takeLatest, call } from 'redux-saga/effects';
import { getFirstBrand } from 'App/helpers';
import BrandService from 'Brand/services/brand';

import {
  GET_BRANDS_REQUEST,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAILURE,
  UPDATE_CURRENT_BRAND,
} from '../models/brand';

export function* getBrandsSaga(initial = false) {
  try {
    const { data } = yield call(BrandService.getAll);
    yield put({
      type: GET_BRANDS_SUCCESS,
      brands: data.results,
      count: data.count,
      next: data.next,
      previous: data.previous,
    });
    if (initial) {
      const persistedBrand = BrandService.getPersisted();
      const brand = persistedBrand || getFirstBrand(data.results);
      yield put({
        type: UPDATE_CURRENT_BRAND,
        currentBrand: brand,
      });
    }
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_BRANDS_FAILURE,
      payload: data,
    });
  }
}

export const brandSaga = [takeLatest(GET_BRANDS_REQUEST, getBrandsSaga)];
