import React from 'react';
import { object, string } from 'prop-types';
import { t } from '@lingui/macro';

import useTable from 'App/components/Table';
import ActionCellRenderer from 'App/components/TableCells/ActionCell';

const TABLE_ID = 'BRANDS_TABLE';

const propTypes = {
  resource: string.isRequired,
  i18n: object.isRequired,
  history: object.isRequired,
};

const BrandsTable = ({ resource, i18n, history }) => {
  const [Table] = useTable(TABLE_ID, resource);

  const columnDefs = [
    {
      title: i18n._(t`Name`),
      key: 'name',
    },
    {
      title: i18n._(t`Website`),
      key: 'website',
    },
    {
      title: i18n._(t`Description`),
      key: 'description',
    },
    {
      title: '',
      key: 'action',
      width: '100px',
      render: data => ActionCellRenderer({ onDelete, onEdit, data }),
    },
  ];

  const fields = [
    {
      key: 'name',
      label: 'Name',
      id: 'name',
      input: 'string',
      type: 'string',
    },
    {
      key: 'website',
      label: 'Website',
      id: 'website',
      input: 'string',
      type: 'string',
    },
    {
      key: 'description',
      label: 'Description',
      id: 'description',
      input: 'string',
      type: 'string',
    },
  ];

  const onDelete = (id) => {
    // This was here when I got here ¯\_(ツ)_/¯
    // eslint-disable-next-line no-console
    console.info(id);
  };

  const onEdit = (id) => {
    history.push(`/system/brands/${id}/edit`);
  };

  return <Table columnDefs={columnDefs} fields={fields} filterable />;
};

BrandsTable.propTypes = propTypes;

export default BrandsTable;
