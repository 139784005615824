import statuses from 'App/constants/statuses';

export const GET_ADDRESS_REQUEST = 'GET_ADDRESS_REQUEST';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAILURE = 'GET_ADDRESS_FAILURE';

export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

export const CREATE_ADDRESS_REQUEST = 'CREATE_ADDRESS_REQUEST';
export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_FAILURE = 'CREATE_ADDRESS_FAILURE';

export const CLEAR_ADDRESS = 'CLEAR_ADDRESS';

const initialState = {
  address: null,
  loading: false,
  errors: null,
  status: statuses.DEFAULT,
};

const createChannel = ({ brandId, walletId, valueData }) => ({
  type: CREATE_ADDRESS_REQUEST,
  valueData,
  brandId,
  walletId,
});

const getChannel = ({ brandId, walletId, channelId }) => ({
  type: GET_ADDRESS_REQUEST,
  brandId,
  walletId,
  channelId,
});

const updateChannel = ({ brandId, walletId, channelId, valueData }) => ({
  type: UPDATE_ADDRESS_REQUEST,
  brandId,
  walletId,
  channelId,
  valueData,
});

const clearChannel = () => ({
  type: CLEAR_ADDRESS,
});

export const actions = {
  createChannel,
  getChannel,
  updateChannel,
  clearChannel,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ADDRESS_REQUEST:
    case GET_ADDRESS_REQUEST:
    case UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        status: statuses.LOADING,
      };
    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        address: action.address,
        status: statuses.DEFAULT,
      };
    case CREATE_ADDRESS_SUCCESS:
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        address: action.address,
        status: statuses.SUCCESS,
      };
    case CREATE_ADDRESS_FAILURE:
    case GET_ADDRESS_FAILURE:
    case UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        status: statuses.ERROR,
      };
    case CLEAR_ADDRESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
