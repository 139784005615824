import http from 'App/http';

export const resources = {
  channelItem: ({ brandId, walletId, channelId }) => `brand/${brandId}/wallet/${walletId}/channel/${channelId}/`,
  channelPaymentItem: ({ brandId, walletId, channelId }) => `brand/${brandId}/wallet/${walletId}/channel/${channelId}/channelpayment/`,
};

const getItem = props => http.get(resources.channelItem(props));
const getItemPayment = props => http.get(resources.channelPaymentItem(props));

export default {
  getItem,
  getItemPayment,
};
