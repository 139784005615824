import React, { useState, useEffect } from 'react';
import Icon from 'tc-biq-design-system/build/Icon';
import { bool, node, string, func } from 'prop-types';

import './Accordion.scss';

const propTypes = {
  open: bool,
  title: string.isRequired,
  children: node.isRequired,
  arrowRight: bool,
  titleDescription: string,
  headerContent: node,
  onToggle: func,
};

const defaultProps = {
  open: false,
  arrowRight: false,
  titleDescription: null,
  headerContent: null,
  onToggle: () => {},
};

const Accordion = (
  {
    open,
    title,
    children,
    arrowRight,
    headerContent,
    titleDescription,
    onToggle,
  },
) => {
  const [openState, setOpenState] = useState(open);

  useEffect(() => {
    onToggle(openState);
  }, [openState]);

  const Arrow = () => (
    <Icon
      colorName="text-neutral-800"
      name={openState ? 'CaretDown' : 'CaretRight'}
    />
  );
  return (
    <div>
      <div
        className="accordion__header"
        onClick={() => { setOpenState(o => !o); }}
        role="button"
        tabIndex={0}
      >
        <div className="accordion__header__inner">
          {!arrowRight && (<Arrow />)}
          <div className="tc-heading-s">
            { title }
            <span className="accordion__header__descr">{titleDescription}</span>
          </div>
          { headerContent && headerContent }
        </div>
        {arrowRight && (<Arrow />)}
      </div>
      {openState && (<div className="accordion__content">{ children }</div>)}
    </div>
  );
};

Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;

export default Accordion;
