import React from 'react';
import { object } from 'prop-types';
import './UserCell.scss';
import Avatar from 'tc-biq-design-system/build/Avatar';

const propTypes = {
  data: object.isRequired,
};

const UserCell = ({ data }) => {
  let initials = '  ';
  if (data.first_name && data.last_name) {
    initials = `${data.first_name[0] || ''}${data.last_name[0] || ''}`;
  }
  return (
    <div className="name__wrapper">
      <Avatar src={data.image} initials={initials} size="regular" />
      {data.first_name && data.last_name ? (
        <div className="name__full">
          {data.first_name}
          {' '}
          {data.last_name}
        </div>
      ) : (
        <div className="name__full">
          {data.username}
        </div>
      )}
    </div>
  );
};

UserCell.propTypes = propTypes;

export default UserCell;
