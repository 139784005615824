import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { string, object } from 'prop-types';
import Avatar from 'tc-biq-design-system/build/Avatar';

import { displayName, getInitials } from 'User/helpers/user';

import './ProfileLink.scss';

const propTypes = {
  user: object,
  link: string.isRequired,
  location: object.isRequired,
};

const defaultProps = {
  user: null,
};

const ProfileLink = ({ user, link, location }) => (
  <div className={classNames('profile-link', { 'profile-link--active': location.pathname === '/user/me' })}>
    <Avatar initials={getInitials(user)} />
    <Link className="profile-link__text" to={link && link}>
      {displayName(user)}
    </Link>
  </div>
);

ProfileLink.propTypes = propTypes;
ProfileLink.defaultProps = defaultProps;

export default ProfileLink;
