import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Table from './Table';
import { tableActionsGenerator } from './generators/model';

const useTable = (key, url) => {
  const actions = tableActionsGenerator(key);

  const mapStateToProps = ({ tables }) => {
    const table = tables[key];
    return {
      ...table,
      ...(url && { resource: url }),
      ...(key && { tableKey: key }),
    };
  };

  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      {
        ...actions,
      },
      dispatch,
    ),
  });

  return useMemo(() => {
    const Component = connect(mapStateToProps, mapDispatchToProps)(Table);

    return [Component, actions];
  }, [key, url]);
};

export default useTable;
