import http from 'App/http';

const get = (url, params = {}) => http.get(url, { params });
const create = (url, params = {}) => http.post(url, params);
const update = (url, params = {}) => http.put(url, params);
const options = (url, params = {}) => http.options(url, params);

export default {
  get,
  create,
  update,
  options,
};
