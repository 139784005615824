import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_BRAND_WALLETS_REQUEST,
  GET_BRAND_WALLETS_SUCCESS,
  GET_BRAND_WALLETS_FAILURE,
  GET_WALLET_REQUEST,
  GET_WALLET_SUCCESS,
  GET_WALLET_FAILURE,
  GET_WALLET_TRANSACTIONS_REQUEST,
  GET_WALLET_TRANSACTIONS_SUCCESS,
  GET_WALLET_TRANSACTIONS_FAILURE,
  GET_WALLET_ADDRESSES_REQUEST,
  GET_WALLET_ADDRESSES_SUCCESS,
  GET_WALLET_ADDRESSES_FAILURE,
} from '../models/wallet';
import WalletService from '../../services/wallet';

export function* getBrandWalletsSaga({ bid }) {
  try {
    const { data } = yield call(WalletService.getAll, bid);

    yield put({
      type: GET_BRAND_WALLETS_SUCCESS,
      payload: data.results,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_BRAND_WALLETS_FAILURE,
      payload: data,
    });
  }
}

export function* getWalletSaga({ bid, wid }) {
  try {
    const { data } = yield call(WalletService.get, bid, wid);
    yield call(getWalletTransactions, { bid, wid });
    yield put({
      type: GET_WALLET_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_WALLET_FAILURE,
      payload: data,
    });
  }
}

export function* getWalletTransactions({ bid, wid }) {
  try {
    const { data } = yield call(WalletService.getAllTransactions, bid, wid);
    yield put({
      type: GET_WALLET_TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_WALLET_TRANSACTIONS_FAILURE,
      payload: data,
    });
  }
}

export function* getWalletChannels({ bid, wid }) {
  try {
    const { data } = yield call(WalletService.getWalletChannels, bid, wid);
    yield put({
      type: GET_WALLET_ADDRESSES_SUCCESS,
      payload: data.results,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_WALLET_ADDRESSES_FAILURE,
      payload: data,
    });
  }
}

export const walletSaga = [
  takeLatest(GET_BRAND_WALLETS_REQUEST, getBrandWalletsSaga),
  takeLatest(GET_WALLET_REQUEST, getWalletSaga),
  takeLatest(GET_WALLET_TRANSACTIONS_REQUEST, getWalletTransactions),
  takeLatest(GET_WALLET_ADDRESSES_REQUEST, getWalletChannels),
];
