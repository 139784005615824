import React from 'react';
import Icon from 'tc-biq-design-system/build/Icon';
import { object, func } from 'prop-types';
import Popconfirm from 'tc-biq-design-system/build/Popconfirm';

import 'tc-biq-design-system/build/Popconfirm.css';
import './ActionCell.scss';


const propTypes = {
  data: object.isRequired,
  onDelete: func.isRequired,
  onEdit: func.isRequired,
};

const ActionCellRenderer = ({ data, onDelete, onEdit }) => (
  <div className="action-wrapper">
    <div
      role="button"
      tabIndex={0}
      onClick={() => onEdit(data.id)}
      className="action-item"
    >
      <Icon name="Edit" size="small" colorName="text-neutral-900" />
    </div>
    <Popconfirm
      type="destructive"
      placement="bottom"
      icon="Delete"
      label="Are you sure you want to delete this item?"
      onConfirm={() => onDelete(data.id)}
      buttonLabels={{ cancel: 'Cancel', confirm: 'Delete' }}
    >
      <div
        className="action-item"
        role="button"
        tabIndex={0}
      >
        <Icon name="Delete" size="small" colorName="text-neutral-900" />
      </div>
    </Popconfirm>
  </div>
);

ActionCellRenderer.propTypes = propTypes;
export default ActionCellRenderer;
