import React from 'react';
import { Trans } from '@lingui/macro';
import Button from 'tc-biq-design-system/build/Button';
import { number, func } from 'prop-types';

const propTypes = {
  submitHandler: func.isRequired,
  resetHandler: func.isRequired,
  userId: number,
};

const defaultProps = {
  userId: null,
};

const UserItemFooter = ({ submitHandler, resetHandler, userId }) => (
  <div className="user-item-footer">
    <Button
      color="ghost"
      onClick={resetHandler}
    >
      {userId && (<Trans>Discard changes</Trans>)}
      {!userId && (<Trans>Discard</Trans>)}
    </Button>
    <Button
      onClick={submitHandler}
    >
      {userId && (<Trans>Save changes</Trans>)}
      {!userId && (<Trans>Invite user</Trans>)}
    </Button>
  </div>
);

UserItemFooter.propTypes = propTypes;
UserItemFooter.defaultProps = defaultProps;

export default UserItemFooter;
