import React from 'react';
import Input from 'tc-biq-design-system/build/Input';
import { string, node, bool } from 'prop-types';

import Field from '../Field';

import './InputText.scss';

const propTypes = {
  name: string.isRequired,
  labelAction: node,
  disabled: bool,
};

const defaultProps = {
  labelAction: null,
  disabled: false,
};

const InputText = ({ name, labelAction, disabled, ...rest }) => (
  <Field name={name}>
    {({ onChange, value, error }) => (
      <div className="input-wrapper">
        <div className="input-children">{labelAction}</div>
        <Input
          disabled={disabled}
          value={value || ''}
          helpText={error && error[0]}
          hasError={error && !!error[0]}
          onChange={!disabled ? e => onChange(e.target.value) : () => {}}
          {...rest}
        />
      </div>
    )}
  </Field>
);

InputText.propTypes = propTypes;
InputText.defaultProps = defaultProps;

export default InputText;
