import http from 'App/http';

export const resources = {
  users: () => 'user/',
  invite: () => 'user/invite/',
  resendInvite: id => `user/${id}/resend_invite/`,
  userItem: id => `user/${id}/`,
  me: () => 'user/me/',
  updateCurrentUser: () => 'user/update_me/',
  userChangePassword: id => `user/${id}/change_password/`,
  twoFaAuth: () => 'mfa/',
  twoFaConfirm: () => 'mfa/confirm/',
  twoFaAuthId: id => `mfa/${id}/`,
};

const getAll = () => http.get(resources.users());
const getItem = id => http.get(resources.userItem(id));
const getCurrent = () => http.get(resources.me());
const updateCurrentUser = data => http.patch(resources.updateCurrentUser(), data);
const updateItem = props => http.patch(resources.userItem(props[0]), props[1]);
const createItem = data => http.post(resources.users(), data);
const deleteItem = id => http.delete(resources.userItem(id));
const changePassword = props => http.post(resources.userChangePassword(props[0]), props[1]);
const getCode = () => http.post(resources.twoFaAuth());
const getCodeInfo = () => http.get(resources.twoFaAuth());
const confirmCode = token => http.post(resources.twoFaConfirm(), null, {
  headers: {
    'X-OrbPay-MFA-Token': token,
  },
});
const deleteCode = ({ token, id }) => http.delete(resources.twoFaAuthId(id), {
  headers: {
    'X-OrbPay-MFA-Token': token,
  },
});
const invite = data => http.post(resources.invite(), data);
const resendInvite = id => http.post(resources.resendInvite(id));

export default {
  getAll,
  getItem,
  getCurrent,
  updateCurrentUser,
  updateItem,
  createItem,
  deleteItem,
  changePassword,
  getCode,
  confirmCode,
  deleteCode,
  getCodeInfo,
  invite,
  resendInvite,
};
