import React, { useEffect } from 'react';
import { func, object, number, bool, string } from 'prop-types';
import { t } from '@lingui/macro';
import { Sidepanel } from 'tc-biq-design-system/build/Sidepanel';
import FaIcon from 'App/components/FaIcon';
import Form from 'App/components/Form';
import InputText from 'App/components/Form/Field/InputText';
import SelectField from 'App/components/Form/Field/SelectField';
import { required } from 'App/components/Form/validators';
import statuses from 'App/constants/statuses';
import BankAccountFooter from './BankAccountFooter';

const currencyOptions = [
  {
    id: 1,
    label: 'USD',
    value: 'USD',
  },
  {
    id: 2,
    label: 'EUR',
    value: 'EUR',
  },
];

const propTypes = {
  onSidepanelClose: func.isRequired,
  aid: number,
  open: bool.isRequired,
  loading: bool.isRequired,
  errors: object,
  i18n: object.isRequired,
  actions: object.isRequired,
  bankAccount: object,
  status: string,
};

const defaultProps = {
  aid: null,
  errors: null,
  bankAccount: null,
  status: null,
};

const BankAccountSidepanel = (
  {
    onSidepanelClose,
    aid,
    open,
    bankAccount,
    loading,
    errors,
    status,
    actions,
    i18n,
  },
) => {
  useEffect(() => {
    if (aid) {
      actions.getBankAccount({
        aid,
      });
    }
  }, [aid]);

  useEffect(() => {
    if (status === statuses.SUCCESS) resetHandler();
  }, [status]);

  const validators = {
    bank_name: [required({ i18n })],
    bank_address: [required({ i18n })],
    account_holder_name: [required({ i18n })],
    IBAN: [required({ i18n })],
    currency: [required({ i18n })],
    bic: [required({ i18n })],
    description: [required({ i18n })],
  };

  const avatar = () => {
    if (aid) {
      return (
        <div className="icon-wrapper">
          <FaIcon colorName="text-brand-50" name="Pen" />
        </div>
      );
    }
    return (
      <div className="icon-wrapper">
        <FaIcon colorName="text-brand-50" name="Plus" />
      </div>
    );
  };

  const resetHandler = () => {
    actions.clearBankAccount();
    onSidepanelClose();
  };

  const onSubmit = (data) => {
    const currency = data.currency && data.currency.value
      ? data.currency.value : data.currency;
    const valueData = {
      ...data,
      currency,
    };
    if (aid) {
      actions.updateBankAccount({
        aid,
        valueData,
      });
    } else {
      actions.createBankAccount({
        aid,
        valueData,
      });
    }
  };
  return (
    <Form
      onSubmit={onSubmit}
      loading={loading}
      errors={errors}
      validators={validators}
      defaultValues={{ ...bankAccount }}
    >
      {({ submitHandler, loader }) => (
        <>
          <Sidepanel
            className="side-panel"
            title={aid ? i18n._(t`Edit bank account`) : i18n._(t`Add bank account`)}
            avatar={avatar}
            visible={open}
            onCloseIconClick={resetHandler}
            footerRender={() => BankAccountFooter({
              submitHandler,
              resetHandler,
              aid,
            })}
          >
            <div className="account-content">
              {loader}
              <InputText
                name="bank_name"
                placeholder={i18n._(t`Bank Name`)}
                label={i18n._(t`Bank Name`)}
              />
              <InputText
                name="bank_address"
                placeholder={i18n._(t`Bank Address`)}
                label={i18n._(t`Bank Address`)}
              />
              <InputText
                name="account_holder_name"
                placeholder={i18n._(t`Account Holder Name`)}
                label={i18n._(t`Account Holder Name`)}
              />
              <InputText
                name="IBAN"
                placeholder={i18n._(t`IBAN / Account number`)}
                label={i18n._(t`IBAN / Account number`)}
              />
              <SelectField
                name="currency"
                placeholder={i18n._(t`Currency`)}
                label={i18n._(t`Currency`)}
                options={currencyOptions}
                type="single"
              />
              <InputText
                name="bic"
                placeholder={i18n._(t`BIC/SWIFT`)}
                label={i18n._(t`BIC/SWIFT`)}
              />
              <InputText
                name="description"
                placeholder={i18n._(t`Description`)}
                label={i18n._(t`Description`)}
              />
            </div>
          </Sidepanel>
        </>
      )}
    </Form>
  );
};
BankAccountSidepanel.propTypes = propTypes;
BankAccountSidepanel.defaultProps = defaultProps;
export default BankAccountSidepanel;
