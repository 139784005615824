import React from 'react';
import { put, takeEvery, call } from 'redux-saga/effects';
import { notifier } from 'tc-biq-design-system/build/Notification';
import { Trans } from '@lingui/macro';
import { tableActionsGenerator } from 'App/components/Table/generators/model';
import ApiKeyService from '../../services/apiKey';

import {
  GET_API_KEY_REQUEST,
  GET_API_KEY_SUCCESS,
  GET_API_KEY_FAILURE,

  CREATE_API_KEY_REQUEST,
  CREATE_API_KEY_SUCCESS,
  CREATE_API_KEY_FAILURE,

  UPDATE_API_KEY_REQUEST,
  UPDATE_API_KEY_SUCCESS,
  UPDATE_API_KEY_FAILURE,
} from '../models/apiKey';

const userTableKey = 'API_KEYS_TABLE';
const actions = tableActionsGenerator(userTableKey);

export function* getApiKeySaga({ bid, kid }) {
  try {
    const { data } = yield call(ApiKeyService.getItem, { bid, kid });
    yield put({
      type: GET_API_KEY_SUCCESS,
      apiKey: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_API_KEY_FAILURE,
      payload: data,
    });
  }
}

export function* createApiKeySaga({ bid, valueData }) {
  try {
    const { data } = yield call(ApiKeyService.createItem, { bid, valueData });
    yield put({
      type: CREATE_API_KEY_SUCCESS,
      apiKey: data,
    });
    yield call(notifier.success, <Trans>API keys created successful</Trans>);
    yield put(actions.fetchTableData());
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: CREATE_API_KEY_FAILURE,
      payload: data,
    });
  }
}

export function* updateApiKeySaga({ bid, kid, valueData }) {
  try {
    const { data } = yield call(ApiKeyService.updateItem, { bid, kid, valueData });
    yield put({
      type: UPDATE_API_KEY_SUCCESS,
      apiKey: data,
    });
    yield call(notifier.success, <Trans>Success update</Trans>);
    yield put(actions.fetchTableData());
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: UPDATE_API_KEY_FAILURE,
      payload: data,
    });
  }
}

export const apiKeySaga = [
  takeEvery(GET_API_KEY_REQUEST, getApiKeySaga),
  takeEvery(CREATE_API_KEY_REQUEST, createApiKeySaga),
  takeEvery(UPDATE_API_KEY_REQUEST, updateApiKeySaga),
];
