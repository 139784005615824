import React, { useEffect, Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { bool, func, object, string } from 'prop-types';
import { Sidepanel } from 'tc-biq-design-system/build/Sidepanel';
import Space from 'tc-biq-design-system/build/Space';
import { Card } from 'tc-biq-design-system/build/Card';
import { Pill } from 'tc-biq-design-system/build/Pill';
import Spinner from 'tc-biq-design-system/build/Spinner';
import { notifier } from 'tc-biq-design-system/build/Notification';
import { Button } from 'tc-biq-design-system';
import RefundOverageModal from 'App/components/RefundOverageModal';
import InvoiceExchangeModal from 'App/components/InvoiceExchangeModal';
import InvoiceExchangeOverageModal from 'App/components/InvoiceExchangeOverageModal';
import InvoiceRefundModal from 'App/components/InvoiceRefundModal';
import KeepAsCryptoModal from 'App/components/KeepAsCryptoModal';
import InvoiceService from 'App/services/invoice';
import { invoiceStatuses } from 'App/constants/invoices';

import InvoiceHistoryEvents from 'App/components/InvoiceHistoryEvents';
import useApi from 'App/hooks/useApi';

import './InvoiceSidepanel.scss';

const propTypes = {
  user: object,
  open: bool.isRequired,
  onSidepanelClose: func.isRequired,
  i18n: object.isRequired,
  invoiceID: string,
  brand: string,
};

const defaultProps = {
  invoiceID: '',
  brand: '',
  user: null,
};

const InvoiceSidepanel = ({ open, onSidepanelClose, i18n, invoiceID, brand, user }) => {
  const [refundOverageModalOpen, setOverageRefundModalOpen] = useState(false);
  const [invoiceExchangeModalOpen, setInvoiceExchangeModalOpen] = useState(false);
  const [invoiceExchangeOverageModalOpen, setInvoiceExchangeOverageModalOpen] = useState(false);
  const [invoiceRefundModalOpen, setInvoiceRefundModalOpen] = useState(false);
  const [keepAsCryptoModalOpen, setKeepAsCryptoModalOpen] = useState(false);
  const [keepOverage, setKeepOverage] = useState(false);

  const onRefundOverageModalClose = () => {
    setOverageRefundModalOpen(false);
  };

  const onInvoiceExchangeModalClose = () => {
    setInvoiceExchangeModalOpen(false);
  };

  const onInvoiceExchangeOverageModalClose = () => {
    setInvoiceExchangeOverageModalOpen(false);
  };

  const onInvoiceRefundModalClose = () => {
    setInvoiceRefundModalOpen(false);
  };

  const onKeepAsCryptoModalClose = () => {
    setKeepAsCryptoModalOpen(false);
    setKeepOverage(false);
  };

  const response = useApi(InvoiceService.getInvoice);

  const { run, response: invoice, loading, error } = response;

  useEffect(() => {
    if (invoiceID && brand) run(brand, invoiceID);
  }, [invoiceID, brand]);

  useEffect(() => {
    if (error) {
      notifier.error(i18n._(t`Get invoice error.`));
    }
  }, [error]);

  const showSpinner = loading;
  const showDetails = !loading && invoice;

  const openOverageRefundModal = () => {
    setOverageRefundModalOpen(true);
  };

  const openInvoiceExchangeModal = () => {
    setInvoiceExchangeModalOpen(true);
  };

  const openInvoiceExchangeOverageModal = () => {
    setInvoiceExchangeOverageModalOpen(true);
  };

  const openInvoiceRefundModal = () => {
    setInvoiceRefundModalOpen(true);
  };

  const openKeepAsCryptoModal = () => {
    setKeepAsCryptoModalOpen(true);
  };

  const onKeepOverageAsCrypto = () => {
    setKeepAsCryptoModalOpen(true);
    setKeepOverage(true);
  }

  const markAsComplete = () => {
    InvoiceService.markAsComplete(brand, invoiceID).then(() => {
      notifier.success('Invoice marked as completed.');
    }, () => {
      notifier.error('Failed marking invoice as completed.');
    }).finally(() => {
      onSidepanelClose();
    });
  };

  const footerRender = invoiceObject => (
    <div className="invoice-sidepanel-footer">
      {user && ['admin', 'operator'].includes(user.role) && (
        <div className="footer-actions">
          {invoiceObject && invoiceObject.status === 'OVERPAID' && (
            <>
              <Button color="ghost" onClick={markAsComplete}>
                  Mark as completed
              </Button>
              {invoiceObject.overage > 0 && (
                <>
                  <Button color="ghost" onClick={openOverageRefundModal}>
                      Refund overage
                  </Button>
                  <Button color="ghost" onClick={openInvoiceExchangeOverageModal}>
                      Exchange Overage
                  </Button>
                </>
              )}
            </>
          )}

          {invoiceObject && ['UNDERPAID', 'PAID_LATE'].includes(invoiceObject.status) && (
            <>
              <Button color="ghost" onClick={openInvoiceExchangeModal}>
                Exchange
              </Button>
              <Button color="ghost" onClick={openKeepAsCryptoModal}>
                Keep as crypto
              </Button>
              <Button color="ghost" onClick={openInvoiceRefundModal}>
                Refund
              </Button>
            </>
          )}

          {invoiceObject && invoiceObject.status === 'COMPLETED' && invoiceObject.overage > 0 && (
            <>
              <Button color="ghost" onClick={openInvoiceExchangeOverageModal}>
                Exchange overage
              </Button>
              <Button color="ghost" onClick={onKeepOverageAsCrypto}>
                Keep overage
              </Button>
              <Button color="ghost" onClick={openOverageRefundModal}>
                Refund overage
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );

  return (
    <>
      <Sidepanel
        title={i18n._(t`Invoice details`)}
        visible={open}
        onCloseIconClick={onSidepanelClose}
        icon="Notes"
        footerRender={() => footerRender(invoice)}
        size="large"
      >
        {showSpinner && <Spinner />}
        {showDetails && (
          <Fragment>
            <Card
              title={invoice.name}
            >
              <div style={{ padding: '10px 0 10px 0', minHeight: '45px' }}>
                
                <div
                  style={{
                    display: 'inline-block',
                    wordBreak: 'break-all',
                    width: '60%',
                  }}
                >
                  {invoice.description}
                </div>
                <div style={{ display: 'inline' }}>
                  <Pill
                    type={invoiceStatuses[invoice.status].pill}
                    style={{ float: 'right' }}
                  >
                    {invoiceStatuses[invoice.status].title}
                  </Pill>
                </div>
              </div>
            </Card>
            <Space size={20} />
            { invoice.history && !!invoice.history.length && (
              <Card
                title={i18n._(t`Invoice history`)}
              >
                <InvoiceHistoryEvents
                  events={invoice.history}
                  invoice={invoice}
                />
              </Card>
            ) }
          </Fragment>
        )}
      </Sidepanel>

      {refundOverageModalOpen && (
        <RefundOverageModal
          visible={refundOverageModalOpen}
          onClose={onRefundOverageModalClose}
          brand={brand}
          invoice={invoice}
        />
      )}

      {invoiceExchangeModalOpen && (
        <InvoiceExchangeModal
          visible={invoiceExchangeModalOpen}
          onClose={onInvoiceExchangeModalClose}
          brand={brand}
          invoice={invoiceID}
        />
      )}

      {invoiceExchangeOverageModalOpen && (
        <InvoiceExchangeOverageModal
          visible={invoiceExchangeOverageModalOpen}
          onClose={onInvoiceExchangeOverageModalClose}
          brand={brand}
          invoice={invoiceID}
        />
      )}

      {invoiceRefundModalOpen && (
        <InvoiceRefundModal
          visible={invoiceRefundModalOpen}
          onClose={onInvoiceRefundModalClose}
          brand={brand}
          invoice={invoice}
        />
      )}

      {keepAsCryptoModalOpen && (
        <KeepAsCryptoModal
          visible={keepAsCryptoModalOpen}
          onClose={onKeepAsCryptoModalClose}
          brand={brand}
          invoice={invoice}
          keepOverage={keepOverage}
        />
      )}
    </>
  );
};

InvoiceSidepanel.propTypes = propTypes;
InvoiceSidepanel.defaultProps = defaultProps;

export default InvoiceSidepanel;
