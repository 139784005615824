import React, { useEffect } from 'react';
import { t } from '@lingui/macro';
import { bool, object, number, func, string } from 'prop-types';

import statuses from 'App/constants/statuses';
import { Sidepanel } from 'tc-biq-design-system/build/Sidepanel';
import Icon from 'tc-biq-design-system/build/Icon';
import Form from 'App/components/Form';
import Space from 'tc-biq-design-system/build/Space';
import InputText from 'App/components/Form/Field/InputText';
import SelectField from 'App/components/Form/Field/SelectField';
import MultiSelect from 'App/components/MultiSelect';
import BrandService from 'Brand/services/brand';
import { required, email } from 'App/components/Form/validators';
import UserItemFooter from './UserItemFooter';

import './UserItem.scss';

const propTypes = {
  actions: object.isRequired,
  i18n: object.isRequired,
  submitLoading: bool.isRequired,
  open: bool.isRequired,
  onCloseIconClick: func.isRequired,
  submitErrors: object,
  userId: number,
  user: object,
  submitStatus: string,
};

const defaultProps = {
  user: null,
  userId: null,
  submitErrors: null,
  submitStatus: null,
};

const UserItem = (
  { actions,
    i18n,
    userId,
    open,
    onCloseIconClick,
    user,
    submitErrors,
    submitLoading,
    submitStatus,
  },
) => {
  useEffect(() => {
    if (userId) actions.getUser(userId);
  }, [userId]);

  const avatar = () => (userId ? 'Pen' : 'Plus');

  const validators = {
    first_name: [required({ i18n })],
    last_name: [required({ i18n })],
    email: [required({ i18n }), email({ i18n })],
    available_brands: [required({ i18n })],
  };

  const onSubmit = (formData) => {
    const valueData = { ...formData };
    valueData.role = valueData.role.value;

    if (userId) {
      actions.updateUser({
        id: userId,
        valueData,
      });
    } else {
      actions.inviteUser({ valueData });
    }
  };

  useEffect(() => {
    if (submitStatus === statuses.SUCCESS) resetHandler();
  }, [submitStatus]);

  const resetHandler = () => {
    actions.clearUser();
    onCloseIconClick();
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        loading={submitLoading}
        errors={submitErrors}
        validators={validators}
        defaultValues={{ ...user }}
      >
        {({ submitHandler, loader }) => (
          <>
            <Sidepanel
              className="side-panel"
              title={userId ? i18n._(t`Edit User`) : i18n._(t`Invite User`)}
              avatar={() => (
                <div className="icon-wrapper">
                  <Icon colorName="text-brand-50" name={avatar()} />
                </div>
              )}
              onCloseIconClick={resetHandler}
              visible={open}
              footerRender={() => UserItemFooter({ submitHandler, resetHandler, userId })}
            >
              <div className="user-content">
                {loader}
                <InputText
                  name="first_name"
                  placeholder={i18n._(t`First name`)}
                  label={i18n._(t`First name`)}
                />
                <Space size={11} />
                <InputText
                  name="last_name"
                  placeholder={i18n._(t`Last name`)}
                  label={i18n._(t`Last name`)}
                />
                <Space size={11} />
                <InputText
                  disabled={!!userId}
                  name="email"
                  placeholder={i18n._(t`Email`)}
                  label={i18n._(t`Email`)}
                />
                <Space size={11} />
                <SelectField
                  name="role"
                  options={[
                    { label: i18n._(t`Admin`), value: 'admin' },
                    { label: i18n._(t`Operator`), value: 'operator' },
                    { label: i18n._(t`Accountant`), value: 'accountant' },
                  ]}
                  label={i18n._(t`Role`)}
                  type="single"
                  clearable={false}
                />
                <Space size={30} />
                <MultiSelect
                  name="available_brands"
                  icon="Account"
                  title="Brands"
                  getData={BrandService.getAll}
                />
              </div>
            </Sidepanel>
          </>
        )}
      </Form>
    </div>
  );
};

UserItem.propTypes = propTypes;
UserItem.defaultProps = defaultProps;

export default UserItem;
