export const GET_BANK_ACCOUNT_REQUEST = 'GET_BANK_ACCOUNT_REQUEST';
export const GET_BANK_ACCOUNT_SUCCESS = 'GET_BANK_ACCOUNT_SUCCESS';
export const GET_BANK_ACCOUNT_FAILURE = 'GET_BANK_ACCOUNT_FAILURE';

const getAccountList = bid => ({
  type: GET_BANK_ACCOUNT_REQUEST,
  bid,
});

const initialState = {
  bankAccountList: [],
  loading: false,
  errors: null,
};

export const actions = {
  getAccountList,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        bankAccountList: action.payload,
      };
    case GET_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
