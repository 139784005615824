import React from 'react';
import { Row, Col } from 'tc-biq-design-system/build/Grid';
import { object } from 'prop-types';
import Header from 'App/components/Header';
import FaIcon from 'App/components/FaIcon';
import { t } from '@lingui/macro';
import useTable from 'App/components/Table';
import { formatDate } from 'App/helpers/date';
import UserCell from 'App/components/TableCells/UserCell';
import AuditLogTargetCell from 'App/components/TableCells/AuditLogTargetCell';
import { resources } from '../../services/auditlogs';

const TABLE_ID = 'AUDIT_LOGS_TABLE';

const propTypes = {
  i18n: object.isRequired,
};

const AuditLogs = ({ i18n }) => {
  const [Table] = useTable(TABLE_ID, resources.auditLogs());

  const columnDefs = [
    {
      title: i18n._(t`Actor`),
      key: 'actor',
      render: (tableData) => {
        const data = tableData.actor;
        return UserCell({ data });
      },
    },
    {
      title: i18n._(t`Created at`),
      key: 'created_at',
      render: data => formatDate(data.created_at),
    },
    {
      title: i18n._(t`Target`),
      key: 'target_object_id',
      render: data => AuditLogTargetCell(data.target, data.target_content_type),
    },
    {
      title: i18n._(t`Action`),
      key: 'action',
    },
  ];

  const filterFields = [
    {
      type: 'string',
      input: 'select',
      key: 'actor',
      id: 'actor',
      label: 'Actor',
    },
    {
      type: 'date',
      input: 'date',
      key: 'created_at',
      id: 'created_at',
      label: 'Created at',
    },
    {
      type: 'string',
      input: 'select',
      key: 'target',
      id: 'target',
      label: 'Target',
    },
    {
      type: 'string',
      input: 'text',
      key: 'action',
      id: 'action',
      label: 'Action',
    },
  ];

  return (
    <div>
      <Header
        title={i18n._(t`Audit Logs`)}
        icon={<FaIcon round size="regular" name="Logs" color="text-neutral-50" bgColor="bgc-primary-500" />}
      />
      <Row>
        <Col>
          <Table
            columnDefs={columnDefs}
            filterable
            fields={filterFields}
            query={{
              ordering: '-created_at',
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

AuditLogs.propTypes = propTypes;

export default AuditLogs;
