import React from 'react';
import { object, string, func } from 'prop-types';
import { t } from '@lingui/macro';

import useTable from 'App/components/Table';
import { formatDate } from 'App/helpers/date';
import { stringTruncate } from 'App/helpers';
import { Pill } from 'tc-biq-design-system/build/Pill';

const TABLE_ID = 'ADDRESS_PAYMENT_TABLE';

const propTypes = {
  resource: string.isRequired,
  i18n: object.isRequired,
  showPayment: func.isRequired,
};

const AddressPaymentsTable = ({ resource, i18n, showPayment }) => {
  const [Table] = useTable(TABLE_ID, resource);

  const columnDefs = [
    {
      title: i18n._(t`Date`),
      key: 'created_at',
      // eslint-disable-next-line camelcase
      render: ({ created_at }) => formatDate(created_at),
    },
    {
      title: i18n._(t`ID`),
      key: 'id',
      render: data => <div>{stringTruncate(data.id, 4, 4)}</div>,
    },
    {
      title: i18n._(t`Payment Status`),
      key: 'payment_status',
      render: data => (
        <Pill type={data.payment_status === 'executed' ? 'status01' : 'status03'}>
          {data.payment_status}
        </Pill>
      ),
    },
    {
      title: i18n._(t`Compliance Review`),
      key: 'compliance_review',
      render: data => <Pill type={['approved', 'skipped'].includes(data.compliance_review) ? 'status01' : 'status03'}>{data.compliance_review}</Pill>,
    },
    {
      title: i18n._(t`Deposited Amount`),
      key: 'deposited',
      render: data => <div>{data.deposited_amount} {data.deposited_currency}</div>,
    },
    {
      title: i18n._(t`Received amount`),
      key: 'credited',
      render: data => <div>{data.credited_amount} {data.credited_currency}</div>,
    },
    {
      title: i18n._(t`Exchange Fee`),
      key: 'exchange_fee',
      render: data => (
        <div>{data.exchange_fee_amount} {data.exchange_fee_currency}</div>
      ),
    },
    {
      title: i18n._(t`Deposit fee`),
      key: 'exchange_fee',
      render: data => (
        <div>{data.deposit_fee_amount} {data.deposit_fee_currency}</div>
      ),
    },
    {
      title: i18n._(t`Exchange rate`),
      key: 'exchange_rate',
    },
    {
      title: i18n._(t`Exchange %`),
      key: 'exchange_percentage',
      render: data => <div>{data.exchange_percentage}%</div>,
    },
    {
      title: i18n._(t`View`),
      key: 'actions',
      width: '120px',
      render: data => (
        <>
          <Table.Action iconName="View" onClick={() => showPayment(data.id)} />
        </>
      ),
    },
  ];

  const fields = [
    {
      key: 'created_at',
      id: 'created_at',
      label: 'Date',
      type: 'date',
      input: 'date',
    },
    {
      key: 'id',
      id: 'id',
      label: 'ID',
      type: 'string',
      input: 'string',
    },
    {
      key: 'deposited_amount',
      id: 'deposited_amount',
      label: 'Deposited Amount',
      type: 'number',
      input: 'number',
    },
    {
      key: 'credited_amount',
      id: 'credited_amount',
      label: 'Received amount',
      type: 'number',
      input: 'number',
    },
    {
      key: 'exchange_fee_amount',
      id: 'exchange_fee_amount',
      label: 'Fee',
      type: 'number',
      input: 'number',
    },
    {
      key: 'exchange_rate',
      id: 'exchange_rate',
      label: 'Exchange rate',
      type: 'number',
      input: 'number',
    },
    {
      key: 'exchange_percentage',
      id: 'exchange_percentage',
      label: 'Exchange %',
      type: 'number',
      input: 'number',
    },
  ];

  return (
    <Table
      columnDefs={columnDefs}
      filterable
      fields={fields}
      query={{
        ordering: '-created_at',
      }}
    />
  );
};

AddressPaymentsTable.propTypes = propTypes;

export default AddressPaymentsTable;
