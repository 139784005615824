import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { bool } from 'prop-types';

const propTypes = {
  isAllowed: bool,
};

const defaultProps = {
  isAllowed: false,
};

const ProtectedRoute = ({ isAllowed, ...props }) => (isAllowed ? <Route {...props} /> : <Redirect to="/login" />);

ProtectedRoute.propTypes = propTypes;
ProtectedRoute.defaultProps = defaultProps;

export default ProtectedRoute;
