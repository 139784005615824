import React, { useEffect } from 'react';
import { bool, number, object, func, string } from 'prop-types';
import Form from 'App/components/Form';
import FaIcon from 'App/components/FaIcon';
import InputText from 'App/components/Form/Field/InputText';
import { required } from 'App/components/Form/validators';
import { t } from '@lingui/macro';
import { Sidepanel } from 'tc-biq-design-system/build/Sidepanel';
import statuses from 'App/constants/statuses';
import ApiKeysFooter from './ApiKeysFooter';

const propTypes = {
  actions: object.isRequired,
  open: bool.isRequired,
  onSidepanelClose: func.isRequired,
  i18n: object.isRequired,
  status: string,
  loading: bool.isRequired,
  match: object.isRequired,
  apiKey: object,
  errors: object,
  params: number,
  kid: number,
};

const defaultProps = {
  apiKey: null,
  errors: null,
  kid: null,
  params: null,
  status: null,
};

const ApiKeysSidepanel = (
  {
    actions,
    open,
    apiKey,
    loading,
    errors,
    kid,
    onSidepanelClose,
    i18n,
    status,
    match: { params } },
) => {
  useEffect(() => {
    if (kid) actions.getApiKeyItem({ kid, bid: params.id });
  }, [kid]);

  useEffect(() => {
    if (status === statuses.SUCCESS && apiKey && apiKey.key) {
      onSidepanelClose();
    } else if (status === statuses.SUCCESS && apiKey && !apiKey.key) {
      resetHandler();
    }
  }, [status, apiKey]);

  const validators = {
    description: [required({ i18n })],
  };

  const onSubmit = (valueData) => {
    if (kid) {
      actions.updateApiKeyItem({ kid, bid: params.id, valueData });
    } else {
      actions.createApiKeyItem({ bid: params.id, valueData });
    }
  };

  const resetHandler = () => {
    actions.clearApiKey();
    onSidepanelClose();
  };

  const avatar = () => {
    if (kid) {
      return (
        <div className="icon-wrapper">
          <FaIcon colorName="text-brand-50" name="Pen" />
        </div>
      );
    }
    return (
      <div className="icon-wrapper">
        <FaIcon colorName="text-brand-50" name="Plus" />
      </div>
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      loading={loading}
      errors={errors}
      validators={validators}
      defaultValues={{ ...apiKey }}
    >
      {({ submitHandler, loader }) => (
        <>
          <Sidepanel
            className="side-panel"
            title={kid ? i18n._(t`Edit API Keys`) : i18n._(t`Add API Keys`)}
            avatar={avatar}
            onCloseIconClick={resetHandler}
            visible={open}
            footerRender={() => ApiKeysFooter({
              submitHandler,
              resetHandler,
              kid,
            })}
          >
            <div>
              {loader}
              <InputText
                name="description"
                placeholder={i18n._(t`Description`)}
                label={i18n._(t`Description`)}
              />
            </div>
          </Sidepanel>
        </>
      )}
    </Form>
  );
};

ApiKeysSidepanel.propTypes = propTypes;
ApiKeysSidepanel.defaultProps = defaultProps;

export default ApiKeysSidepanel;
