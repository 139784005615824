import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { t } from '@lingui/macro';
import { object, array, bool } from 'prop-types';

import FaIcon from 'App/components/FaIcon/FaIcon';
import Header from 'App/components/Header';
import CurrencyIcon from 'App/components/CurrencyIcon';
import Page from 'App/components/Page/Page';

import './Wallets.scss';

const propTypes = {
  actions: object.isRequired,
  currentBrand: object,
  wallets: array.isRequired,
  i18n: object.isRequired,
  walletsLoading: bool,
};

const defaultProps = {
  currentBrand: null,
  walletsLoading: false,
};

const currencyNames = {
  BTC: 'Bitcoin',
  USD: 'US Dollar',
  EUR: 'Euro',
};

const Wallets = ({
  actions,
  wallets,
  walletsLoading,
  currentBrand,
  i18n,
}) => {
  useEffect(() => {
    if (currentBrand) {
      actions.getBrandWallets(currentBrand.id);
    }

    return () => actions.resetWallet();
  }, [currentBrand]);

  return (
    <Page
      loading={walletsLoading}
      className="wallets-page"
      feadeInTimeout={600}
      header={(
        <Header
          title={i18n._(t`Wallets`)}
          icon={<FaIcon faIcon size="regular" round name="Wallet" color="text-neutral-50" bgColor="bgc-primary-500" />}
        />
      )}
    >
      { wallets.map(data => (
        <Link
          className="wallets-page__link"
          key={data.id}
          to={`/wallets/${data.id}`}
        >
          <div className="wallets-page__link_currency__wrap">
            <CurrencyIcon size="large" currency={data.currency} />
            <div className="wallets-page__link_currency">{ currencyNames[data.currency] }</div>
          </div>

          <div className="wallets-page__link_amount">
            <div className="wallets-page__link_total">Total</div>
            <div>{`${data.available} ${data.currency}`}</div>
          </div>
        </Link>
      )) }
    </Page>
  );
};
Wallets.propTypes = propTypes;
Wallets.defaultProps = defaultProps;

export default Wallets;
