import React, { useState, useEffect, useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import { Redirect, Link } from 'react-router-dom';
import { object, bool } from 'prop-types';
import Space from 'tc-biq-design-system/build/Space';
import Form from 'App/components/Form';
import { InputText } from 'App/components/Form/Field';
import { minLength } from 'App/components/Form/validators';
import { RESET_PASSWORD } from 'App/constants/routes';
import LandingPageLayout from '../../layouts/LandingPageLayout';

import ConfirmAuthModal from '../../components/ConfirmAuthModal';

const propTypes = {
  i18n: object.isRequired,
  actions: object.isRequired,
  isAuthenticated: bool.isRequired,
  loading: bool.isRequired,
  errors: object,
  confirmAuth: object,
};

const defaultProps = {
  errors: null,
  confirmAuth: null,
};

const Login = ({
  i18n,
  actions,
  isAuthenticated,
  loading,
  errors,
  confirmAuth,
}) => {
  const onSubmit = ({ email, password }) => actions.login(email, password);

  const validators = {
    password: [minLength({ i18n })],
  };

  const [openModal, setOpenModal] = useState(!!confirmAuth);

  useEffect(() => {
    setOpenModal(!!confirmAuth);
  }, [confirmAuth]);

  const sendCode = (data) => {
    const { code } = data;
    actions.setConfirmAuth({ ...confirmAuth, code });
    setOpenModal(false);
  };

  const onClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <>
      <LandingPageLayout>
        <div className="form">
          <p className="title48 proxima"><Trans>Sign In</Trans></p>
          <Form
            onSubmit={onSubmit}
            loading={loading}
            errors={errors}
            validators={validators}
            defaultValues={{ 'keep-logged': false }}
          >
            {({ submitHandler, loader }) => (
              <form onSubmit={submitHandler}>
                {loader}
                <InputText
                  name="email"
                  label={i18n._(t`Email`)}
                  placeholder={i18n._(t`Enter Email`)}
                />
                <Space size={30} />
                <InputText
                  name="password"
                  type="password"
                  label={i18n._(t`Password`)}
                  placeholder={i18n._(t`Enter Password`)}
                />
                <Link
                  to={RESET_PASSWORD}
                  className="biq-link tc-paragraph-regular"
                >
                  <Trans>Forgot your password?</Trans>
                </Link>
                <Space size={40} />
                <button
                  className="biq-button primary regular"
                  type="submit"
                  style={{ width: '100%', margin: '0px' }}
                >
                  <Trans>Sign In</Trans>
                </button>
              </form>
            )}
          </Form>
        </div>
      </LandingPageLayout>
      <ConfirmAuthModal
        errors={errors}
        loading={loading}
        sendCode={sendCode}
        visible={openModal}
        onClose={onClose}
      />
      {isAuthenticated && <Redirect to="/" />}
    </>
  );
};

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;

export default Login;
