import React, { useEffect, useState } from 'react';
import { object, bool } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import Avatar from 'tc-biq-design-system/build/Avatar';
import Space from 'tc-biq-design-system/build/Space';
import Button from 'tc-biq-design-system/build/Button';
import { notifier } from 'tc-biq-design-system/build/Notification';

import Page from 'App/components/Page';
import Header from 'App/components/Header';
import Form from 'App/components/Form';
import Accordion from 'App/components/Accordion';
import Divider from 'App/components/Divider';
import QRCodeWrap from 'User/components/QRCode';
import { InputText, Checkbox } from 'App/components/Form/Field';
import { email } from 'App/components/Form/validators';
import { Link } from 'react-router-dom';
import FaIcon from 'App/components/FaIcon';
import { displayName, getInitials } from '../../helpers/user';
import UserService from 'User/services/user';

import './Profile.scss';


const propTypes = {
  user: object,
  i18n: object.isRequired,
  errors: object,
  loading: bool,
  actions: object.isRequired,
};

const defaultProps = {
  user: null,
  errors: null,
  loading: false,
};

const Profile = ({ user, i18n, loading, errors, actions }) => {
  const validators = {
    email: [email({ i18n })],
  };

  const [open, setOpen] = useState(false);

  const onSubmit = data => {
    const payload = {
      receives_email_notifications: data.receives_email_notifications,
    }
    UserService.updateCurrentUser(payload).then(() => {
      notifier.success("Successfully updated profile information");
    }, () => {
      notifier.error("Profile information update failed");
    });
  }


  useEffect(() => actions.clearUser, []);

  return (
    <>
      <Header
        title={displayName(user)}
        icon={<Avatar initials={getInitials(user)} />}
      />
      <Page wrapped>
        <Form
          onSubmit={onSubmit}
          loading={loading}
          errors={errors}
          validators={validators}
          defaultValues={{ ...(user || {}) }}
        >
          {({ submitHandler, loader }) => (
            <>
              <Page.Content>
                {loader}

                <Space size={30} />

                <InputText
                  name="first_name"
                  label={i18n._(t`First name`)}
                  placeholder={i18n._(t`First name`)}
                  disabled
                />

                <Space size={20} />

                <InputText
                  name="last_name"
                  label={i18n._(t`Last name`)}
                  placeholder={i18n._(t`Last name`)}
                  disabled
                />

                <Space size={20} />

                <InputText
                  name="email"
                  label={i18n._(t`Email`)}
                  placeholder={i18n._(t`Email`)}
                  disabled
                />

                <Space size={20} />

                <InputText
                  name="role"
                  label={i18n._(t`Role`)}
                  placeholder={i18n._(t`Role`)}
                  disabled
                />

                <Space size={20} />

                <Link className="link" to="/user/me/change-password">
                  <FaIcon faIcon name="Lock" color="text-primary-600" size="small" />
                  Change password
                </Link>

                <Divider spaceBottom={30} spaceTop={30} />

                <Accordion title="Email notifications">
                  <Space size={20} />
                  <Checkbox
                    name="receives_email_notifications"
                    label="Receive email notifications"
                  />
                </Accordion>

                <Divider spaceBottom={30} spaceTop={30} />

                <Accordion title="Two-factor authentication" onToggle={setOpen}>
                  <QRCodeWrap open={open} />
                </Accordion>

              </Page.Content>

              <Page.Footer>
                <Button
                  onClick={submitHandler}
                >
                  <Trans>Save changes</Trans>
                </Button>
              </Page.Footer>
            </>
          )}
        </Form>
      </Page>
    </>
  );
};

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

export default Profile;
