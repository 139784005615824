import React from 'react';
import { string, object, func } from 'prop-types';
import { t } from '@lingui/macro';

import useTable from 'App/components/Table';
import { formatDate } from 'App/helpers/date';

const TABLE_ID = 'ADDRESSES_TABLE';

const propTypes = {
  resource: string.isRequired,
  i18n: object.isRequired,
  history: object.isRequired,
  match: object.isRequired,
  editChannel: func.isRequired,
};

const AddressesTable = ({ resource, i18n, editChannel, ...props }) => {
  const [Table] = useTable(TABLE_ID, resource);

  const columnDefs = [
    {
      title: i18n._(t`Date`),
      key: 'created_at',
      // eslint-disable-next-line camelcase
      render: ({ created_at }) => formatDate(created_at),
    },
    {
      title: i18n._(t`Name`),
      key: 'name',
    },
    {
      title: i18n._(t`Deposited amount`),
      key: 'deposited_amount',
      render: ({ deposited }) => (deposited[0] ? `${deposited[0].amount} ${deposited[0].currency}` : '-'),
    },
    {
      title: i18n._(t`Received amount`),
      key: 'credited',
      render: ({ credited }) => (credited[0] ? `${credited[0].amount} ${credited[0].currency}` : '-'),
    },
    {
      title: i18n._(t`Exchange percentage`),
      key: 'exchange_percentage',
      render: ({ exchange_percentage }) => (exchange_percentage ? `${exchange_percentage}%` : '-'),
    },
    {
      title: i18n._(t`Address`),
      key: 'deposit_address',
    },
    {
      title: '',
      key: 'actions',
      width: '120px',
      render: data => (
        <>
          <Table.Action iconName="Edit" onClick={() => editChannel(data.id)} />
          <Table.Action
            iconName="View"
            onClick={() => props.history.push(`${props.match.url}/address/${data.id}`)}
          />
        </>
      ),
    },
  ];

  const fields = [
    {
      key: 'created_at',
      id: 'created_at',
      label: 'Date',
      type: 'date',
      input: 'date',
    },
    {
      key: 'name',
      id: 'name',
      label: 'Name',
      type: 'string',
      input: 'string',
    },
    {
      key: 'deposited_amount',
      id: 'deposited_amount',
      label: 'Deposited amount',
      type: 'number',
      input: 'number',
    },
    {
      key: 'received_amount',
      id: 'received_amount',
      label: 'Received amount',
      type: 'number',
      input: 'number',
    },
    {
      key: 'exchange_percentage',
      id: 'exchange_percentage',
      label: 'Exchange %',
      type: 'number',
      input: 'number',
    },
    {
      key: 'deposit_address',
      id: 'deposit_address',
      label: 'Address',
      type: 'string',
      input: 'string',
    },
  ];

  return (
    <Table
      columnDefs={columnDefs}
      fields={fields}
      filterable
      query={{
        ordering: '-created_at',
      }}
    />
  );
};

AddressesTable.propTypes = propTypes;

export default AddressesTable;
