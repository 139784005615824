import React, { useEffect, Fragment } from 'react';
import { t } from '@lingui/macro';
import { bool, func, object, string } from 'prop-types';
import { Sidepanel } from 'tc-biq-design-system/build/Sidepanel';
import Code from 'tc-biq-design-system/build/Code';
import Space from 'tc-biq-design-system/build/Space';
import Spinner from 'tc-biq-design-system/build/Spinner';
import { notifier } from 'tc-biq-design-system/build/Notification';

import Accordion from 'App/components/Accordion';
import PaymentService from 'Wallet/services/payment';
import InvoiceService from 'App/services/invoice';
import PaymentHeader from './PaymentHeader';
import useApi from '../../../App/hooks/useApi';

const propTypes = {
  open: bool.isRequired,
  onSidepanelClose: func.isRequired,
  i18n: object.isRequired,
  pid: string,
  type: string,
  brand: string,
};

const defaultProps = {
  pid: '',
  type: '',
  brand: '',
};

const sidepanelTitles = i18n => ({
  channelpayment: i18n._(t`Channel Payment`),
  withdrawal: i18n._(t`Bank Withdrawal`),
  exchange: i18n._(t`Exchange`),
  invoice: i18n._(t`Invoice`),
});

const AddressPaymentsSidepanel = ({ open, onSidepanelClose, i18n, pid, type, brand }) => {
  let response;

  if (type === 'channelpayment') {
    response = useApi(PaymentService.getChannelPayment);
  } else if (type === 'exchange') {
    response = useApi(PaymentService.getExchange);
  } else if (type === 'withdrawal') {
    response = useApi(PaymentService.getWithdrawal);
  } else if (type === 'invoice') {
    response = useApi(InvoiceService.getInvoice)
  }

  const { run, response: payment, loading, error } = response;

  useEffect(() => {
    if (pid && brand) run(brand, pid);
  }, [pid, brand]);

  useEffect(() => {
    if (error) {
      if (type === 'channel_payment') {
        notifier.error(i18n._(t`Get channel payment error.`));
      } else if (type === 'exchange') {
        notifier.error(i18n._(t`Get exchange error.`));
      } else if (type === 'withdrawal') {
        notifier.error(i18n._(t`Get bank withdrawal error.`));
      }
    }
  }, [error]);

  const showSpinner = loading;
  const showTransactions = !loading && payment;

  return (
    <Sidepanel
      hideFooter
      title={sidepanelTitles(i18n)[type]}
      visible={open}
      onCloseIconClick={onSidepanelClose}
    >
      {showSpinner && <Spinner />}
      {showTransactions && payment.transactions.map(transaction => (
        <Fragment key={transaction.id}>
          <Space size={20} />
          <Accordion
            title={transaction.displayType}
            headerContent={(
              <PaymentHeader
                amount={transaction.amount}
                currency={transaction.instrumentId}
              />
            )}
          >
            <Code data={transaction} />
          </Accordion>
        </Fragment>
      ))}
    </Sidepanel>
  );
};

AddressPaymentsSidepanel.propTypes = propTypes;
AddressPaymentsSidepanel.defaultProps = defaultProps;

export default AddressPaymentsSidepanel;
