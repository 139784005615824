import React, { useState, useEffect } from 'react';
import { array, func, object } from 'prop-types';
import { includes, find } from 'lodash';
import Button from 'tc-biq-design-system/build/Button';
import Select from 'tc-biq-design-system/build/Select';
import { Pill } from 'tc-biq-design-system/build/Pill';
import { operatorsForType, operators } from 'App/services/operator-per-type.js';
import FilterInput from './FilterInput';


import './Filters.scss';

const propTypes = {
  fields: array.isRequired,
  onApply: func.isRequired,
  query: object.isRequired,
};

const Filters = ({ fields, onApply, query }) => {
  const [fieldValue, setFieldValue] = useState(null);
  const [operatorValue, setOperatorValue] = useState(null);
  const [operatorChoices, setOperatorChoices] = useState([]);
  const [addFilterVisible, addFilter] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [activeFilters, setActiveFilters] = useState(null);
  const [isPillVisible, setPillVisible] = useState(false);

  useEffect(() => {
    if (!query) return;
    const ignoredKeys = ['ordering', 'limit', 'offset'];
    const filters = Object.keys(query).filter(key => !includes(ignoredKeys, key));
    const foo = {};
    filters.forEach((key) => {
      foo[key] = query[key];
    });
    setActiveFilters(foo);
  }, [query]);

  useEffect(() => {
    if (activeFilters && Object.keys(activeFilters).length) setPillVisible(true);
  }, [activeFilters]);

  const onFieldChange = (value) => {
    let field = null;
    if (value) {
      field = value;
    }
    setFieldValue(field);
    setOperatorChoices(operatorsForType(field.type));
  };

  const onOperatorChange = (value) => {
    let operator = null;
    if (value) operator = value;
    setOperatorValue(operator);
  };

  const onInputChange = (value) => {
    setInputValue(value);
  };

  const parseQuery = () => {
    let { key } = fieldValue;
    if (operatorValue.id !== 'exact') {
      key = `${fieldValue.key}__${operatorValue.id}`;
    }
    const value = inputValue;
    const parsedQuery = {};

    parsedQuery[key] = value;
    return parsedQuery;
  };

  const resetCurrentFilter = () => {
    addFilter(false);
    setFieldValue(null);
    setOperatorValue(null);
    setInputValue(null);
  };

  const applyFilter = () => {
    const filter = parseQuery();
    const newQuery = {
      ...query,
      ...filter,
    };
    onApply(newQuery);
    resetCurrentFilter();
  };

  const getFieldLabel = field => fields.find(el => el.key === field).label;
  const getOperatorLabel = operator => find(operators, el => el.id === operator).label;

  const onCloseIconClick = (field, operator) => {
    let key = field;
    if (operator !== 'exact') {
      key = `${field}__${operator}`;
    }
    const newQuery = { ...query };
    delete newQuery[key];
    onApply(newQuery);
  };

  const ActiveFiltersPills = () => {
    const pills = Object.keys(activeFilters).map((key) => {
      let field = '';
      let operator = '';
      let isAnOperator = false;
      const foo = key.split('__');
      if (foo.length === 1) {
        operator = 'exact';
        field = foo[0];
      } else {
        operator = foo.pop();
        isAnOperator = !!Object.keys(operators).find(el => el === operator);
        if (isAnOperator) {
          field = foo.join('__');
        } else {
          field = `${foo.join('__')}__${operator}`;
          operator = 'exact';
        }
      }
      const fieldLabel = getFieldLabel(field);
      const operatorLabel = getOperatorLabel(operator);
      const value = activeFilters[key];
      return (
        <Pill
          big
          icon="Close"
          key={field}
          onIconClick={() => onCloseIconClick(field, operator)}
        >
          <b>{fieldLabel} {operatorLabel.toLowerCase()} {value}</b>
        </Pill>
      );
    });
    return (
      <div>{pills}</div>
    );
  };

  return (
    <div className="filters-block">

      { !addFilterVisible && (
        <div className="filters-item">
          <Button color="transparent" icon="Plus" onClick={() => addFilter(true)}>
          Add filter
          </Button>
        </div>
      )}

      { addFilterVisible && (
        <div style={{ width: '250px' }}>
          <Select
            placeholder="Choose field"
            type="single"
            value={fieldValue}
            onChange={onFieldChange}
            options={fields}
            clearable
          />
        </div>
      )}

      { addFilterVisible && fieldValue && (
        <div style={{ width: '250px' }}>
          <Select
            placeholder="Choose field"
            type="single"
            value={operatorValue}
            onChange={onOperatorChange}
            options={operatorChoices}
            clearable
          />
        </div>
      ) }

      { addFilterVisible && fieldValue && operatorValue && fieldValue.type && (
        <FilterInput
          type={fieldValue.type}
          operator={operatorValue.id}
          value={inputValue}
          onChange={onInputChange}
        />
      ) }

      { addFilterVisible && (
        <Button onClick={applyFilter}>
          Apply filter
        </Button>
      ) }

      { isPillVisible && (
        <ActiveFiltersPills />
      )}

    </div>
  );
};

Filters.propTypes = propTypes;

export default Filters;
