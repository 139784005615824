export const invoiceStatuses = {
  COMPLETED: {
    pill: 'status01',
    title: 'Completed',
  },
  CANCELLED: {
    pill: 'status04',
    title: 'Cancelled',
  },
  OVERPAID: {
    pill: 'primary',
    title: 'Overpaid',
  },
  PAID_LATE: {
    pill: 'status02',
    title: 'Paid late',
  },
  UNDERPAID: {
    pill: 'status04',
    title: 'Underpaid',
  },
  PENDING: {
    pill: 'neutral',
    title: 'Pending',
  },
  REFUNDED: {
    pill: 'status01',
    title: 'Refunded',
  },
  PAID: {
    pill: 'status01',
    title: 'Paid',
  },
};

export const filterFields = [
  {
    type: 'date',
    input: 'date',
    key: 'created_at',
    id: 'created_at',
    label: 'Date',
  },
  {
    type: 'string',
    input: 'text',
    key: 'status',
    id: 'status',
    label: 'Status',
  },
  {
    type: 'string',
    input: 'text',
    key: 'custom_id',
    id: 'custom_id',
    label: 'Custom ID',
  },
  {
    type: 'string',
    input: 'text',
    key: 'address__deposit_address',
    id: 'address__deposit_address',
    label: 'Address',
  },
  {
    type: 'string',
    input: 'text',
    key: 'name',
    id: 'name',
    label: 'Name',
  },
  {
    type: 'string',
    input: 'text',
    key: 'description',
    id: 'description',
    label: 'Description',
  },
  {
    type: 'number',
    input: 'number',
    key: 'amount',
    id: 'amount',
    label: 'Invoice Amount',
  },
  {
    type: 'string',
    input: 'text',
    key: 'currency',
    id: 'currency',
    label: 'Invoice Currency',
  },
  {
    type: 'number',
    input: 'number',
    key: 'original_exchange_rate',
    id: 'original_exchange_rate',
    label: 'Guaranteed Price',
  },
  {
    type: 'number',
    input: 'number',
    key: 'pay_amount',
    id: 'pay_amount',
    label: 'Pay Amount',
  },
  {
    type: 'number',
    input: 'number',
    key: 'pay_amount',
    id: 'pay_amount',
    label: 'Pay Currency',
  },
  {
    type: 'number',
    input: 'number',
    key: 'exchanged_amount',
    id: 'exchanged_amount',
    label: 'Exchanged Amount',
  },
  {
    type: 'string',
    input: 'text',
    key: 'currency',
    id: 'currency',
    label: 'Exchanged Currency',
  },
  {
    type: 'number',
    input: 'number',
    key: 'exchange_fee',
    id: 'exchange_fee',
    label: 'Exchange Fee',
  },
  {
    type: 'string',
    input: 'text',
    key: 'exchange_fee_currency',
    id: 'exchange_fee_currency',
    label: 'Exchange Fee Currency',
  },
  {
    type: 'number',
    input: 'number',
    key: 'deposit_fee',
    id: 'deposit_fee',
    label: 'Deposit Fee',
  },
  {
    type: 'string',
    input: 'text',
    key: 'deposit_fee_currency',
    id: 'deposit_fee_currency',
    label: 'Deposit Fee Currency',
  },
  {
    type: 'number',
    input: 'number',
    key: 'exchange_rate',
    id: 'exchange_rate',
    label: 'Exchange Rate',
  },
  {
    type: 'string',
    input: 'text',
    key: 'exchange_symbol',
    id: 'exchange_symbol',
    label: 'Exchange Symbol',
  },
  {
    type: 'number',
    input: 'number',
    key: 'credited',
    id: 'credited',
    label: 'Credited Amount',
  },
  {
    type: 'string',
    input: 'text',
    key: 'credited_currency',
    id: 'credited_currency',
    label: 'Credited Currency',
  },
  {
    type: 'number',
    input: 'number',
    key: 'overage',
    id: 'overage',
    label: 'Overage',
  },
];
