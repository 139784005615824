import { t } from '@lingui/macro';

export const email = ({ message, i18n } = {}) => ({
  test: value => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value),
  message: message || i18n._(t`Enter a valid email address`),
});

export const minLength = ({ message, i18n, length = 5 } = {}) => ({
  test: value => value && value.length >= length,
  message: message || i18n._(t`Please enter at least ${length} characters.`),
});

export const maxLength = ({ message, i18n, length = 6 } = {}) => ({
  test: value => !value || value.length <= length,
  message: message || i18n._(t`Please enter maximum ${length} characters.`),
});

export const required = ({ message, i18n } = {}) => ({
  test: value => !!value && value.length !== 0,
  message: message || i18n._(t`This field is required.`),
});

export const isValidPercentage = ({ message, i18n } = {}) => ({
  test: value => !value || /^(\d{1,2}(\.\d{0,5})?)$|^100$/g.test(value),
  message: message || i18n._(t`Enter a valid number.`),
});

export const isSameValue = ({ message, i18n, field } = {}) => ({
  test: (value, formValues) => !value || value === formValues[field],
  message: message || i18n._(t`Must match the previous entry`),
});
