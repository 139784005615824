import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';

import WalletsPage from 'Wallet/pages/Wallets';
import WalletPage from 'Wallet/pages/Wallet';
import BrandsPage from 'Brand/pages/Brands';
import BrandPage from 'Brand/pages/BrandItem';
import UsersPage from 'User/pages/Users';
import ProfilePage from 'User/pages/Profile';
import AddressPayments from 'Wallet/pages/AddressPayments';
import ChangePassword from 'User/pages/Profile/ChangePassword';
import BankAccounts from 'BankAccount/pages/BankAccounts';
import AuditLogs from 'AuditLogs/pages/AuditLogs';
import BrandDependentRoute from 'App/components/BrandDependentRoute';
import TransactionHistoryAddressPayments from 'TransactionHistory/pages/AddressPayments';
import BankWithdrawals from 'TransactionHistory/pages/BankWithdrawals';
import CryptoWithdrawals from 'TransactionHistory/pages/CryptoWithdrawals';
import InvoiceHistory from 'TransactionHistory/pages/InvoiceHistory';
import RBACRoute from '../../components/RBACRoute';

const MainRouter = () => (
  <Fragment>
    <Route path="/" exact render={() => <Redirect to="/wallets" />} />

    <RBACRoute
      path="/user/me"
      exact
      component={ProfilePage}
      roles={['admin', 'operator', 'accountant']}
    />

    <RBACRoute
      path="/user/me/change-password"
      exact
      component={ChangePassword}
      roles={['admin', 'operator', 'accountant']}
    />

    <RBACRoute
      path="/wallets"
      exact
      component={WalletsPage}
      roles={['admin', 'operator', 'accountant']}
    />

    <BrandDependentRoute
      path="/wallets/:wid"
      exact
      component={WalletPage}
      roles={['admin', 'operator', 'accountant']}
    />

    <RBACRoute
      path="/wallets/:wid/address/:aid"
      exact
      component={AddressPayments}
      roles={['admin', 'operator', 'accountant']}
    />

    <RBACRoute
      path="/system/audit-logs"
      exact
      component={AuditLogs}
      roles={['admin']}
    />

    <BrandDependentRoute
      path="/transaction-history/channel-payments"
      exact
      component={TransactionHistoryAddressPayments}
      roles={['admin', 'operator', 'accountant']}
    />

    <BrandDependentRoute
      path="/transaction-history/bank-withdrawals"
      exact
      component={BankWithdrawals}
      roles={['admin', 'operator', 'accountant']}
    />

    <BrandDependentRoute
      path="/transaction-history/crypto-withdrawals"
      exact
      component={CryptoWithdrawals}
      roles={['admin', 'operator', 'accountant']}
    />

    <BrandDependentRoute
      path="/transaction-history/invoices"
      exact
      component={InvoiceHistory}
      roles={['admin', 'operator', 'accountant']}
    />

    <RBACRoute
      path="/system/bank-accounts"
      exact
      component={BankAccounts}
      roles={['admin']}
    />

    <RBACRoute
      path="/system/brands"
      exact
      component={BrandsPage}
      roles={['admin']}
    />

    <RBACRoute
      path="/system/brands/new"
      exact
      component={BrandPage}
      roles={['admin']}
    />

    <RBACRoute path="/system/brands/:id/edit" exact component={BrandPage} roles={['admin']} />
    <RBACRoute path="/system/users" exact component={UsersPage} roles={['admin']} />
  </Fragment>
);

export default MainRouter;
