import { put, takeLatest, call, select } from 'redux-saga/effects';
import { tableActionTypesGenerator, tableActionsGenerator } from './model';
import GenericService from '../../../services/generic';

/**
 * Table saga generator
 *
 * @description Used in root saga with table specific key.
 * Check this file `App/redux/sagas/index.js`
 * @example `
  export default function* rootSaga() {
    yield all([
      ...tableSagaGenerator('BRANDS_TABLE'),
    ]);
  }
 * `
 * @param {string} key
 */
export const tableSagaGenerator = (key) => {
  const {
    FETCH_TABLE_DATA_REQUEST,
  } = tableActionTypesGenerator(key);

  const actions = tableActionsGenerator(key);

  function* getQuery(query) {
    if (query) return query;
    const queryObj = yield select(({ tables }) => tables[key].query);
    const currentPage = yield select(({ tables }) => tables[key].currentPage);
    const pageSize = yield select(({ tables }) => tables[key].pageSize);
    return {
      ...queryObj,
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
    };
  }

  function* fetchTableData({ query }) {
    yield put(actions.fetchTableDataPending());
    try {
      const url = yield select(({ tables }) => tables[key].url);
      const newQuery = yield call(getQuery, query);
      const { data } = yield call(GenericService.get, url, newQuery);

      yield put(actions.fetchTableDataSuccess(data, query));
    } catch (err) {
      yield put(actions.fetchTableDataFailure(err));
    }
  }

  return [takeLatest(FETCH_TABLE_DATA_REQUEST, fetchTableData)];
};
