export const smartlook = document.createElement('script');

smartlook.text = `
  window.smartlook||(function(d) {
  var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
  var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
  c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
  })(document);
  smartlook('init', '${process.env.SMARTLOOK}');
`;

export const addSmartlookIdentify = (user) => {
  const smartlookIdentify = document.createElement('script');
  smartlookIdentify.id = 'smartlookIdentify';

  smartlookIdentify.text = `
    smartlook('identify', '${user.id}', {
      'name': '${user.first_name} ${user.last_name}',
      'email': '${user.email}',
      'customer_name': '${user.customer}',
    });
  `;

  if (!document.getElementById('smartlookIdentify')) document.body.appendChild(smartlookIdentify);
};

export const addIntercomSnippet = (user) => {
  const intercom = document.createElement('script');
  intercom.id = 'intercomIntegration';

  intercom.text = `
    window.intercomSettings = {
      app_id: "${process.env.INTERCOM}",
      name: "${user.first_name} ${user.last_name}",
      email: "${user.email}",
      production: ${window.location.origin === 'https://app.orbpay.com'},
      company: {
        "name": "${user.customer_name}",
        "company_id": "${user.customer}",
      }
    };

    (function(){
      var w=window;
      var ic=w.Intercom;
      if(typeof ic==="function") {
        ic('reattach_activator');
        ic('update',w.intercomSettings);
      }
      else {
        var d=document;
        var i=function() {
          i.c(arguments);
        };
        i.q=[];
        i.c=function(args) {
          i.q.push(args);
        };
        w.Intercom=i;
        var l=function() {
          var s=d.createElement('script');
          s.type='text/javascript';
          s.async=true;
          s.src='https://widget.intercom.io/widget/on7k0wpm';
          s.id='intercomInit';
          var x=d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s,x);
        };
        if(w.attachEvent) {
          w.attachEvent('onload',l);
        }
        else if (!document.getElementById('intercomInit')) {
          l();
        }
      }
    })();
  `;

  if (!document.getElementById('intercomIntegration')) document.body.appendChild(intercom);
};

export const pusher = document.createElement('script');

pusher.text = `
  var pusher = new Pusher('${process.env.PUSHER}', {
    cluster: 'eu',
    forceTLS: true
  });
`;
