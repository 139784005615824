import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import Button from 'tc-biq-design-system/build/Button';
import Space from 'tc-biq-design-system/build/Space';
import Modal from 'tc-biq-design-system/build/Modal';
import InfoBox from 'tc-biq-design-system/build/InfoBox';
import { object, bool } from 'prop-types';

import { required } from 'App/components/Form/validators';
import Header from 'App/components/Header/Header';
import FaIcon from 'App/components/FaIcon';
import InputText from 'App/components/Form/Field/InputText';
import Form from 'App/components/Form';
import Divider from 'App/components/Divider';
import Accordion from 'App/components/Accordion';
import ButtonLink from 'App/components/ButtonLink';
import Page from 'App/components/Page/Page';
import { notifier } from 'tc-biq-design-system/build/Notification';
import ApiKeysTable from './ApiKeysTable';
import ApiKeysSidepanel from './ApiKeysSidepanel';

import { resources as apiKeysResources } from '../../services/apiKey';
import CallbackSecretService from '../../services/callbackSecret';
import CallbackSecretModal from './CallbackSecretModal';
import ApiKeysModal from './ApiKeysModal';

const propTypes = {
  actions: object.isRequired,
  i18n: object.isRequired,
  match: object.isRequired,
  loading: bool.isRequired,
  errors: object,
  brand: object,
  history: object.isRequired,
};

const defaultProps = {
  brand: null,
  errors: null,
};


const BrandItem = ({ actions, match, i18n, loading, errors, brand, history }) => {
  const { params: { id } } = match;
  const [openPanel, setOpenPanel] = useState(false);
  const [apiKeyId, setApiKeyId] = useState(null);
  const [callbackSecret, setCallbackSecret] = useState('');
  const [buttonStatus, setButtonStatus] = useState(false);
  const [showModal, setModalShown] = useState(false);

  const footerRender = () => (
    <div>
      <Button
        color="ghost"
        onClick={onConfirmationModalClose}
      >
        Decline
      </Button>
      <Button
        onClick={generateSecret}
      >
        Proceed
      </Button>
    </div>
  );

  useEffect(() => {
    if (id) {
      actions.getBrand(id);
    } else {
      actions.clearBrand();
    }
  }, []);

  const validators = {
    name: [required({ i18n })],
  };

  const onSubmit = (formData) => {
    const valueData = {
      name: formData.name,
      website: formData.website,
      description: formData.description,
      callback_url: formData.callback_url,
      bc_account_id: formData.bc_account_id,
      customer: formData.customer,
    };
    if (id) {
      actions.updateBrand({
        id: match.params.id,
        valueData,
        refreshTable: true,
      });
    } else {
      actions.createBrand(valueData);
    }
  };

  const onDiscard = () => {
    actions.clearBrand();
    history.push('/system/brands');
  };

  const onConfirmationModalClose = () => {
    setModalShown(false);
  };

  const generateSecret = () => {
    setModalShown(false);
    setButtonStatus(true);
    CallbackSecretService.getItem(id)
      .then((res) => {
        setCallbackSecret(res.data.callback_secret);
        setButtonStatus(true);
      })
      .catch(() => {
        notifier.error(<Trans>Callback secret failed</Trans>);
        setButtonStatus(true);
      });
  };

  const addApiKey = () => {
    setOpenPanel(true);
  };

  const addApiKeyClose = () => {
    setApiKeyId(null);
    setOpenPanel(false);
  };

  const editApiKey = (kid) => {
    setApiKeyId(kid);
    setOpenPanel(true);
  };

  return (
    <>
      <Header
        title={id ? i18n._(t`Edit Brand`) : i18n._(t`New Brand`)}
        icon={<FaIcon size="regular" round name={id ? 'Edit' : 'Plus'} color="text-neutral-50" bgColor="bgc-primary-500" />}
      />
      <Page wrapped>
        <Form
          onSubmit={onSubmit}
          loading={loading}
          errors={errors}
          validators={validators}
          defaultValues={{ ...brand }}
        >
          {({ submitHandler, loader }) => (

            <>
              <Page.Content>
                {loader}

                <InputText
                  name="name"
                  placeholder={i18n._(t`Brand name`)}
                  label={i18n._(t`Brand name`)}
                />

                <Space size={11} />

                <InputText
                  name="website"
                  placeholder="http://"
                  label={i18n._(t`Website`)}
                />

                <Space size={11} />

                <InputText
                  name="description"
                  placeholder={i18n._(t`Description`)}
                  label={i18n._(t`Description`)}
                />

                <Divider spaceBottom={30} spaceTop={30} />

                <Accordion title={i18n._(t`IPN`)} open={!!id}>

                  <InputText
                    name="callback_url"
                    placeholder={i18n._(t`IPN URL`)}
                    label={i18n._(t`IPN URL`)}
                  />

                  <Space size={11} />

                  <InputText
                    name="callback_secret"
                    disabled
                    placeholder={i18n._(t`IPN Secret`)}
                    label={i18n._(t`IPN Secret`)}
                    labelAction={(
                      <ButtonLink
                        color="transparent"
                        icon="Plus"
                        disabled={buttonStatus}
                        onClick={setModalShown}
                      >
                        Regenerate secret
                      </ButtonLink>
                    )}
                  />

                  <Space size={11} />

                </Accordion>

                {id && (
                  <>
                    <Divider spaceBottom={30} spaceTop={30} />

                    <Accordion title="API Keys" open={!!id}>

                      <ApiKeysTable
                        resource={apiKeysResources.apiKeys(id)}
                        addApiKey={addApiKey}
                        editApiKey={editApiKey}
                        bid={id}
                      />

                    </Accordion>
                  </>
                )}
              </Page.Content>

              <Page.Footer>
                <Button
                  color="ghost"
                  onClick={onDiscard}
                >
                  { id && (<Trans>Discard changes</Trans>)}
                  { !id && (<Trans>Discard</Trans>)}
                </Button>

                <Button
                  onClick={submitHandler}
                >
                  { id && (<Trans>Save changes</Trans>)}
                  { !id && (<Trans>Add Brand</Trans>)}
                </Button>
              </Page.Footer>
            </>
          )}
        </Form>
      </Page>

      <Modal
        visible={showModal}
        title={i18n._(t`Generate new secret?`)}
        footerRender={() => footerRender()}
      >
        <div>
          <InfoBox type="warning">
            Processing the
            <strong> Regenerate secret </strong>
            request may result
            in breaking the authentication of IPNs on your side.
          </InfoBox>

          <Space size={10} />

          <strong>Are you sure you want to proceed?</strong>
        </div>
      </Modal>

      <ApiKeysSidepanel open={openPanel} onSidepanelClose={addApiKeyClose} kid={apiKeyId} />
      <ApiKeysModal />
      <CallbackSecretModal callbackSecret={callbackSecret} />
    </>
  );
};

BrandItem.propTypes = propTypes;
BrandItem.defaultProps = defaultProps;

export default BrandItem;
