import React, { useEffect } from 'react';
import { I18nProvider } from '@lingui/react';
import { string, object } from 'prop-types';
import { Notification } from 'tc-biq-design-system/build/Notification';

import en from 'locales/en/messages';
import fr from 'locales/fr/messages';
import AppRouter from './AppRouter';

import '../../styles/styles.scss';
import './App.scss';

const catalogs = {
  en,
  fr,
};

const propTypes = {
  language: string.isRequired,
  actions: object.isRequired,
};

const App = ({ language, actions }) => {
  useEffect(() => {
    actions.loadCurrentUser();
  }, []);

  return (
    <I18nProvider language={language} catalogs={catalogs}>
      <AppRouter />
      <Notification transition="slide" duration={5} />
    </I18nProvider>
  );
};

App.propTypes = propTypes;

export default App;
