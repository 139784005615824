import React from 'react';
import Select from 'tc-biq-design-system/build/Select';
import { node, string, arrayOf, object } from 'prop-types';
import Field from '../Field';
import './SelectField.scss';

const propTypes = {
  name: string.isRequired,
  labelAction: node,
  options: arrayOf(object),
  labelField: string,
};

const defaultProps = {
  labelAction: null,
  options: null,
  labelField: null,
};

const SelectField = ({ name, labelAction, options, labelField, ...rest }) => {
  const getValue = (data) => {
    if (!options) return data;
    return options.find(item => data
      && (data === item.value || data.value === item.value));
  };

  return (
    <Field name={name}>
      {({ onChange, value, error }) => (
        <div className="input-wrapper">
          <div className="input-children">{labelAction}</div>
          <Select
            value={getValue(value) || rest.value}
            options={options}
            helpText={error && error[0]}
            hasError={error && error[0]}
            onChange={data => onChange(data)}
            {...rest}
          />
        </div>
      )}
    </Field>
  );
};

SelectField.propTypes = propTypes;
SelectField.defaultProps = defaultProps;

export default SelectField;
