export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const INVALID_TOKEN = 'INVALID_TOKEN';
export const LOAD_CURRENT_USER_REQUEST = 'LOAD_CURRENT_USER_REQUEST';
export const LOAD_CURRENT_USER_SUCCESS = 'LOAD_CURRENT_USER_SUCCESS';
export const LOAD_CURRENT_USER_FAILURE = 'LOAD_CURRENT_USER_FAILURE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CONFIRM_AUTH = 'SET_CONFIRM_AUTH';
export const TFA_REQUEST = 'TFA_REQUEST';
export const TFA_SUCCESS = 'TFA_SUCCESS';
export const TFA_FAILURE = 'TFA_FAILURE';
export const SET_NEW_PASSWORD_REQUEST = 'SET_NEW_PASSWORD_REQUEST';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE';
export const ACCEPT_INVITE_REQUEST = 'ACCEPT_INVITE_REQUEST';
export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS';
export const ACCEPT_INVITE_FAILURE = 'ACCEPT_INVITE_FAILURE';

const savedToken = localStorage.getItem('auth_token') || null;

const initialState = {
  loading: false,
  errors: null,
  token: savedToken,
  confirmAuth: null,
  user: null,
  userLoading: false,
  passwordChangeLoading: false,
  passwordChanged: false,
};

const login = (email, password) => ({
  type: LOGIN_REQUEST,
  email,
  password,
});

const logout = () => ({
  type: LOGOUT_REQUEST,
});

const loadCurrentUser = () => ({
  type: LOAD_CURRENT_USER_REQUEST,
});

const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  user,
});

const setConfirmAuth = ({ code, email, password }) => ({
  type: TFA_REQUEST,
  code,
  email,
  password,
});

const invalidToken = () => ({
  type: INVALID_TOKEN,
});

const setNewPassword = ({ token, password }) => ({
  type: SET_NEW_PASSWORD_REQUEST,
  token,
  password,
});

const acceptInvite = ({ token, password }) => ({
  type: ACCEPT_INVITE_REQUEST,
  token,
  password,
});

export const actions = {
  login,
  logout,
  invalidToken,
  loadCurrentUser,
  setCurrentUser,
  setConfirmAuth,
  setNewPassword,
  acceptInvite,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_REQUEST:
      return {
        ...initialState,
        token: null,
      };
    case INVALID_TOKEN:
      return {
        ...initialState,
        token: null,
      };
    case LOGIN_REQUEST:
    case TFA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
    case TFA_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
        confirmAuth: null,
      };
    case LOGIN_FAILURE:
    case TFA_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        confirmAuth: null,
      };
    case SET_CONFIRM_AUTH:
      return {
        ...state,
        loading: false,
        confirmAuth: action.payload,
      };
    case LOAD_CURRENT_USER_REQUEST:
      return {
        ...state,
        userLoading: true,
      };
    case LOAD_CURRENT_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        user: action.payload,
      };
    case LOAD_CURRENT_USER_FAILURE:
      return {
        ...state,
        userLoading: false,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.user,
      };
    case ACCEPT_INVITE_REQUEST:
    case SET_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        passwordChangeLoading: true,
      };
    case ACCEPT_INVITE_SUCCESS:
    case SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChangeLoading: false,
        passwordChanged: true,
      };
    case ACCEPT_INVITE_FAILURE:
    case SET_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        passwordChangeLoading: false,
        passwordChanged: false,
      };
    default:
      return state;
  }
}
