import React, { useEffect, useState, useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import { object, bool } from 'prop-types';
import { includes } from 'lodash';
import Detail from 'tc-biq-design-system/build/Detail';
import { Tabs, Tab } from 'tc-biq-design-system/build/Tabs';
import { Row, Col } from 'tc-biq-design-system/build/Grid';
import Button from 'tc-biq-design-system/build/Button';
import Space from 'tc-biq-design-system/build/Space';

import Page from 'App/components/Page/Page';
import DisplayTypeCell from 'App/components/TableCells/DisplayTypeCell/DisplayTypeCell';
import Header from 'App/components/Header';
import { formatDate } from 'App/helpers/date';
import { stringTruncate } from 'App/helpers';
import CurrencyIcon from 'App/components/CurrencyIcon';
import CurrencyCell from 'App/components/TableCells/CurrencyCell/CurrencyCell';
import useTable from 'App/components/Table';
import AddressesTable from './AddressesTable';
import PendingWithdrawalsTable from './PendingWithdrawalsTable';
import PendingWithdrawalsSidepanel from '../../components/PendingWithdrawalsSidepanel';
import { resources as walletResources } from '../../services/wallet';
import { resources as withdrawalResources } from '../../services/withdraw';
import AddressPaymentsSidepanel from '../../components/AddressPaymentsSidepanel';
import AddressSidePanel from './AddressSidePanel';
import WithdrawModal from '../../components/WithdrawModal';
import ExchangeModal from '../../components/ExchangeModal';
import {
  isCryptoCurrencyType,
  isFiatCurrencyType,
  isExchangeableCurrency,
  isWithdrawableCurrency,
} from '../../helpers';

import './Wallet.scss';

const TABLE_ID = 'WALLET_TRANSACTIONS_TABLE';

const currencies = i18n => ({
  USD: {
    label: i18n._(t`US Dollar`),
  },
  EUR: {
    label: i18n._(t`EUR`),
  },
  BTC: {
    label: i18n._(t`Bitcoin`),
  },
});

const cols = onSidepanelOpen => [
  {
    title: 'Date',
    key: 'createdAt',
    render: ({ createdAt }) => formatDate(createdAt),
  },
  {
    title: 'Type',
    key: 'displayType',
    render: data => <DisplayTypeCell data={data} />,
  },
  {
    title: 'Amount',
    key: 'amount',
    render: data => <CurrencyCell amount={data.amount} currency={data.instrumentId} />,
  },
  {
    title: 'Balance',
    key: 'available',
    // eslint-disable-next-line react/prop-types
    render: data => <CurrencyCell amount={data.total} currency={data.instrumentId} />,
  },
  {
    title: 'Reference',
    key: 'relatedObject',
    render: data => (data.relatedObject ? (
      <Button
        onClick={() => onSidepanelOpen(data.relatedObject, data.relatedObjectType)}
        icon="View"
        color="transparent"
        title={data.relatedObject}
      >
        {`${stringTruncate(data.relatedObject, 4, 4)}`}
      </Button>
    ) : null),
  },
];

const fields = [
  {
    key: 'date',
    id: 'date',
    label: 'Date',
    input: 'date',
    type: 'date',
  },
  {
    key: 'displayType',
    id: 'displayType',
    label: 'Type',
    input: 'string',
    type: 'string',
  },
  {
    key: 'amount',
    id: 'amount',
    label: 'Amount',
    input: 'number',
    type: 'number',
  },
  {
    key: 'total',
    id: 'total',
    label: 'Balance',
    input: 'number',
    type: 'number',
  },
  {
    key: 'relatedObject',
    id: 'relatedObject',
    label: 'Reference',
    input: 'string',
    type: 'string',
  },
];

const propTypes = {
  i18n: object.isRequired,
  actions: object.isRequired,
  match: object.isRequired,
  wallet: object,
  user: object,
  loadingWallet: bool.isRequired,
  currentBrand: object,
};

const defaultProps = {
  wallet: null,
  user: null,
  currentBrand: null,
};

const Wallet = (
  {
    i18n,
    actions,
    match: {
      params: { wid },
    },
    wallet,
    user,
    loadingWallet,
    currentBrand,
  },
) => {
  const [sidepanelOpen, setSidepanelOpen] = useState(false);
  const [panelOpen, setPanelOpen] = useState(false);
  const [approvalsPanelOpen, setApprovalsPanelOpen] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [exchangeOpen, setExchangeOpen] = useState(false);
  const [withdrawalId, setWithdrawalId] = useState(null);
  const [isEdit, setEdit] = useState(false);

  const [channelId, setChannelId] = useState(null);

  const [Table] = useTable(TABLE_ID, walletResources.transactions(currentBrand.id, wid));

  const onSidepanelOpen = useCallback((pid, type) => {
    setPaymentId(pid);
    setPaymentType(type);
    setSidepanelOpen(true);
  });

  const onSidepanelClose = useCallback(() => setSidepanelOpen(false));
  const onPanelClose = useCallback(() => {
    setChannelId(null);
    setEdit(false);
    setPanelOpen(false);
  });

  const onApprovalsPanelOpen = (id) => {
    setWithdrawalId(id);
    setApprovalsPanelOpen(true);
  };

  const onApprovalsPanelClose = useCallback(() => {
    setApprovalsPanelOpen(false);
  });

  useEffect(() => {
    if (currentBrand) actions.getWallet(currentBrand.id, wid);

    return () => actions.resetWallet();
  }, [currentBrand]);

  const { currency } = wallet || {};

  const currencyConf = currencies(i18n)[currency];

  const editAddressOpen = (id) => {
    setChannelId(id);
    setEdit(true);
    setPanelOpen(true);
  };

  const openWithdraw = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openExchangeModal = () => {
    setExchangeOpen(true);
  };

  const closeExchangeModal = () => {
    setExchangeOpen(false);
  };

  return (
    <Page
      loading={loadingWallet}
    >
      { !loadingWallet && currencyConf && wallet && (
        <>
          <Header
            title={currencyConf.label}
            backLink="/wallets"
            icon={<CurrencyIcon size="regular" currency={currency} />}
            actions={(
              <>
                { isExchangeableCurrency(currency) && includes(['admin', 'operator'], user.role) && (
                  <>
                    <Header.Separator />
                    <Header.Action title="Exchange" onAction={openExchangeModal} icon="Exchange" />
                  </>
                )}
                { isWithdrawableCurrency(currency) && includes(['admin', 'operator'], user.role) && (
                  <>
                    <Header.Separator />
                    <Header.Action title={i18n._(t`Bank Withdrawal`)} onAction={openWithdraw} icon="University" />
                  </>
                )}
              </>
            )}
          >

            <Header.Separator />
            <div className="wallet-page__header">
              <Detail reverse entity="Total" value={`${wallet.total} ${currency}`} />
              <Detail reverse entity="Reserved" value={`${wallet.reserved} ${currency}`} />
              <Detail reverse entity="Available" value={`${wallet.available} ${currency}`} />
            </div>
          </Header>

          {
            isCryptoCurrencyType(currency) && (
              <Tabs>
                <Tab visible title={i18n._(t`Transactions`)}>
                  <Row>
                    <Col>
                      <Space size={10} />
                      { Table && (
                        <Table
                          striped
                          columnDefs={cols(onSidepanelOpen)}
                          fields={fields}
                          query={{
                            ordering: '-created_at',
                          }}
                          filterable
                        />
                      )}
                    </Col>
                  </Row>
                </Tab>
                <Tab title={i18n._(t`Channels`)}>
                  {currentBrand && (
                  <Row>
                    <Col>
                      <Space size={10} />
                      <div className="text-right">
                        {user && includes(['admin', 'operator'], user.role) && (
                          <Button onClick={() => setPanelOpen(!panelOpen)}>
                            <Trans>Generate channel</Trans>
                          </Button>
                        )}
                      </div>
                      <Space size={10} />
                      <AddressesTable
                        editChannel={editAddressOpen}
                        resource={walletResources.channels(currentBrand.id, wid)}
                      />
                    </Col>
                  </Row>
                  )}
                </Tab>
              </Tabs>
            )
          }

          <AddressSidePanel
            channelId={channelId}
            currentBrand={currentBrand}
            onSidepanelClose={onPanelClose}
            open={panelOpen}
            isEdit={isEdit}
          />
        </>
      ) }

      {
        isFiatCurrencyType(currency) && (
          <Row>
            <Col>
              <Space size={10} />
              { Table && (
                <Table
                  striped
                  columnDefs={cols(onSidepanelOpen)}
                  fields={fields}
                  query={{
                    ordering: '-created_at',
                  }}
                  filterable
                />
              ) }
            </Col>
          </Row>
        )
      }

      {
        isFiatCurrencyType && isWithdrawableCurrency(currency) && includes([], user.role) && (
          <Tabs>
            <Tab visible title={i18n._(t`Transactions`)}>
              <Row>
                <Col>
                  <Space size={10} />
                  { Table && (
                    <Table
                      striped
                      columnDefs={cols(onSidepanelOpen)}
                      fields={fields}
                      query={{
                        ordering: '-created_at',
                      }}
                      filterable
                    />
                  )}
                </Col>
              </Row>
            </Tab>
            <Tab title={i18n._(t`Pending Withdrawals`)}>
              {currentBrand && (
              <Row>
                <Col>
                  <Space size={10} />
                  <PendingWithdrawalsTable
                    resource={withdrawalResources.pendingWithdrawals(currentBrand.id)}
                    openSidepanel={onApprovalsPanelOpen}
                    brand={currentBrand.id}
                    currency={currency}
                  />
                </Col>
              </Row>
              )}
            </Tab>
          </Tabs>
        )
      }

      {
        isFiatCurrencyType(currency) && isWithdrawableCurrency(currency) && !includes(['admin'], user.role) && (
          <Row>
            <Col>
              <Space size={10} />
              { Table && (
                <Table
                  striped
                  columnDefs={cols(onSidepanelOpen)}
                  fields={fields}
                  query={{
                    ordering: '-created_at',
                  }}
                  filterable
                />
              ) }
            </Col>
          </Row>
        )
      }

      {
        paymentId && paymentType && (
          <AddressPaymentsSidepanel
            pid={paymentId}
            type={paymentType}
            brand={currentBrand.id}
            open={sidepanelOpen}
            onSidepanelClose={onSidepanelClose}
          />
        )
      }

      {
        withdrawalId && (
          <PendingWithdrawalsSidepanel
            open={approvalsPanelOpen}
            onSidepanelClose={onApprovalsPanelClose}
            brand={currentBrand.id}
            withdrawal={withdrawalId}
          />
        )
      }

      {wallet
        && (
          <>
            <ExchangeModal visible={exchangeOpen} onClose={closeExchangeModal} />
            <WithdrawModal visible={modalOpen} onClose={closeModal} />
          </>
        )
      }
    </Page>
  );
};

Wallet.propTypes = propTypes;
Wallet.defaultProps = defaultProps;

export default Wallet;
