import React from 'react';
import { string, func } from 'prop-types';

import Tooltip from 'tc-biq-design-system/build/Tooltip';
import Icon from 'tc-biq-design-system/build/Icon';

const propTypes = {
  iconName: string.isRequired,
  label: string,
  onClick: func.isRequired,
};

const defaultProps = {
  label: null,
};

const TableAction = ({ iconName, label, onClick }) => {
  const icon = <Icon name={iconName} onClick={onClick} colorName="text-neutral-900" />;
  return (
    <div className="table-grid__action">
      {label && (
        <Tooltip title={label}>{icon}</Tooltip>
      )}
      {!label && icon}
    </div>
  );
};

TableAction.propTypes = propTypes;
TableAction.defaultProps = defaultProps;

export default TableAction;
