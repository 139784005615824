import React from 'react';
import { put, takeEvery, call } from 'redux-saga/effects';
import { notifier } from 'tc-biq-design-system/build/Notification';
import { Trans } from '@lingui/macro';
import { push } from 'connected-react-router';
import {
  GET_BRAND_REQUEST,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAILURE,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAILURE,
  CREATE_BRAND_REQUEST,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_FAILURE,
} from '../models/brandItem';
import BrandService from '../../services/brand';

export function* getBrandItemSaga({ id }) {
  try {
    const { data } = yield call(BrandService.getItem, id);
    yield put({
      type: GET_BRAND_SUCCESS,
      brand: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_BRAND_FAILURE,
      payload: data,
    });
  }
}

export function* updateBrandItemSaga({ id, valueData }) {
  try {
    const { data } = yield call(BrandService.updateItem, [id, valueData]);
    yield call(notifier.success, <Trans>Success update</Trans>);
    yield put({
      type: UPDATE_BRAND_SUCCESS,
      brand: data,
    });
    yield put(push('/system/brands'));
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: UPDATE_BRAND_FAILURE,
      payload: data,
    });
  }
}

export function* createBrandItemSaga({ valueData }) {
  try {
    const { data } = yield call(BrandService.createItem, valueData);
    yield call(notifier.success, <Trans>Brand created successfully</Trans>);
    yield put({
      type: CREATE_BRAND_SUCCESS,
      brand: data,
    });
    yield put(push('/system/brands'));
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: CREATE_BRAND_FAILURE,
      payload: data,
    });
  }
}

export const brandItemSaga = [
  takeEvery(GET_BRAND_REQUEST, getBrandItemSaga),
  takeEvery(UPDATE_BRAND_REQUEST, updateBrandItemSaga),
  takeEvery(CREATE_BRAND_REQUEST, createBrandItemSaga),
];
