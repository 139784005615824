import http from 'App/http';

export const resources = {
  withdraw: bid => `brand/${bid}/bank-withdrawal/`,
  pendingWithdrawals: bid => `brand/${bid}/bank-withdrawal/pending_authorization/`,
  authorizedBy: (bid, wid) => `brand/${bid}/bank-withdrawal/${wid}/authorized_by/`,
  authorize: (bid, wid) => `brand/${bid}/bank-withdrawal/${wid}/authorize/`,
  unauthorize: (bid, wid) => `brand/${bid}/bank-withdrawal/${wid}/decline/`,
};

const createItem = (brand, payload) => http.post(resources.withdraw(brand), payload);

const getAuthorizedBy = (brand, withdrawal) => http.get(resources.authorizedBy(brand, withdrawal));

const authorize = (brand, withdrawal) => http.post(resources.authorize(brand, withdrawal), {});

const unauthorize = (brand, withdrawal) => http.post(resources.unauthorize(brand, withdrawal), {});

export default {
  createItem,
  getAuthorizedBy,
  authorize,
  unauthorize,
};
