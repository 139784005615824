import React from 'react';
import { object } from 'prop-types';
import FaIcon from '../../FaIcon';

import './DisplayTypeCell.scss';

const displayTypeIcons = {
  Deposit: {
    name: 'Deposit',
    color: 'text-status01-500',
    bgColor: 'bgc-status01-100',
  },
  Withdrawal: {
    name: 'Withdrawal',
    color: 'text-status04-500',
    bgColor: 'bgc-status04-100',
  },
};

const depositTypes = ['Deposit', 'Buy order', 'Release custody'];
const withdrawalTypes = ['Withdrawal', 'Sell order', 'Fee', 'Acquire custody'];

const propTypes = {
  data: object.isRequired,
};

const DisplayTypeCell = ({ data: { displayType, amount } }) => {
  let displayIcon = {};

  if (displayType === 'Price guarantee correction') {
    displayIcon = amount > 0 ? displayTypeIcons['Deposit'] : displayTypeIcons['Withdrawal'];
  } else if (depositTypes.includes(displayType)) {
    displayIcon = displayTypeIcons['Deposit'];
  } else if (withdrawalTypes.includes(displayType)) {
    displayIcon = displayTypeIcons['Withdrawal'];
  }

  return (
    <>
      { displayIcon && <span className="display-type-cell__icon"><FaIcon round size="small" {...displayIcon} /></span> }
      { displayType }
    </>
  );
};

DisplayTypeCell.propTypes = propTypes;

export default DisplayTypeCell;
