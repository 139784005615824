import React from 'react';
import { string, element, node, func } from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from 'tc-biq-design-system/build/Icon';
import FaIcon from 'App/components/FaIcon';
import './Header.scss';

const propTypes = {
  title: string,
  description: string,
  actions: element,
  icon: node,
  backLink: string,
  children: node,
};

const defaultProps = {
  title: null,
  description: null,
  actions: null,
  icon: null,
  backLink: null,
  children: null,
};

const Separator = () => <div className="header__separator" />;
const Action = ({ title, icon, onAction }) => (
  <div role="button" tabIndex={0} className="header__action" onClick={onAction}>
    <FaIcon
      faIcon
      name={icon}
      size="small"
      color="text-primary-600"
    />
    {title}
  </div>
);

Action.propTypes = {
  title: string.isRequired,
  icon: string.isRequired,
  onAction: func.isRequired,
};

const Header = ({ title, description, icon, backLink, actions, children }) => (
  <div className="header">
    <div className="header__info">
      {
        backLink && (
          <Link className="header__back-link" to={backLink}>
            <Icon colorName="text-brand-1000" name="ArrowLeft" />
          </Link>
        )
      }
      {
        icon && <div className="header__icon">{icon}</div>
      }
      <div className="header__title">
        {title}
        {description && (
          <div className="header__title__description">
            {description}
          </div>
        )}
      </div>
    </div>
    <div className="header__content">
      {children}
    </div>
    <div className="header__actions">
      {actions}
    </div>
  </div>
);

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
Header.Separator = Separator;
Header.Action = Action;

export default Header;
