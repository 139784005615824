import http from 'App/http';

export const resources = {
  login: () => 'login/',
  logout: () => 'logout/',
  auth: () => 'auth',
  passwordReset: () => 'reset-password/start/',
  setNewPassword: () => 'reset-password/',
  acceptInvite: token => `user/${token}/accept_invite/`,
};

const login = credentials => http.post(resources.login(), credentials);

const logout = () => http.delete(resources.logout());

const getToken = () => localStorage.setItem('auth_token');

const deleteToken = () => localStorage.removeItem('auth_token');

const saveToken = token => localStorage.setItem('auth_token', token);

const confirmAuth = ({ code, email, password }) => http.post(
  resources.login(),
  { email, password },
  {
    headers: {
      'X-OrbPay-MFA-Token': code,
    },
  },
);

const requestPasswordReset = ({ email }) => http.post(resources.passwordReset(), { email });

const setNewPassword = ({ token, password }) => http.post(resources.setNewPassword(), {
  token,
  password,
});

const acceptInvite = ({ token, password }) =>
  // Without the line break, the line exceeds 100 chars.
  // eslint-disable-next-line implicit-arrow-linebreak
  http.post(resources.acceptInvite(token), { password });

export default {
  login,
  logout,
  getToken,
  deleteToken,
  saveToken,
  confirmAuth,
  requestPasswordReset,
  setNewPassword,
  acceptInvite,
};
