import React, { useCallback, useMemo } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { object, bool } from 'prop-types';
import { Trans, t } from '@lingui/macro';
import Space from 'tc-biq-design-system/build/Space';
import Form from 'App/components/Form';
import { InputText } from 'App/components/Form/Field';
import LandingPageLayout from 'Auth/layouts/LandingPageLayout';
import { minLength, isSameValue } from 'App/components/Form/validators';
import { RESET_PASSWORD, LOGIN } from 'App/constants/routes';

const propTypes = {
  i18n: object.isRequired,
  location: object.isRequired,
  actions: object.isRequired,
  passwordChanged: bool.isRequired,
  loading: bool.isRequired,
};

const NewPassword = ({
  actions,
  i18n,
  passwordChanged,
  loading,
  location: { search },
}) => {
  const token = useMemo(() => new URLSearchParams(search).get('token'), [
    search,
  ]);
  const onSubmit = useCallback(
    ({ password }) => actions.acceptInvite({
      token,
      password,
    }),
    [token],
  );
  const validators = {
    password: [minLength({ i18n })],
    password_confirm: [
      isSameValue({
        i18n,
        field: 'password',
        message: i18n._(t`The passwords must match.`),
      }),
    ],
  };
  return (
    <>
      {!token && <Redirect to={RESET_PASSWORD} />}
      <LandingPageLayout>
        <div className="form">
          <Form onSubmit={onSubmit} loading={loading} validators={validators}>
            {({ submitHandler, loader }) => (
              <form onSubmit={submitHandler}>
                {loader}
                <p className="title48 proxima">
                  <Trans>Set a password</Trans>
                </p>
                {passwordChanged ? (
                  <>
                    <p className="tc-paragraph-regular">
                      <Trans>
                        Success! You can now log in with your new password.
                      </Trans>
                    </p>
                    <Space size={40} />
                    <Link to={LOGIN} className="biq-link tc-paragraph-regular">
                      <Trans>Back to login page</Trans>
                    </Link>
                  </>
                ) : (
                  <>
                    <InputText
                      name="password"
                      type="password"
                      label={i18n._(t`Password`)}
                      placeholder={i18n._(t`Enter password`)}
                    />
                    <Space size={40} />
                    <InputText
                      name="password_confirm"
                      type="password"
                      label={i18n._(t`Repeat password`)}
                      placeholder={i18n._(t`Enter password again`)}
                    />
                    <Space size={40} />
                    <button
                      className="biq-button primary regular"
                      type="submit"
                      style={{ width: '100%', margin: '0px' }}
                    >
                      <Trans>Set password</Trans>
                    </button>
                  </>
                )}
              </form>
            )}
          </Form>
        </div>
      </LandingPageLayout>
    </>
  );
};

NewPassword.propTypes = propTypes;

export default NewPassword;
