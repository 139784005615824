import { bindActionCreators, compose } from 'redux';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { actions as bankAccountActions } from 'BankAccount/redux/models/bankAccount';
import { actions as walletActions } from 'Wallet/redux/models/wallet';
import { actions as withdrawActions } from 'Wallet/redux/models/withdraw';
import WithdrawModal from './WithdrawModal';

const mapStateToProps = (
  {
    brand: { currentBrand },
    bankAccount: { bankAccountList },
    wallet: { wallets, wallet },
    withdraw: { withdraw, errors, loading },
  },
) => ({
  bankAccountList,
  errors,
  loading,
  currentBrand,
  wallets,
  wallet,
  withdraw,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...bankAccountActions,
      ...walletActions,
      ...withdrawActions,
    },
    dispatch,
  ),
});

export default compose(
  withI18n(),
  connect(mapStateToProps, mapDispatchToProps),
)(WithdrawModal);
