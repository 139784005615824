import React from 'react';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { notifier } from 'tc-biq-design-system/build/Notification';
import { Trans } from '@lingui/macro';
import { tableActionsGenerator } from 'App/components/Table/generators/model';
import { actions as authActions } from 'Auth/redux/models/auth';

import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
} from '../models/user';
import UserService from '../../services/user';

const userTableKey = 'USERS_TABLE';
const actions = tableActionsGenerator(userTableKey);

export function* getUserSaga({ id }) {
  try {
    const { data } = yield call(UserService.getItem, id);
    yield put({
      type: GET_USER_SUCCESS,
      user: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_USER_FAILURE,
      payload: data,
    });
  }
}

export function* updateUserSaga({
  id,
  valueData,
  refreshTable,
  updateCurrentUser,
}) {
  try {
    const { data } = yield call(UserService.updateItem, [id, valueData]);
    yield call(
      notifier.success,
      <Trans>User has been successfully updated</Trans>,
    );
    if (updateCurrentUser) yield put(authActions.setCurrentUser(data));
    yield put({
      type: UPDATE_USER_SUCCESS,
      user: data,
    });
    if (refreshTable) yield put(actions.fetchTableData());
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: UPDATE_USER_FAILURE,
      payload: data,
    });
  }
}

export function* inviteUserSaga({ valueData }) {
  try {
    const { data } = yield call(UserService.invite, valueData);
    yield call(
      notifier.success,
      <Trans>User has been successfully invited</Trans>,
    );
    yield put({
      type: INVITE_USER_SUCCESS,
      user: data,
    });
    const query = yield select(({ tables }) => tables[userTableKey].query);
    yield put(actions.fetchTableData(query));
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: INVITE_USER_FAILURE,
      payload: data,
    });
  }
}

export function* changePasswordSaga({ id, valueData }) {
  try {
    const { data } = yield call(UserService.changePassword, [id, valueData]);
    yield call(
      notifier.success,
      <Trans>Password has been successfully updated</Trans>,
    );
    yield put({
      type: CHANGE_PASSWORD_SUCCESS,
      user: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: CHANGE_PASSWORD_FAILURE,
      payload: data,
    });
  }
}

export const userSaga = [
  takeEvery(GET_USER_REQUEST, getUserSaga),
  takeEvery(UPDATE_USER_REQUEST, updateUserSaga),
  takeEvery(INVITE_USER_REQUEST, inviteUserSaga),
  takeEvery(CHANGE_PASSWORD_REQUEST, changePasswordSaga),
];
