import { bindActionCreators, compose } from 'redux';
import { withI18n } from '@lingui/react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { actions as channelPaymentActions } from 'Wallet/redux/models/addressPayment';
import AddressPayments from './AddressPayments';

const mapStateToProps = (
  {
    brand: { currentBrand },
    addressPayment: { addressItem, errors, loading },
  },
) => ({
  currentBrand,
  addressItem,
  errors,
  loading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...channelPaymentActions,
    },
    dispatch,
  ),
});

export default compose(
  withI18n(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AddressPayments);
