import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_BANK_ACCOUNT_FAILURE,
  GET_BANK_ACCOUNT_REQUEST,
  GET_BANK_ACCOUNT_SUCCESS,
} from '../models/bankAccount';
import BankAccountService from '../../services/bankAccount';

export function* getBankAccountListSaga({ bid }) {
  try {
    const { data } = yield call(BankAccountService.getAll, bid);
    yield put({
      type: GET_BANK_ACCOUNT_SUCCESS,
      payload: data.results,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_BANK_ACCOUNT_FAILURE,
      payload: data,
    });
  }
}

export const bankAccountSaga = [
  takeLatest(GET_BANK_ACCOUNT_REQUEST, getBankAccountListSaga),
];
