import statuses from 'App/constants/statuses';

export const GET_API_KEY_REQUEST = 'GET_API_KEY_REQUEST';
export const GET_API_KEY_SUCCESS = 'GET_API_KEY_SUCCESS';
export const GET_API_KEY_FAILURE = 'GET_API_KEY_FAILURE';

export const CREATE_API_KEY_REQUEST = 'CREATE_API_KEY_REQUEST';
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';
export const CREATE_API_KEY_FAILURE = 'CREATE_API_KEY_FAILURE';

export const UPDATE_API_KEY_REQUEST = 'UPDATE_API_KEY_REQUEST';
export const UPDATE_API_KEY_SUCCESS = 'UPDATE_API_KEY_SUCCESS';
export const UPDATE_API_KEY_FAILURE = 'UPDATE_API_KEY_FAILURE';

export const CLEAR_API_KEY = 'CLEAR_API_KEY';

const initialState = {
  apiKey: null,
  loading: false,
  errors: null,
  status: statuses.DEFAULT,
};

const getApiKeyItem = ({ bid, kid }) => ({
  type: GET_API_KEY_REQUEST,
  bid,
  kid,
});

const createApiKeyItem = ({ bid, valueData }) => ({
  type: CREATE_API_KEY_REQUEST,
  bid,
  valueData,
});

const updateApiKeyItem = ({ bid, kid, valueData }) => ({
  type: UPDATE_API_KEY_REQUEST,
  bid,
  kid,
  valueData,
});

const clearApiKey = () => ({
  type: CLEAR_API_KEY,
});

export const actions = {
  getApiKeyItem,
  createApiKeyItem,
  updateApiKeyItem,
  clearApiKey,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_API_KEY_REQUEST:
    case CREATE_API_KEY_REQUEST:
    case UPDATE_API_KEY_REQUEST:
      return {
        ...state,
        loading: true,
        status: statuses.LOADING,
      };
    case GET_API_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        apiKey: action.apiKey,
        status: statuses.DEFAULT,
      };
    case CREATE_API_KEY_SUCCESS:
    case UPDATE_API_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        apiKey: action.apiKey,
        status: statuses.SUCCESS,
      };
    case GET_API_KEY_FAILURE:
    case CREATE_API_KEY_FAILURE:
    case UPDATE_API_KEY_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        status: statuses.ERROR,
      };
    case CLEAR_API_KEY:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
