import React, { useState, useEffect } from 'react';
import { object } from 'prop-types';
import Tooltip from 'tc-biq-design-system/build/Tooltip';
import BrandService from 'Brand/services/brand';
import BrandsTooltip from './BrandsTooltip';
import './BrandsCell.scss';

const propTypes = {
  data: object.isRequired,
};

const BrandsCell = ({ data }) => {
  const [brands, setBrands] = useState([]);
  const brandIds = data.available_brands;
  const remainingBrands = brands.filter((b, index) => index !== 0);

  const getBrands = async () => {
    const res = await Promise.all(brandIds.map(item => BrandService.getItem(item)));
    setBrands(res.map(item => item.data.name));
  };

  useEffect(() => {
    if (brandIds.length > 0) {
      getBrands();
    }
  }, []);

  return (
    <div className="brand-cell">
      {brands && brands.length > 1 && (
        <>
          <div>{brands[0]}</div>
          <Tooltip
            content={<BrandsTooltip brands={remainingBrands} />}
            tooltipOptions={{ interactive: true }}
          >
            <div
              className="brand-cell__more"
              role="button"
              tabIndex={0}
            >
              +
              {brands.length - 1}
              {' '}
              more
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
};

BrandsCell.propTypes = propTypes;

export default BrandsCell;
