import http from 'App/http';

export const resources = {
  invoice: (brandID, invoiceID) => `brand/${brandID}/invoice/${invoiceID}/`,
  exchangeOverage: (brandID, invoiceID) => `brand/${brandID}/invoice/${invoiceID}/exchange_overage/`,
  refundOverage: (brandID, invoiceID) => `brand/${brandID}/invoice/${invoiceID}/refund_overage/`,
  recalculate: (brandID, invoiceID) => `brand/${brandID}/invoice/${invoiceID}/recalculate/`,
  recalculateOverage: (brandID, invoiceID) => `brand/${brandID}/invoice/${invoiceID}/recalculate/?for_overage=true`,
  exchange: (brandID, invoiceID) => `brand/${brandID}/invoice/${invoiceID}/exchange/`,
  refund: (brandID, invoiceID) => `brand/${brandID}/invoice/${invoiceID}/refund/`,
  markAsComplete: (brandID, invoiceID) => `brand/${brandID}/invoice/${invoiceID}/mark_as_complete/`,
  keepAsCrypto: (brandID, invoiceID) => `brand/${brandID}/invoice/${invoiceID}/keep_as_crypto/`,
  keepOverageAsCrypto: (brandID, invoiceID) => `brand/${brandID}/invoice/${invoiceID}/keep_as_crypto/?for_overage=true`,
};

const getInvoice = (brandID, invoiceID) => http.get(resources.invoice(brandID, invoiceID));

const refundOverage = (brandID, invoiceID, data) => http.post(
  resources.refundOverage(brandID, invoiceID),
  { ...data },
);

const recalculate = (brandID, invoiceID) => http.post(resources.recalculate(brandID, invoiceID), {});

const recalculateOverage = (brandID, invoiceID) => http.post(resources.recalculateOverage(brandID, invoiceID), {});

const exchange = (brandID, invoiceID) => http.post(resources.exchange(brandID, invoiceID), {});

const exchangeOverage = (brandID, invoiceID) => http.post(resources.exchangeOverage(brandID, invoiceID), {});

const refund = (brandID, invoiceID, data) => http.post(resources.refund(brandID, invoiceID), { ...data });

const markAsComplete = (brandID, invoiceID) => http.post(resources.markAsComplete(brandID, invoiceID), {});

const keepAsCrypto = (brandID, invoiceID) => http.post(resources.keepAsCrypto(brandID, invoiceID), {});

const keepOverageAsCrypto = (brandID, invoiceID) => http.post(resources.keepOverageAsCrypto(brandID, invoiceID), {});

export default {
  getInvoice,
  refundOverage,
  recalculate,
  recalculateOverage,
  exchange,
  exchangeOverage,
  refund,
  markAsComplete,
  keepAsCrypto,
  keepOverageAsCrypto,
};
