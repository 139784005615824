import React, { useState, useCallback } from 'react';
import { Row, Col } from 'tc-biq-design-system/build/Grid';
import { object } from 'prop-types';
import Header from 'App/components/Header';
import FaIcon from 'App/components/FaIcon';
import { t } from '@lingui/macro';
import useTable from 'App/components/Table';
import { formatDate } from 'App/helpers/date';
import AddressPaymentsSidepanel from 'Wallet/components/AddressPaymentsSidepanel';
import Button from 'tc-biq-design-system/build/Button';
import { stringTruncate, capitalizeFirstLetter, downloadResponse } from 'App/helpers';
import { Pill } from 'tc-biq-design-system/build/Pill';
import http from 'App/http';
import { resources } from '../../services/transactionhistory';

const TABLE_ID = 'TRANSACTION_HISTORY_ADDRESS_PAYMENTS_TABLE';

const propTypes = {
  i18n: object.isRequired,
  currentBrand: object.isRequired,
};

const TransactionHistoryAddressPayments = ({ i18n, currentBrand }) => {
  const [Table] = useTable(TABLE_ID, resources.channelPayments(currentBrand.id));

  const [sidepanelOpen, setSidepanelOpen] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [paymentType, setPaymentType] = useState(null);

  const onSidepanelOpen = useCallback((pid, type) => {
    setPaymentType(type);
    setPaymentId(pid);
    setSidepanelOpen(true);
  });

  const onSidepanelClose = useCallback(() => setSidepanelOpen(false));

  const columnDefs = [
    {
      title: i18n._(t`Date`),
      key: 'created_at',
      render: data => formatDate(data.created_at),
    },
    {
      title: i18n._(t`Status`),
      key: 'status',
      render: data => <Pill type={['executed', 'filled'].includes(data.payment_status) ? 'status01' : 'status03'}>{capitalizeFirstLetter(data.payment_status)}</Pill>,
    },
    {
      title: i18n._(t`Compliance Review`),
      key: 'compliance_review',
      render: data => <Pill type={['approved', 'skipped'].includes(data.compliance_review) ? 'status01' : 'status03'}>{capitalizeFirstLetter(data.compliance_review)}</Pill>,
    },
    {
      title: i18n._(t`Deposited amount`),
      key: 'amount',
      render: data => `${data.deposited_amount} ${data.deposited_currency}`,
    },
    {
      title: i18n._(t`Address`),
      key: 'address',
      render: data => `${stringTruncate(data.address, 5, 5)}`,
    },
    {
      title: i18n._(t`Address name`),
      key: 'address_name',
    },
    {
      title: i18n._(t`Address description`),
      key: 'address_description',
    },
    {
      title: i18n._(t`Deposit fee`),
      key: 'deposit_fee',
      render: (data) => {
        if (data.deposit_fee_amount !== null) {
          return `${data.deposit_fee_amount} ${data.deposit_fee_currency}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Exchanged amount`),
      key: 'exchanged_amount',
      render: (data) => {
        if (data.exchanged_amount !== null && data.exchanged_currency !== 'XYZ') {
          return `${data.exchanged_amount} ${data.exchanged_currency}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Exchange rate`),
      key: 'exchange_rate',
      render: (data) => {
        if (data.exchange_rate !== null && data.exchange_symbol) {
          return `${data.exchange_rate} ${data.exchange_symbol}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Credited amount`),
      key: 'credited_amount',
      render: (data) => {
        if (data.credited_amount !== null && data.credited_currency !== 'XYZ') {
          return `${data.credited_amount} ${data.credited_currency}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Custom ID`),
      key: 'custom_id',
    },
    {
      title: i18n._(t`Reference`),
      key: 'id',
      render: data => (data.id ? (
        <Button
          onClick={() => onSidepanelOpen(data.id, 'channelpayment')}
          icon="View"
          color="transparent"
          title={data.id}
        >
          {`${stringTruncate(data.id, 4, 4)}`}
        </Button>
      ) : null),
    },
  ];

  const filterFields = [
    {
      type: 'date',
      input: 'date',
      key: 'created_at',
      id: 'created_at',
      label: 'Date',
    },
    {
      type: 'string',
      input: 'text',
      key: 'payment_status',
      id: 'status',
      label: 'Status',
    },
    {
      type: 'number',
      input: 'number',
      key: 'deposited',
      id: 'deposited',
      label: 'Deposited Amount',
    },
    {
      type: 'string',
      input: 'text',
      key: 'deposited_currency',
      id: 'deposited_currency',
      label: 'Deposited Currency',
    },
    {
      type: 'string',
      input: 'text',
      key: 'address__deposit_address',
      id: 'address__deposit_address',
      label: 'Address',
    },
    {
      type: 'string',
      input: 'text',
      key: 'address__name',
      id: 'address__name',
      label: 'Address Name',
    },
    {
      type: 'string',
      input: 'text',
      key: 'address__description',
      id: 'address__description',
      label: 'Address Description',
    },
    {
      type: 'number',
      input: 'number',
      key: 'deposit_fee',
      id: 'deposit_fee',
      label: 'Deposit Fee',
    },
    {
      type: 'string',
      input: 'text',
      key: 'deposit_fee_currency',
      id: 'deposit_fee_currency',
      label: 'Deposit Fee Currency',
    },
    {
      type: 'number',
      input: 'number',
      key: 'exchanged',
      id: 'exchanged',
      label: 'Exchanged Amount',
    },
    {
      type: 'string',
      input: 'text',
      key: 'exchanged_currency',
      id: 'exchanged_currency',
      label: 'Exchanged Currency',
    },
    {
      type: 'number',
      input: 'number',
      key: 'exchange_rate',
      id: 'exchange_rate',
      label: 'Exchange Rate',
    },
    {
      type: 'string',
      input: 'text',
      key: 'exchange_symbol',
      id: 'exchange_symbol',
      label: 'Exchange Symbol',
    },
    {
      type: 'number',
      input: 'number',
      key: 'credited',
      id: 'credited',
      label: 'Credited Amount',
    },
    {
      type: 'string',
      input: 'text',
      key: 'credited_currency',
      id: 'credited_currency',
      label: 'Credited Currency',
    },
    {
      type: 'string',
      input: 'text',
      key: 'address__custom_id',
      id: 'address__custom_id',
      label: 'Custom ID',
    },
  ];

  return (
    <div>
      <Header
        title={i18n._(t`Channel Payments`)}
        icon={<FaIcon round size="regular" name="Notes" color="text-neutral-50" bgColor="bgc-primary-500" />}
        actions={(
          <>
            <Header.Separator />
            <Header.Action
              title="Download as .CSV"
              icon="Download"
              onAction={() => {
                http.get(
                  resources.channelPayments(currentBrand.id),
                  { headers: { Accept: 'text/csv' } },
                ).then(response => downloadResponse(response));
              }}
            />
          </>
        )}
      />
      <Row>
        <Col>
          <Table
            columnDefs={columnDefs}
            filterable
            fields={filterFields}
            query={{
              ordering: '-created_at',
            }}
          />
        </Col>
      </Row>

      {paymentId && (
        <AddressPaymentsSidepanel
          pid={paymentId}
          type={paymentType}
          brand={currentBrand.id}
          open={sidepanelOpen}
          onSidepanelClose={onSidepanelClose}
        />
      )}
    </div>
  );
};

TransactionHistoryAddressPayments.propTypes = propTypes;

export default TransactionHistoryAddressPayments;
