import React from 'react';
import { Trans } from '@lingui/macro';
import { notifier } from 'tc-biq-design-system/build/Notification';
import { put, takeLatest, call } from 'redux-saga/effects';

import UserService from 'User/services/user';
import AuthService from '../../services/auth';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  INVALID_TOKEN,
  LOAD_CURRENT_USER_SUCCESS,
  LOAD_CURRENT_USER_FAILURE,
  LOAD_CURRENT_USER_REQUEST,
  SET_CONFIRM_AUTH,
  TFA_SUCCESS,
  TFA_FAILURE,
  TFA_REQUEST,
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
  ACCEPT_INVITE_REQUEST,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
} from '../models/auth';
import { CONFIRM_QRCODE_SUCCESS } from '../../../User/redux/models/qrCode';

export function* loginSaga({ email, password }) {
  try {
    const { data } = yield call(AuthService.login, { email, password });
    AuthService.saveToken(data.token);

    yield put({
      type: LOGIN_SUCCESS,
      payload: data.token,
    });

    yield call(loadCurrentUserSaga);
  } catch (error) {
    const { data, headers } = error.response;
    if (headers['www-authenticate'] === 'MFA') {
      yield put({
        type: SET_CONFIRM_AUTH,
        payload: { email, password },
      });
    } else {
      yield put({
        type: LOGIN_FAILURE,
        payload: data,
      });
    }
  }
}

export function* authConfirmSaga({ code, email, password }) {
  try {
    const { data } = yield call(AuthService.confirmAuth, {
      code,
      email,
      password,
    });
    AuthService.saveToken(data.token);

    yield put({
      type: TFA_SUCCESS,
      payload: data.token,
    });

    yield call(loadCurrentUserSaga);
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: TFA_FAILURE,
      payload: data,
    });
  }
}

export function* loadCurrentUserSaga() {
  try {
    const { data } = yield call(UserService.getCurrent);

    yield put({
      type: LOAD_CURRENT_USER_SUCCESS,
      payload: data,
    });

    if (data.mfa_enabled) {
      yield put({
        type: CONFIRM_QRCODE_SUCCESS,
        payload: true,
      });
    }
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: LOAD_CURRENT_USER_FAILURE,
      payload: data,
    });
  }
}

export function* setNewPasswordSaga({ token, password }) {
  try {
    // Keeping this in a saga in case we want to immediately log the user
    // in at some point.
    yield call(AuthService.setNewPassword, {
      token,
      password,
    });
    yield put({
      type: SET_NEW_PASSWORD_SUCCESS,
    });
  } catch (err) {
    const { data } = err.response;
    yield call(
      notifier.error,
      <Trans>
        Something went wrong while attempting to change your password. Please
        check the link in your email and try again.
      </Trans>,
    );
    yield put({
      type: SET_NEW_PASSWORD_FAILURE,
      payload: data,
    });
  }
}

export function* acceptInviteSaga({ token, password }) {
  try {
    // Keeping this in a saga in case we want to immediately log the user
    // in at some point.
    yield call(AuthService.acceptInvite, {
      token,
      password,
    });
    yield put({
      type: ACCEPT_INVITE_SUCCESS,
    });
  } catch (err) {
    const { data } = err.response;
    yield call(
      notifier.error,
      (data && data.reduce((acc, curr) => `${acc} ${curr}`, '')) || (
        <Trans>
          Something went wrong while attempting to set your password. Please
          check the link in your email and try again.
        </Trans>
      ),
    );
    yield put({
      type: ACCEPT_INVITE_FAILURE,
      payload: data,
    });
  }
}

export function* logoutSaga() {
  yield call(AuthService.logout);

  AuthService.deleteToken();
}

export function invalidTokenSaga() {
  AuthService.deleteToken();
}

export const authSaga = [
  takeLatest(LOGIN_REQUEST, loginSaga),
  takeLatest(LOGOUT_REQUEST, logoutSaga),
  takeLatest(INVALID_TOKEN, invalidTokenSaga),
  takeLatest(LOAD_CURRENT_USER_REQUEST, loadCurrentUserSaga),
  takeLatest(TFA_REQUEST, authConfirmSaga),
  takeLatest(SET_NEW_PASSWORD_REQUEST, setNewPasswordSaga),
  takeLatest(ACCEPT_INVITE_REQUEST, acceptInviteSaga),
];
