import { bindActionCreators, compose } from 'redux';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { actions as userActions } from 'User/redux/models/qrCode';
import QRCodeWrap from './QRCode';

const mapStateToProps = (
  {
    qrCode: { code, errors, loading, confirm, info },
  },
) => ({ code, errors, loading, confirm, info });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getQRCode: userActions.getQRCode,
    getQRCodeInfo: userActions.getQRCodeInfo,
    confirmQRCode: userActions.confirmQRCode,
    deleteQRCode: userActions.deleteQRCode,
  },
  dispatch),
});

export default compose(
  withI18n(),
  connect(mapStateToProps, mapDispatchToProps),
)(QRCodeWrap);
