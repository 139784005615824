import React from 'react';
import CheckboxField from 'tc-biq-design-system/build/Checkbox';
import { string } from 'prop-types';

import Field from '../Field';

const propTypes = {
  name: string.isRequired,
  label: string.isRequired,
};

const Checkbox = ({ name, label, ...rest }) => (
  <Field name={name}>
    {({ onChange, value, error }) => (
      <CheckboxField
        checked={value}
        helpText={error && error[0]}
        hasError={error && !!error[0]}
        onChange={e => onChange(e.target.checked)}
        {...rest}
      >
        {label}
      </CheckboxField>
    )}
  </Field>
);

Checkbox.propTypes = propTypes;

export default Checkbox;
