import { bindActionCreators, compose } from 'redux';
import { withI18n } from '@lingui/react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { actions as addressActions } from 'Wallet/redux/models/address';
import AddressSidePanel from './AddressSidePanel';

const mapStateToProps = ({ addressItem: { address, loading, error, status } }) => ({
  address, loading, error, status,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    createChannel: addressActions.createChannel,
    getChannel: addressActions.getChannel,
    clearChannel: addressActions.clearChannel,
    updateChannel: addressActions.updateChannel,
  }, dispatch),
});

export default compose(
  withI18n(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AddressSidePanel);
