import React from 'react';
import { Trans } from '@lingui/macro';
import Button from 'tc-biq-design-system/build/Button';
import { func, number, oneOfType, string } from 'prop-types';

const propTypes = {
  submitHandler: func.isRequired,
  resetHandler: func.isRequired,
  aid: oneOfType([string, number]),
};

const defaultProps = {
  aid: null,
};

const BankAccountFooter = ({ submitHandler, resetHandler, aid }) => (
  <div className="user-item-footer">
    <Button color="ghost" onClick={resetHandler}>
      {aid && (<Trans>Discard changes</Trans>)}
      {!aid && (<Trans>Discard</Trans>)}
    </Button>
    <Button onClick={submitHandler}>
      {aid && (<Trans>Save changes</Trans>)}
      {!aid && (<Trans>Add bank account</Trans>)}
    </Button>
  </div>
);

BankAccountFooter.propTypes = propTypes;
BankAccountFooter.defaultProps = defaultProps;

export default BankAccountFooter;
