import React, { useEffect, useState } from 'react';
import { object, bool } from 'prop-types';
import Detail from 'tc-biq-design-system/build/Detail';

import Header from 'App/components/Header/Header';
import FaIcon from 'App/components/FaIcon';
import Page from 'App/components/Page/Page';
// import DetailAction from 'App/components/DetailAction';
import { stringTruncate } from 'App/helpers';
import AddressPaymentsTable from './AddressPaymentsTable';
import AddressSidepanel from './AddressSidepanel';
import { resources as addressPaymentResources } from '../../services/addressPayment';
import AddressPaymentsSidepanel
  from '../../components/AddressPaymentsSidepanel';

import './AddressPayments.scss';


const propTypes = {
  actions: object.isRequired,
  currentBrand: object,
  addressItem: object,
  match: object.isRequired,
  loading: bool.isRequired,
};

const defaultProps = {
  addressItem: null,
  currentBrand: null,
};

const AddressPayments = (
  {
    actions,
    currentBrand,
    addressItem,
    match,
    loading,
  },
) => {
  useEffect(() => {
    if (currentBrand) {
      const urlData = {
        brandId: currentBrand.id,
        walletId: match.params.wid,
        channelId: match.params.aid,
      };
      actions.getChannelPayment(urlData);
    }
  }, [currentBrand]);

  const [sidepanelConfig, setSidepanelConfig] = useState(null);
  const [paymentDetailOpen, setPaymentDetailOpen] = useState(false);
  const [paymentId, setPaymentId] = useState(null);

  const onSidepanelClose = () => {
    setSidepanelConfig(null);
  };

  const showPaymentDetail = (id) => {
    setPaymentId(id);
    setPaymentDetailOpen(true);
  };

  const hidePaymentDetail = () => {
    setPaymentId(null);
    setPaymentDetailOpen(false);
  };

  return (
    <Page
      loading={loading}
    >
      {
        addressItem && (
          <>
            <Header
              title={addressItem.name}
              description={addressItem.id.toString()}
              backLink={`/wallets/${match.params.wid}`}
              icon={(
                <FaIcon
                  round
                  faIcon
                  name="Link"
                  color="text-neutral-50"
                  bgColor="bgc-primary-600"
                />
              )}
            >

              <Header.Separator />
              <div className="address-payment-page__header">
                <Detail
                  reverse
                  entity="Paid"
                  value={addressItem.deposited.length ? `${addressItem.deposited[0].amount} ${addressItem.deposited[0].currency}` : 0}
                />

                <Header.Separator />
                <Detail
                  reverse
                  entity="Received"
                  value={addressItem.credited.length ? `${addressItem.credited[0].amount} ${addressItem.credited[0].currency}` : 0}
                />

                <Header.Separator />
                <Detail
                  reverse
                  entity="Exchange percentage"
                  value={`${addressItem.exchange_percentage}%`}
                />
              </div>
            </Header>

            <div className="address-payment-page__content">
              <div className="address-payment-page__content__item">
                <h4>Description</h4>
                <p>{addressItem.description}</p>
              </div>
              <div className="address-payment-page__content__item">
                <h4>Url</h4>
                <a href={addressItem.payments_url} target="_blank" rel="noopener noreferrer">{stringTruncate(addressItem.payments_url)}</a>
              </div>
              <div className="address-payment-page__content__item">
                <h4>Address</h4>
                <p>{addressItem.deposit_address}</p>
              </div>
            </div>

            <div className="address-payment-page__table">
              <AddressPaymentsTable
                resource={addressPaymentResources.channelPaymentItem(
                  {
                    brandId: currentBrand.id,
                    walletId: match.params.wid,
                    channelId: match.params.aid,
                  },
                )}
                showPayment={showPaymentDetail}
              />
            </div>

            <AddressSidepanel
              open={!!sidepanelConfig}
              onSidepanelClose={onSidepanelClose}
              {...sidepanelConfig}
            />

            <AddressPaymentsSidepanel
              type="channelpayment"
              open={paymentDetailOpen}
              onSidepanelClose={hidePaymentDetail}
              pid={paymentId}
              brand={currentBrand.id}
            />
          </>
        )
      }
    </Page>
  );
};

AddressPayments.propTypes = propTypes;
AddressPayments.defaultProps = defaultProps;

export default AddressPayments;
