import { bindActionCreators } from 'redux';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';

import { actions as walletActions } from 'Wallet/redux/models/wallet';
import Wallet from './Wallet';

const mapStateToProps = ({ wallet, brand: { currentBrand }, auth: { user } }) => ({
  ...wallet,
  currentBrand,
  user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...walletActions,
    },
    dispatch,
  ),
});

export default withI18n()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Wallet),
);
