import { useState, useEffect } from 'react';

export default (service, onSuccess = () => {}) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const [serviceArguments, setServiceArguments] = useState(null);
  const run = (...serviceArgs) => setServiceArguments(serviceArgs);
  const clear = () => {
    setLoading(false);
    setResponse(null);
    setError(null);
  };

  useEffect(() => {
    if (serviceArguments) {
      setLoading(true);
      setError(null);
      service(...serviceArguments)
        .then(({ data }) => {
          setResponse(data);
          onSuccess(data);
        })
        .catch((err) => {
          setResponse(null);
          const res = err && err.response;
          if (res) setError(res);
        })
        .finally(() => {
          setLoading(false);
          setServiceArguments(null);
        });
    }
  }, [serviceArguments]);

  useEffect(() => clear, []);

  return {
    loading,
    response,
    error,
    run,
    clear,
  };
};
