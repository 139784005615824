import React from 'react';
import { object } from 'prop-types';
import RBACRoute from 'App/components/RBACRoute';

const propTypes = {
  currentBrand: object,
};

const defaultProps = {
  currentBrand: null,
};

const BrandDependentRoute = ({ currentBrand, ...props }) => (
  currentBrand
    ? <RBACRoute {...props} />
    : null
);

BrandDependentRoute.propTypes = propTypes;
BrandDependentRoute.defaultProps = defaultProps;

export default BrandDependentRoute;
