import React from 'react';
import { put, takeEvery, call } from 'redux-saga/effects';
import { Trans } from '@lingui/macro';
import { notifier } from 'tc-biq-design-system/build/Notification';
import exchangeService from '../../services/exchange';
import {
  CREATE_EXCHANGE_FAILURE,
  CREATE_EXCHANGE_REQUEST,
  CREATE_EXCHANGE_SUCCESS,
} from '../models/exchange';

export function* createExchangeSaga({ brandId, valueData }) {
  try {
    const { data } = yield call(exchangeService.createItem, [brandId, valueData]);
    yield call(notifier.success, <Trans>Exchange created successfully</Trans>);
    yield put({
      type: CREATE_EXCHANGE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      typ: CREATE_EXCHANGE_FAILURE,
      payload: data,
    });
  }
}

export const exchangeSaga = [
  takeEvery(CREATE_EXCHANGE_REQUEST, createExchangeSaga),
];
