import React from 'react';

export const Wallet = () => (
  <path d="M 22.570824,6.4654709 H 4.7020737 c -0.41437,0 -0.75,-0.38357 -0.75,-0.85715 0,-0.47357 0.33563,-0.85714 0.75,-0.85714 H 22.702074 c 0.41438,0 0.75,-0.38357 0.75,-0.85714 0,-1.42018 -1.00734,-2.57143 -2.25,-2.57143 H 3.9520737 c -1.65703,0 -2.99999998,1.53482 -2.99999998,3.42857 V 21.894041 c 0,1.89375 1.34296998,3.42857 2.99999998,3.42857 H 22.570824 c 1.31344,0 2.38125,-1.15339 2.38125,-2.57143 V 9.0369009 c 0,-1.41804 -1.06781,-2.57143 -2.38125,-2.57143 z m -2.11875,11.1428501 c -0.82828,0 -1.5,-0.76767 -1.5,-1.71428 0,-0.94661 0.67172,-1.71429 1.5,-1.71429 0.82828,0 1.5,0.76768 1.5,1.71429 0,0.94661 -0.67172,1.71428 -1.5,1.71428 z" />
);

export const USD = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512"><path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z" /></svg>
);

export const BTC = () => (
  <g
    transform="translate(-289.60744,-357.50571)"
  >
    <path
      id="path4412"
      d="m 310.62591,367.15418 c 0.37727,-2.52183 -1.54283,-3.87751 -4.1683,-4.78188 l 0.85167,-3.41613 -2.07941,-0.51823 -0.82916,3.32611 c -0.54666,-0.13622 -1.10811,-0.26474 -1.66602,-0.39207 l 0.83508,-3.34803 -2.07823,-0.51822 -0.85225,3.41495 c -0.45249,-0.10305 -0.89668,-0.20492 -1.32784,-0.31212 l 0.002,-0.0107 -2.86771,-0.71604 -0.55316,2.22096 c 0,0 1.54282,0.35358 1.51025,0.37549 0.84219,0.21025 0.9944,0.76757 0.96893,1.20939 l -0.97012,3.89172 c 0.0581,0.0148 0.13326,0.0361 0.21618,0.0693 -0.0693,-0.0172 -0.14333,-0.0361 -0.21973,-0.0545 l -1.35982,5.45172 c -0.10305,0.25586 -0.36424,0.63964 -0.95294,0.49394 0.0207,0.0302 -1.51144,-0.37726 -1.51144,-0.37726 l -1.0323,2.38027 2.70602,0.67458 c 0.50342,0.12615 0.99677,0.25823 1.48242,0.3826 l -0.86055,3.45522 2.07704,0.51823 0.85226,-3.4185 c 0.56738,0.15398 1.11818,0.29612 1.65713,0.42997 l -0.8493,3.40252 2.07942,0.51822 0.86054,-3.44871 c 3.54584,0.67103 6.21218,0.40037 7.33451,-2.8067 0.90437,-2.58224 -0.045,-4.07176 -1.91062,-5.04306 1.35863,-0.31331 2.38205,-1.20702 2.65508,-3.05308 z m -4.75108,6.66229 c -0.6426,2.58223 -4.99035,1.18629 -6.39992,0.83626 l 1.14187,-4.57755 c 1.40957,0.3518 5.92967,1.0483 5.25805,3.74129 z m 0.64319,-6.69961 c -0.58633,2.34889 -4.20502,1.1555 -5.37887,0.86292 l 1.03527,-4.15171 c 1.17385,0.29257 4.95422,0.83863 4.3436,3.28879 z"
    />
  </g>
);

export const EUR = () => (
  <path d="m 19.070084,19.395234 c -0.06159,-0.31078 -0.367266,-0.509625 -0.676124,-0.439171 -0.501189,0.114326 -1.285408,0.253735 -2.129346,0.253735 -2.660765,0 -4.777922,-1.630783 -5.693483,-4.005423 h 5.329172 a 0.5625,0.5625 0 0 0 0.548812,-0.439173 l 0.298734,-1.329374 C 16.826872,13.084172 16.559459,12.75 16.199038,12.75 H 9.906678 C 9.84996,12.078375 9.840397,11.424048 9.913098,10.769765 h 6.871546 a 0.5625,0.5625 0 0 0 0.549515,-0.442218 l 0.305254,-1.3947659 c 0.07678,-0.350812 -0.190359,-0.6827809 -0.549516,-0.6827809 h -6.481782 c 0.967171,-2.1089529 2.938594,-3.5170319 5.513391,-3.5170319 0.67903,0 1.338937,0.1054691 1.774266,0.1942973 0.291374,0.059439 0.578764,-0.1170933 0.656343,-0.4042027 L 19.114193,2.443312 C 19.199602,2.1273277 18.998645,1.8050626 18.677038,1.7440777 18.109661,1.6365 17.195271,1.5 16.207381,1.5 c -4.5555,0 -8.2314843,2.8243127 -9.58875,6.7500002 H 5.0682405 c -0.3106406,0 -0.5625,0.2518592 -0.5625,0.5624999 v 1.3947659 c 0,0.310641 0.2518594,0.562499 0.5625,0.562499 H 6.0792874 C 6.0319904,11.407594 6.0239279,12.142594 6.0708011,12.75 H 5.0682405 c -0.3106406,0 -0.5625,0.251859 -0.5625,0.5625 v 1.329376 c 0,0.31064 0.2518594,0.562499 0.5625,0.562499 H 6.4798343 C 7.652881,19.438688 11.31499,22.5 16.207381,22.5 c 1.232859,0 2.276343,-0.213 2.864109,-0.365062 0.289078,-0.07477 0.470015,-0.361313 0.411938,-0.654235 z" />
);

export const Link = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z" /></svg>
);

export const Dots = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 256c0 26.5-21.5 48-48 48s-48-21.5-48-48 21.5-48 48-48 48 21.5 48 48zm120-48c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48zm-336 0c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z" /></svg>
);

export const Exchange = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M508.485 168.485l-100.375 100c-4.686 4.686-12.284 4.686-16.97 0l-19.626-19.626c-4.753-4.753-4.675-12.484.173-17.14L422.916 184H12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h410.916l-51.228-47.719c-4.849-4.656-4.927-12.387-.173-17.14l19.626-19.626c4.686-4.686 12.284-4.686 16.97 0l100.375 100c4.685 4.686 4.685 12.284-.001 16.97zm-504.97 192l100.375 100c4.686 4.686 12.284 4.686 16.97 0l19.626-19.626c4.753-4.753 4.675-12.484-.173-17.14L89.084 376H500c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12H89.084l51.228-47.719c4.849-4.656 4.927-12.387.173-17.14l-19.626-19.626c-4.686-4.686-12.284-4.686-16.97 0l-100.375 100c-4.686 4.686-4.686 12.284.001 16.97z" /></svg>
);

export const University = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M472 440h-8v-56c0-13.255-10.745-24-24-24h-16V208h-48v152h-48V208h-48v152h-48V208h-48v152h-48V208H88v152H72c-13.255 0-24 10.745-24 24v56h-8c-13.255 0-24 10.745-24 24v16a8 8 0 0 0 8 8h464a8 8 0 0 0 8-8v-16c0-13.255-10.745-24-24-24zm-56 0H96v-32h320v32zm72.267-322.942L271.179 26.463a48.004 48.004 0 0 0-30.358 0L23.733 117.058A11.999 11.999 0 0 0 16 128.274V156c0 6.627 5.373 12 12 12h20v12c0 6.627 5.373 12 12 12h392c6.627 0 12-5.373 12-12v-12h20c6.627 0 12-5.373 12-12v-27.726c0-4.982-3.077-9.445-7.733-11.216zM64 144l192-72 192 72H64z" /></svg>
);

export const Lock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z" /></svg>
);

export const Download = () => (
  <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-158.000000, -599.000000)">
      <path d="M175.183077,610.695538 L171.029231,615.310923 L171.029231,615.310462 C170.775846,615.592 170.408923,615.769231 170,615.769231 C169.591538,615.769231 169.224615,615.592 168.971231,615.310462 L168.970769,615.310923 L164.816923,610.695538 L164.817385,610.695077 C164.596308,610.45 164.461538,610.125538 164.461538,609.769231 C164.461538,609.004462 165.081846,608.384615 165.846154,608.384615 C166.255077,608.384615 166.622,608.561385 166.875385,608.842923 L168.615385,610.776308 L168.615385,602.384615 C168.615385,601.619846 169.235692,601 170,601 C170.764769,601 171.384615,601.619846 171.384615,602.384615 L171.384615,610.776308 L173.124615,608.842923 L173.125077,608.842923 C173.378462,608.561385 173.745385,608.384615 174.154308,608.384615 C174.918615,608.384615 175.538462,609.004462 175.538462,609.769231 C175.538462,610.125538 175.404154,610.45 175.183077,610.695077 L175.183077,610.695538 Z M180.615385,623.153846 L170.001385,623.153846 C170.000923,623.153846 170.000462,623.153846 170,623.153846 C170,623.153846 169.999538,623.153846 169.999077,623.153846 L159.384615,623.153846 C158.620308,623.153846 158,622.534 158,621.769231 L158,614.846154 C158,614.081385 158.620308,613.461538 159.384615,613.461538 C160.149385,613.461538 160.769231,614.081385 160.769231,614.846154 L160.769231,620.384615 L179.230769,620.384615 L179.230769,614.846154 C179.230769,614.081385 179.851077,613.461538 180.615385,613.461538 C181.380154,613.461538 182,614.081385 182,614.846154 L182,621.769231 C182,622.534 181.380154,623.153846 180.615385,623.153846 Z" />
    </g>
  </g>
);

export const Payments = () => (
  <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-193.000000, -529.000000)">
      <path d="M193,534.125 C193,533.5025 193.504,533 194.125,533 L215.875,533 C216.496375,533 217,533.5025 217,534.125 L217,537.5 L193,537.5 L193,534.125 Z M195.25,545 L206.5,545 L206.5,543.875 L195.25,543.875 L195.25,545 Z M195.25,547.25 L199.75,547.25 L199.75,546.125 L195.25,546.125 L195.25,547.25 Z M215.875,549.5 L194.125,549.5 C193.504,549.5 193,548.9975 193,548.375 L193,539.75 L217,539.75 L217,548.375 C217,548.9975 216.496375,549.5 215.875,549.5 Z" />
    </g>
  </g>
);

export const Checkmark = () => (
  <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-648.000000, -734.000000)">
      <path d="M668.623714,742.243245 L658.766571,752.320325 C658.533857,752.556917 658.212429,752.705882 657.857143,752.705882 C657.502286,752.705882 657.180857,752.556917 656.948143,752.320325 L651.376714,746.624584 C651.144,746.387992 651,746.059391 651,745.69574 C651,744.968438 651.576,744.381339 652.285714,744.381339 C652.641,744.381339 652.962429,744.530304 653.195143,744.766897 L657.857143,749.533793 L666.805286,740.385558 C667.038,740.148966 667.359429,740 667.714286,740 C668.424429,740 669,740.587099 669,741.314402 C669,741.678053 668.856429,742.006653 668.623714,742.243245" />
    </g>
  </g>
);
