import React from 'react';
import { string } from 'prop-types';

import FaIcon from '../FaIcon';

const getIconProps = (currency) => {
  switch (currency) {
    case 'USD':
      return {
        bgColor: 'bgc-status01-200',
        color: 'text-status01-700',
        name: 'USD',
      };
    case 'BTC':
      return {
        bgColor: 'bgc-status03-700',
        color: 'text-neutral-50',
        name: 'BTC',
      };
    case 'EUR':
      return {
        bgColor: 'bgc-primary-200',
        color: 'text-primary-600',
        name: 'EUR',
      };
    default:
      return {
        bgColor: 'bgc-primary-200',
        color: 'text-primary-600',
        name: 'USD',
      };
  }
};

const propTypes = {
  currency: string.isRequired,
  size: string,
};

const defaultProps = {
  size: 'regular',
};

const CurrencyIcon = ({ currency, size }) => (
  <FaIcon
    faIcon
    round
    size={size}
    {...getIconProps(currency)}
  />
);

CurrencyIcon.propTypes = propTypes;
CurrencyIcon.defaultProps = defaultProps;

export default CurrencyIcon;
