import http from 'App/http';

export const resources = {
  channel: ({ brandId, walletId }) => `brand/${brandId}/wallet/${walletId}/channel/`,
  channelItem: ({ brandId, walletId, channelId }) => `brand/${brandId}/wallet/${walletId}/channel/${channelId}/`,
};

const createItem = props => http.post(resources.channel(props[0]), props[1]);
const getItem = ({ brandId, walletId, channelId }) => http.get(resources.channelItem(
  { brandId, walletId, channelId },
));
const updateItem = ({ brandId, walletId, channelId, valueData }) => http.put(resources.channelItem(
  { brandId, walletId, channelId },
), valueData);

export default {
  createItem,
  getItem,
  updateItem,
};
