import { withI18n } from '@lingui/react';
import { compose } from 'redux';

import { withRouter } from 'react-router';
import AddressesTable from './AddressesTable';

export default compose(
  withI18n(),
  withRouter,
)(AddressesTable);
