import React, { useRef, useState, useEffect } from 'react';
import './SidebarBrands.scss';
import Avatar from 'tc-biq-design-system/build/Avatar';
import Icon from 'tc-biq-design-system/build/Icon';
import { func, array, object } from 'prop-types';
import { getInitials } from 'App/helpers';

const propTypes = {
  brands: array,
  currentBrand: object,
  chooseBrand: func.isRequired,
};

const defaultProps = {
  brands: null,
  currentBrand: null,
};

const SidebarBrands = ({ brands, currentBrand, chooseBrand }) => {
  const node = useRef();
  const [open, setOpen] = useState(false);
  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const onChooseBrand = (brand) => {
    chooseBrand(brand);
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div className="sidebar-brands__wrapper" ref={node}>
      {currentBrand && (
        <div className="sidebar-brands__selector" onClick={() => setOpen(!open)} role="button" tabIndex={0}>
          <Avatar initials={getInitials(currentBrand.name)} />
          <span className="sidebar-brands__name">
            {currentBrand.name}
            {' '}
          </span>
        </div>
      )}
      {open && brands && (
        <div className="sidebar-brands__list__wrap">
          <div className="sidebar-brands__list__drag" />
          <div className="sidebar-brands__list__current">
            <div className="sidebar-brands__list__current__inner">
              <Avatar initials={getInitials(currentBrand.name)} />
              <span className="sidebar-brands__name">{currentBrand.name}</span>
            </div>
            <Icon colorName="text-primary-500" name="Checkmark" />
          </div>
          <div className="sidebar-brands__list">
            {brands
              .filter(item => item.id !== currentBrand.id)
              .map(item => (
                <div
                  key={item.id}
                  className="sidebar-brands__list__item"
                  onClick={() => onChooseBrand(item)}
                  role="button"
                  tabIndex={0}
                >
                  <Avatar initials={getInitials(item.name)} />
                  <span className="sidebar-brands__name">{item.name}</span>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

SidebarBrands.propTypes = propTypes;
SidebarBrands.defaultProps = defaultProps;
export default SidebarBrands;
