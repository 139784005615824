import http from 'App/http';

export const resources = {
  apiKeys: bid => `brand/${bid}/apikey/`,
  apiKeyItem: ({ bid, kid }) => `brand/${bid}/apikey/${kid}/`,
};

const getAll = bid => http.get(resources.apiKeys(bid));
const getItem = ({ bid, kid }) => http.get(resources.apiKeyItem({ bid, kid }));
const createItem = ({ bid, valueData }) => http.post(resources.apiKeys(bid), valueData);
const updateItem = ({ bid,
  kid,
  valueData }) => http.put(resources.apiKeyItem({ bid, kid }), valueData);
const deleteItem = ({ bid, kid }) => http.delete(resources.apiKeyItem({ bid, kid }));

export default {
  getAll,
  getItem,
  createItem,
  updateItem,
  deleteItem,
};
