import React from 'react';
import { t, Trans } from '@lingui/macro';
import { object, arrayOf, func, string, number, oneOfType } from 'prop-types';
import { notifier } from 'tc-biq-design-system/build/Notification';
import ButtonLink from 'App/components/ButtonLink';
import ActionCellRenderer from 'App/components/TableCells/ActionCell';
import useTable from 'App/components/Table';
import { formatDate } from 'App/helpers/date';
import ApiKeysService from '../../../services/apiKey';

import './ApiKeysTable.scss';

const propTypes = {
  actions: object.isRequired,
  i18n: object.isRequired,
  addApiKey: func.isRequired,
  editApiKey: func.isRequired,
  bid: oneOfType([string, number]),
  data: arrayOf(object),
  resource: string.isRequired,
  query: object,
};

const defaultProps = {
  data: null,
  query: null,
  bid: null,
};

const TABLE_ID = 'API_KEYS_TABLE';

const ApiKeysTable = ({ actions, resource, i18n, addApiKey, editApiKey, data, bid, query }) => {
  const [Table] = useTable(TABLE_ID, resource);

  const columnDefs = [
    {
      title: i18n._(t`Description`),
      key: 'description',
    },
    {
      title: i18n._(t`Created`),
      key: 'created_at',
      // eslint-disable-next-line camelcase
      render: ({ created_at }) => formatDate(created_at),
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: rowData => (
        ActionCellRenderer({
          onDelete,
          onEdit,
          data: rowData,
        })
      ),
    },
  ];

  const onDelete = (kid) => {
    ApiKeysService.deleteItem({ kid, bid })
      .then(() => {
        notifier.success(<Trans>API key was deleted successfully</Trans>);
        actions.fetchTableData(query);
      })
      .catch(() => {
        notifier.error(<Trans>Delete failed</Trans>);
      });
  };

  const onEdit = (id) => {
    editApiKey(id);
  };

  const AddApiButton = () => (
    <ButtonLink color="transparent" icon="Plus" onClick={addApiKey}>
      <Trans>Add API Key</Trans>
    </ButtonLink>
  );

  return (
    <div>
      {data && data.length > 0 && (
      <div className="add-api-key">
        { AddApiButton() }
      </div>
      )}
      <div className="add-api-key__table">
        <Table
          options={{
            striped: false,
          }}
          className={{ 'add-api-key__table__hidden': data.length === 0 }}
          columnDefs={columnDefs}
        />
        {
          (!data || data.length === 0) && (
          <div className="add-api-key__body">
            { AddApiButton() }
          </div>
          )
        }
      </div>
    </div>
  );
};

ApiKeysTable.propTypes = propTypes;
ApiKeysTable.defaultProps = defaultProps;

export default ApiKeysTable;
