import React, { useEffect, Fragment } from 'react';
import { t } from '@lingui/macro';
import { string, bool, object, func } from 'prop-types';
import { Sidepanel } from 'tc-biq-design-system/build/Sidepanel';
import Space from 'tc-biq-design-system/build/Space';
import { Card } from 'tc-biq-design-system/build/Card';
import Avatar from 'tc-biq-design-system/build/Avatar';
import Spinner from 'tc-biq-design-system/build/Spinner';
import { notifier } from 'tc-biq-design-system/build/Notification';

import WithdrawService from 'Wallet/services/withdraw';
import useApi from '../../../App/hooks/useApi';

import './PendingWithdrawalsSidepanel.scss';

const propTypes = {
  withdrawal: string.isRequired,
  open: bool.isRequired,
  onSidepanelClose: func.isRequired,
  brand: string.isRequired,
  i18n: object.isRequired,
};

const getUserInitials = (user) => {
  let initials = '  ';
  if (user.first_name && user.last_name) {
    initials = `${user.first_name[0] || ''}${user.last_name[0] || ''}`;
  }
  return initials;
};

const PendingWithdrawalsSidepanel = ({ open, onSidepanelClose, brand, withdrawal, i18n }) => {
  const response = useApi(WithdrawService.getAuthorizedBy);

  const { run, response: data, loading, error } = response;

  useEffect(() => {
    if (brand && withdrawal) run(brand, withdrawal);
  }, [withdrawal]);

  useEffect(() => {
    if (error) {
      notifier.error(i18n._(t`Error getting withdrawal approvers.`));
    }
  }, [error]);

  const showSpinner = loading;
  const showApprovers = !loading && data;

  return (
    <Sidepanel
      hideFooter
      title="Pending"
      visible={open}
      onCloseIconClick={onSidepanelClose}
      icon="Pending"
    >
      {showSpinner && <Spinner />}
      {showApprovers && (
        <Card title="Authorized by">

          <Space size={10} />

          {!!data.authorized_by.length && data.authorized_by.map(user => (
            <Fragment key={user.id}>
              <Avatar
                initials={getUserInitials(user)}
                size="regular"
              />
              {user.first_name && user.last_name ? (
                <div className="name__full" style={{ display: 'inline-flex' }}>
                  {user.first_name}
                  {' '}
                  {user.last_name}
                </div>
              ) : (
                <div className="name__full" style={{ display: 'inline-flex' }}>
                  {user.username}
                </div>
              )}
              <Space size={10} />
            </Fragment>
          ))}

          {!data.authorized_by.length && (
            <>
              <div>No one yet...</div>
              <Space size={10} />
            </>
          )}
        </Card>
      )}
    </Sidepanel>
  );
};

PendingWithdrawalsSidepanel.propTypes = propTypes;

export default PendingWithdrawalsSidepanel;
