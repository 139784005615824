import React from 'react';
import Input from 'tc-biq-design-system/build/Input';
import { func } from 'prop-types';

const propTypes = {
  onChange: func.isRequired,
};

const TextInput = (props) => {
  const { onChange } = props;
  return <Input {...props} onChange={e => onChange(e.target.value)} />;
};

TextInput.propTypes = propTypes;

export default TextInput;
