import React from 'react';
import { Trans } from '@lingui/macro';
import Button from 'tc-biq-design-system/build/Button';
import { func, number, oneOfType, string } from 'prop-types';

const propTypes = {
  submitHandler: func.isRequired,
  resetHandler: func.isRequired,
  channelId: oneOfType([string, number]),
};

const defaultProps = {
  channelId: null,
};

const AddressFooter = ({ submitHandler, resetHandler, channelId }) => (
  <div className="user-item-footer">
    <Button color="ghost" onClick={resetHandler}>
      {channelId && (<Trans>Discard changes</Trans>)}
      {!channelId && (<Trans>Discard</Trans>)}
    </Button>
    <Button onClick={submitHandler}>
      {channelId && (<Trans>Save changes</Trans>)}
      {!channelId && (<Trans>Generate Channel</Trans>)}
    </Button>
  </div>
);

AddressFooter.propTypes = propTypes;
AddressFooter.defaultProps = defaultProps;

export default AddressFooter;
