import { LOGOUT, INVALID_TOKEN } from 'Auth/redux/models/auth';
import store from 'App/redux/store';

const WHITELISTED_HEADERS = ['Authorization', 'X-Brand-ID', 'X-OrbPay-MFA-Token', 'WWW-Authenticate', 'Accept'];

const keepWhitelistedHeaders = (config) => {
  Object.keys(config.headers).forEach((key) => {
    if (!WHITELISTED_HEADERS.includes(key)) {
      // eslint-disable-next-line no-param-reassign
      delete config.headers[key];
    }
  });
};

export const attachToken = (config) => {
  const token = localStorage.getItem('auth_token');
  const newConfig = {
    ...config,
    ...(token && {
      headers: {
        ...config.headers,
        Authorization: (token && `Token ${token}`) || undefined,
      },
    }),
  };

  keepWhitelistedHeaders(newConfig);

  return newConfig;
};

export const logoutOnLossOfAuth = (error) => {
  if (error.response && error.response.status === 403) {
    store.dispatch({
      type: INVALID_TOKEN,
    });
  }
  if (error.response && error.response.status === 401 && error.response.headers['www-authenticate'] !== 'MFA') {
    store.dispatch({
      type: LOGOUT,
    });
  }

  return Promise.reject(error);
};
