import React from 'react';
import { string, number, oneOfType } from 'prop-types';

import CurrencyIcon from '../../CurrencyIcon/CurrencyIcon';

import './CurrencyCell.scss';

const propTypes = {
  amount: oneOfType([string, number]),
  currency: string.isRequired,
};
const defaultProps = {
  amount: null,
};

const CurrencyCell = ({ amount, currency }) => (
  <>
    <span className="currency-cell__icon"><CurrencyIcon size="small" currency={currency} /></span>
    {amount}
    {' '}
    {currency}
  </>
);

CurrencyCell.propTypes = propTypes;
CurrencyCell.defaultProps = defaultProps;
export default CurrencyCell;
