import statuses from 'App/constants/statuses';

export const CREATE_EXCHANGE_REQUEST = 'CREATE_EXCHANGE_REQUEST';
export const CREATE_EXCHANGE_SUCCESS = 'CREATE_EXCHANGE_SUCCESS';
export const CREATE_EXCHANGE_FAILURE = 'CREATE_EXCHANGE_FAILURE';

const initialState = {
  exchange: null,
  loading: false,
  errors: null,
  status: statuses.DEFAULT,
};

const createExchange = ({ brandId, valueData }) => ({
  type: CREATE_EXCHANGE_REQUEST,
  brandId,
  valueData,
});

export const actions = {
  createExchange,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_EXCHANGE_REQUEST:
      return {
        ...state,
        loading: true,
        status: statuses.LOADING,
      };
    case CREATE_EXCHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        exchange: action.payload,
        status: statuses.SUCCESS,
      };
    case CREATE_EXCHANGE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        status: statuses.ERROR,
      };
    default:
      return state;
  }
}
