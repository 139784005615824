import React, { useEffect } from 'react';
import { array, object, string, number, bool } from 'prop-types';
import { Trans } from '@lingui/macro';
import cx from 'classnames';

import Spinner from 'tc-biq-design-system/build/Spinner';
import TableGrid from 'tc-biq-design-system/build/Table';
import Pagination from 'tc-biq-design-system/build/Pagination';
import Filters from 'App/components/Filters';
import TableAction from './TableAction';

import 'tc-biq-design-system/build/Pagination.css';
import './Table.scss';

/**
 * Table prop types
 */
const propTypes = {
  data: array.isRequired,
  loading: bool.isRequired,
  total: number.isRequired,
  currentPage: number.isRequired,
  pageSize: number.isRequired,
  query: object.isRequired,
  resource: string.isRequired,
  actions: object.isRequired,
  columnDefs: array.isRequired,
  options: object,
  className: string,
  filterable: bool,
  fields: array,
};

const defaultProps = {
  options: {},
  className: null,
  filterable: false,
  fields: [],
};

/**
 * Table component uses Table component
 * to display table data.
 */
const Table = ({
  data,
  loading,
  total,
  currentPage,
  pageSize,
  query,
  resource,
  actions,
  columnDefs,
  options,
  className,
  filterable,
  fields,
}) => {
  /**
   * Fetch table data
   */
  useEffect(() => {
    actions.setTableUrl(resource);

    const newQuery = {
      ...query,
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
    };

    actions.fetchTableData(newQuery);
  }, [resource, currentPage, pageSize]);

  const changeOrdering = (key) => {
    const newQuery = { ...query };
    if (newQuery.ordering && newQuery.ordering === key) {
      newQuery.ordering = `-${key}`;
    } else {
      newQuery.ordering = key;
    }
    actions.fetchTableData(newQuery);
  };

  const renderHeader = col => () => (
    <th key={col.key} onClick={() => changeOrdering(col.key)} style={{ cursor: 'pointer' }}>
      {col.title}
    </th>
  );

  /**
   * Pagination config
   */
  const pagination = {
    current: currentPage,
    total,
    pageSize,
    onChange: actions.setTableCurrentPage,
    onPageSizeChange: actions.setTablePageSize,
    sizeOptions: [15, 25, 50, 100, 200, 300],
    showTotal: (t, r) => `${r[0]} - ${r[1]} of ${t}`,
  };

  const cols = columnDefs.map((col) => {
    const column = col;
    if (column.title) {
      column.renderTitle = renderHeader(column);
    }
    return column;
  });

  return (
    <div className={cx('table-grid', className)}>
      <div className="table-grid__table">
        {loading && <div className="table-grid__loader"><Spinner /></div>}
        {filterable && (
          <Filters
            fields={fields}
            onApply={actions.fetchTableData}
            query={query}
          />
        )}
        {data.length > 0 ? (
          <TableGrid
            striped
            cols={cols}
            data={data}
            {...options}
          />
        ) : (
          <div className="table-grid__no-rows"><Trans>No rows to show</Trans></div>
        )}
      </div>
      <div className="table-grid__footer">
        <Pagination {...pagination} />
      </div>
    </div>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;
Table.Action = TableAction;

export default Table;
