import React from 'react';
import { Trans } from '@lingui/macro';
import Button from 'tc-biq-design-system/build/Button';
import { func, number, oneOfType, string } from 'prop-types';

const propTypes = {
  submitHandler: func.isRequired,
  resetHandler: func.isRequired,
  kid: oneOfType([string, number]),
};

const defaultProps = {
  kid: null,
};

const ApiKeysFooter = ({ submitHandler, resetHandler, kid }) => (
  <div className="user-item-footer">
    <Button color="ghost" onClick={resetHandler}>
      {kid && (<Trans>Discard changes</Trans>)}
      {!kid && (<Trans>Discard</Trans>)}
    </Button>
    <Button onClick={submitHandler}>
      {kid && (<Trans>Save changes</Trans>)}
      {!kid && (<Trans>Add API Keys</Trans>)}
    </Button>
  </div>
);

ApiKeysFooter.propTypes = propTypes;
ApiKeysFooter.defaultProps = defaultProps;

export default ApiKeysFooter;
