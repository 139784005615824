import React, { useState } from 'react';
import { string, object, func } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import Icon from 'tc-biq-design-system/build/Icon';
import { Pill } from 'tc-biq-design-system/build/Pill';
import Modal from 'tc-biq-design-system/build/Modal';
import Divider from 'App/components/Divider';

import useTable from 'App/components/Table';
import { formatDate } from 'App/helpers/date';
import WithdrawalService from 'Wallet/services/withdraw';

const TABLE_ID = 'PENDING_WITHDRAWALS_TABLE';

const propTypes = {
  i18n: object.isRequired,
  resource: string.isRequired,
  openSidepanel: func.isRequired,
  brand: string.isRequired,
};

const PendingWithdrawalsTable = ({ i18n, resource, openSidepanel, brand }) => {
  const [Table] = useTable(TABLE_ID, resource);

  const [authorizationModalOpen, setAuthorizationModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [withdrawal, setWithdrawal] = useState(null);

  const openAuthorizationModal = (type, withdrawalObject) => {
    setAuthorizationModalOpen(true);
    setActionType(type);
    setWithdrawal(withdrawalObject);
  };

  const closeAuthorizationModal = () => {
    setAuthorizationModalOpen(false);
    setWithdrawal(null);
  };

  const onModalConfirm = () => {
    const authorizationFunction = actionType === 'authorize'
      ? WithdrawalService.authorize
      : WithdrawalService.unauthorize;

    authorizationFunction(brand, withdrawal.id)
      .then(() => {
        closeAuthorizationModal();
      }, () => {
        closeAuthorizationModal();
      });
  };

  const columnDefs = [
    {
      title: i18n._(t`Date`),
      key: 'created_at',
      // eslint-disable-next-line camelcase
      render: ({ date }) => formatDate(date),
    },
    {
      title: i18n._(t`Amount`),
      key: 'amount',
    },
    {
      title: i18n._(t`Currency`),
      key: 'amount_currency',
    },
    {
      title: i18n._(t`To`),
      key: 'to',
      render: data => (
        <div>{data.to.currency} Bank Account - {data.to.IBAN}</div>
      ),
    },
    {
      title: i18n._(t`Status`),
      key: 'status',
      render: data => (
        <Pill
          style={{ cursor: 'pointer' }}
          onClick={() => openSidepanel(data.id)}
        >
          {data.status} {data.approvals_count}/{data.approvals_required}
        </Pill>
      ),
    },
    {
      title: '',
      key: 'unauthorize',
      render: data => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => openAuthorizationModal('unauthorize', data)}
          role="button"
          tabIndex={0}
        >
          <Icon name="Reject" colorName="text-status04-500" />
          <span className="text-status04-500" style={{ paddingLeft: '8px' }}>Unauthorize</span>
        </div>
      ),
    },
    {
      title: '',
      key: 'authorize',
      render: data => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => openAuthorizationModal('authorize', data)}
          role="button"
          tabIndex={0}
        >
          <Icon name="Approve" colorName="text-status01-500" />
          <span className="text-status01-500" style={{ paddingLeft: '8px' }}>Authorize</span>
        </div>
      ),
    },
  ];

  const fields = [
    {
      key: 'created_at',
      id: 'created_at',
      label: 'Date',
      type: 'date',
      input: 'date',
    },
    {
      key: 'amount',
      id: 'amount',
      label: 'Amount',
      type: 'number',
      input: 'number',
    },
    {
      key: 'to__IBAN',
      id: 'to__IBAN',
      label: 'IBAN',
      type: 'string',
      input: 'string',
    },
  ];

  return (
    <>
      <Table
        columnDefs={columnDefs}
        fields={fields}
        filterable
        query={{
          ordering: '-created_at',
        }}
      />

      {
        withdrawal && (
          <Modal
            title={actionType === 'authorize' ? 'Authorize' : 'Unauthorize'}
            icon={actionType === 'authorize' ? 'Checkmark' : 'Close'}
            confirmText={actionType === 'authorize' ? 'Authorize' : 'Unauthorize'}
            visible={authorizationModalOpen}
            onConfirm={onModalConfirm}
            onCancel={closeAuthorizationModal}
          >
            <div className="modal__info">
              <div className="modal__item">
                <div className="modal__item__title"><Trans>Amount:</Trans></div>

                <div className="modal__item__value modal__item__big">
                  {withdrawal.amount} {withdrawal.amount_currency}
                </div>
              </div>
            </div>

            <div className="modal__info">
              <div className="modal__item">
                <div className="modal__item__title"><Trans>To:</Trans></div>

                <div className="modal__item__value modal__item__big">
                  {withdrawal.to.currency} Bank Account - {withdrawal.to.IBAN}
                </div>
              </div>
            </div>

            <Divider spaceBottom={10} spaceTop={24} />

            <div className="modal__info">
              <div className="modal__item">
                <div className="modal__item__title">
                  Are you sure you want to
                  <strong> {actionType === 'authorize' ? 'authorize' : 'unauthorize'} </strong>
                  this request?
                </div>
              </div>

              <div className="modal__item">
                <div className="modal__item__title">
                  This action cannot be reverted.
                </div>
              </div>
            </div>
          </Modal>
        )
      }
    </>
  );
};

PendingWithdrawalsTable.propTypes = propTypes;

export default PendingWithdrawalsTable;
