import { bindActionCreators, compose } from 'redux';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { actions as userActions } from 'User/redux/models/user';
import { withRouter } from 'react-router';
import ChangePassword from './ChangePassword';

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    changePassword: userActions.changePassword,
  },
  dispatch),
});

export default compose(
  withI18n(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ChangePassword);
