import http from 'App/http';

export const resources = {
  callbackSecret: bid => `brand/${bid}/generate_secret/`,
};

const getItem = bid => http.post(resources.callbackSecret(bid));

export default {
  getItem,
};
