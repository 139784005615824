import { compose } from 'redux';
import { withI18n } from '@lingui/react';
import { withRouter } from 'react-router';

import AddressPaymentsTable from './AddressPaymentsTable';

export default compose(
  withI18n(),
  withRouter,
)(AddressPaymentsTable);
