import React from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import LoginPage from 'Auth/pages/Login';
import ResetPassword from 'Auth/pages/ResetPassword';
import NewPassword from 'Auth/pages/NewPassword';
import AcceptInvite from 'Auth/pages/AcceptInvite';
import Main from 'App/pages/Main';
import NotFoundPage from 'App/pages/NotFound';
import ProtectedRoute from 'App/components/ProtectedRoute';
import {
  RESET_PASSWORD,
  LOGIN,
  NEW_PASSWORD,
  SET_PASSWORD,
} from 'App/constants/routes';

const propTypes = {
  isAuthenticated: bool.isRequired,
};

const AppRouter = ({ isAuthenticated }) => (
  <>
    <Switch>
      <Route path={LOGIN} component={LoginPage} />
      <Route path={RESET_PASSWORD} component={ResetPassword} />
      <Route path={NEW_PASSWORD} component={NewPassword} />
      <Route path={SET_PASSWORD} component={AcceptInvite} />
      <ProtectedRoute isAllowed={isAuthenticated} path="/" component={Main} />
      <Route component={NotFoundPage} />
    </Switch>
  </>
);

AppRouter.propTypes = propTypes;

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: !!auth.token,
});

export default connect(mapStateToProps)(AppRouter);
