import React from 'react';
import { Trans } from '@lingui/macro';
import { node } from 'prop-types';
import logo from '../assets/images/logo.svg';
import hero from '../assets/images/hero-image.svg';
import whiteLogo from '../assets/images/logo-white.svg';

import './LandingPageLayout.scss';

const propTypes = {
  children: node.isRequired,
};

const LandingPageLayout = ({ children }) => (
  <div className="landing-page">
    <section id="header">
      <div className="container">
        <div className="flex-header">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="OrbPay Logo" title="OrbPay Logo" />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section id="hero" className="signinform">
      <div className="container">
        <div className="hero-flex">
          <div className="left">{children}</div>
          <div className="right">
            <img
              src={hero}
              alt="OrbPay illustration"
              title="OrbPay illustration"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="footer">
      <div className="container">
        <div className="footer-top">
          <div className="footer-logo">
            <a href="/">
              <img
                src={whiteLogo}
                alt="OrbPay Logo White"
                title="OrbPay Logo White"
              />
            </a>
          </div>
          <div className="footer-right">
            <div className="left">
              <p>
                <Trans>
                  OrbPay enables businesses to expand globally by offering
                  bitcoin as a payment option, without having to deal with
                  complex setups or associated volatility.
                </Trans>
              </p>
            </div>
            <div className="right">
              <span>
                <Trans>Contact Details</Trans>
              </span>
              <ul>
                <li>107 Cheapside, London EC2V 6DN</li>
                <li><Trans>United Kingdom</Trans></li>
                <li>
                  <a href="mailto:sales@orbpay.com"><Trans>Email Us</Trans></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <p>
            <Trans>
              OrbPay Limited is registered in England and Wales under company
              number 111137407 at 9th Floor, 107 Cheapside, London, EC2V 6DN.
            </Trans>
          </p>
          <p>
            <Trans>Copyright © 2019 Design by OrbPay</Trans>
          </p>
        </div>
      </div>
    </section>
  </div>
);

LandingPageLayout.propTypes = propTypes;

export default LandingPageLayout;
