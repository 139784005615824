import http from 'App/http';

export const resources = {
  payments: pid => `/channelpayment/${pid}/`,
  exchanges: (brand, pid) => `/brand/${brand}/exchange/${pid}/`,
  withdrawals: (brand, pid) => `/brand/${brand}/bank-withdrawal/${pid}/`,
};

const getChannelPayment = (_, pid) => http.get(resources.payments(pid));

const getExchange = (brand, pid) => http.get(resources.exchanges(brand, pid));

const getWithdrawal = (brand, pid) => http.get(resources.withdrawals(brand, pid));

export default {
  getChannelPayment,
  getExchange,
  getWithdrawal,
};
