import React from 'react';
import { array } from 'prop-types';

const propTypes = {
  brands: array.isRequired,
};

const BrandsTooltip = ({ brands }) => (
  <div>
    {brands && brands.map(item => (<div key={item}>{item}</div>))}
  </div>
);

BrandsTooltip.propTypes = propTypes;

export default BrandsTooltip;
