import { bindActionCreators, compose } from 'redux';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { tableActionsGenerator } from 'App/components/Table/generators/model';
import BankAccountsTable from './BankAccountsTable';

const actions = tableActionsGenerator('BANK_ACCOUNT_TABLE');

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...actions,
  },
  dispatch),
});

const mapStateToProps = (
  {
    brand: { currentBrand },
    tables: { BANK_ACCOUNT_TABLE: { query } },
  },
) => ({
  currentBrand,
  query,
});

export default compose(
  withI18n(),
  connect(mapStateToProps, mapDispatchToProps),
)(BankAccountsTable);
