import React, { useState, useCallback } from 'react';
import { object } from 'prop-types';
import Header from 'App/components/Header/Header';
import FaIcon from 'App/components/FaIcon';
import { Row, Col } from 'tc-biq-design-system/build/Grid';
import { t, Trans } from '@lingui/macro';
import Button from 'tc-biq-design-system/build/Button';
import BankAccountsTable from './BankAccountsTable';
import { resources as bankAccountResources } from '../../services/bankAccount';
import BankAccountSidepanel from '../../components/BankAccountSidepanel';

const propTypes = {
  i18n: object.isRequired,
  currentBrand: object,
};

const defaultProps = {
  currentBrand: null,
};

const BankAccounts = ({ i18n, currentBrand }) => {
  const [aid, setAid] = useState(null);
  const [open, setOpen] = useState(false);
  const addBankAccount = useCallback(() => {
    setAid(null);
    setOpen(true);
  }, []);
  const onSidepanelClose = useCallback(() => {
    setAid(null);
    setOpen(false);
  }, []);
  const editAccount = useCallback((id) => {
    setAid(id);
    setOpen(true);
  }, []);
  return (
    <div>
      <Header
        title={i18n._(t`Bank accounts`)}
        actions={<Button onClick={addBankAccount}><Trans>Add Bank Account</Trans></Button>}
        icon={(
          <FaIcon
            faIcon
            round
            size="regular"
            name="University"
            color="text-neutral-50"
            bgColor="bgc-primary-500"
          />
        )}
      />
      <Row>
        <Col>
          {
            currentBrand && currentBrand.id
            && (
            <BankAccountsTable
              resource={bankAccountResources.accountList(currentBrand.id)}
              onEdit={editAccount}
            />
            )
          }
        </Col>
      </Row>
      <BankAccountSidepanel
        onSidepanelClose={onSidepanelClose}
        aid={aid}
        open={open}
      />
    </div>
  );
};
BankAccounts.propTypes = propTypes;
BankAccounts.defaultProps = defaultProps;
export default BankAccounts;
