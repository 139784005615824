import React from 'react';
import { put, takeEvery, call } from 'redux-saga/effects';
import { Trans } from '@lingui/macro';
import { notifier } from 'tc-biq-design-system/build/Notification';
import withdrawService from '../../services/withdraw';
import {
  CREATE_WITHDRAW_FAILURE,
  CREATE_WITHDRAW_REQUEST,
  CREATE_WITHDRAW_SUCCESS,
} from '../models/withdraw';

export function* createWithdrawSaga({ brandId, valueData }) {
  try {
    const { data } = yield call(withdrawService.createItem, [brandId, valueData]);
    yield call(notifier.success, <Trans>Withdraw created successfully</Trans>);
    yield put({
      type: CREATE_WITHDRAW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: CREATE_WITHDRAW_FAILURE,
      payload: data,
    });
  }
}

export const withdrawSaga = [
  takeEvery(CREATE_WITHDRAW_REQUEST, createWithdrawSaga),
];
