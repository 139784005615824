import React, { useState, useCallback } from 'react';
import { bool, func, string, object } from 'prop-types';
import Modal from 'tc-biq-design-system/build/Modal';
import Input from 'tc-biq-design-system/build/Input';
import Button from 'tc-biq-design-system/build/Button';
import InvoiceService from 'App/services/invoice';
import { Trans } from '@lingui/macro';
import { notifier } from 'tc-biq-design-system/build/Notification';

import './RefundOverageModal.scss';

const propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  brand: string.isRequired,
  invoice: object.isRequired,
};

const RefundOverageModal = ({ visible, onClose, brand, invoice }) => {
  const [destinationAddress, setDestinationAddress] = useState('');

  const closeModal = () => {
    setDestinationAddress('');
    onClose();
  };

  const onSubmit = useCallback(() => {
    InvoiceService.refundOverage(brand, invoice.id, {
      destination_address: destinationAddress,
    }).then(() => {
      notifier.success('Overage refunded successfully');
    }, () => {
      notifier.error('Overage refund failed');
    }).finally(() => {
      closeModal();
    });
  }, [destinationAddress]);

  const footerRender = submitHandler => (
    <div className="withdraw-footer">
      <Button color="ghost" onClick={closeModal}>
        <Trans>Cancel</Trans>
      </Button>

      <Button onClick={submitHandler} disabled={!destinationAddress}>
        <Trans>Refund</Trans>
      </Button>
    </div>
  );

  return (
    <Modal
      visible={visible}
      icon="Payments"
      title="Refund Overage"
      closable
      onCloseIconClick={onClose}
      footerRender={() => footerRender(onSubmit)}
    >
      <Input
        name="destination_address"
        label="Customer's wallet address"
        placeholder="Customer's wallet address"
        value={destinationAddress}
        onChange={e => setDestinationAddress(e.target.value)}
      />
      <div className="modal__item">
        <div className="modal__item__title">Overage amount:</div>
        <div className="modal__item__value">{invoice.overage} {invoice.pay_currency}</div>
      </div>
    </Modal>
  );
};

RefundOverageModal.propTypes = propTypes;

export default RefundOverageModal;
