import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_ADDRESS_PAYMENT_REQUEST,
  GET_ADDRESS_PAYMENT_FAILURE,
  GET_ADDRESS_PAYMENT_SUCCESS,
} from '../models/addressPayment';

import AddressPaymentService from '../../services/addressPayment';

export function* getAddressPaymentSaga({ brandId, walletId, channelId }) {
  try {
    const { data } = yield call(AddressPaymentService.getItem,
      { brandId, walletId, channelId });
    yield put({
      type: GET_ADDRESS_PAYMENT_SUCCESS,
      addressItem: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_ADDRESS_PAYMENT_FAILURE,
      payload: data,
    });
  }
}

export const addressPaymentSaga = [
  takeEvery(GET_ADDRESS_PAYMENT_REQUEST, getAddressPaymentSaga),
];
