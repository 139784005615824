import React, { useEffect } from 'react';
import { bool, func, object, string, number } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Sidepanel } from 'tc-biq-design-system/build/Sidepanel';

import { required, isValidPercentage } from 'App/components/Form/validators';
import statuses from 'App/constants/statuses';
import Space from 'tc-biq-design-system/build/Space';
import InputText from 'App/components/Form/Field/InputText';
import Accordion from 'App/components/Accordion';
import Divider from 'App/components/Divider';
import SelectField from 'App/components/Form/Field/SelectField';
import Form from 'App/components/Form';
import FaIcon from 'App/components/FaIcon';
import AddressFooter from './AddressFooter';

const propTypes = {
  open: bool.isRequired,
  loading: bool.isRequired,
  errors: object,
  onSidepanelClose: func.isRequired,
  i18n: object.isRequired,
  match: object.isRequired,
  currentBrand: object.isRequired,
  actions: object.isRequired,
  address: object,
  status: string,
  channelId: number,
  isEdit: bool.isRequired,
};

const defaultProps = {
  errors: null,
  address: null,
  status: null,
  channelId: null,
};

const currencyOptions = [
  {
    id: 1,
    label: 'USD',
    value: 'USD',
  },
  {
    id: 2,
    label: 'EUR',
    value: 'EUR',
  },
];

const AddressSidePanel = (
  { open,
    loading,
    errors,
    onSidepanelClose,
    i18n,
    match,
    currentBrand,
    actions,
    address,
    status,
    channelId,
    isEdit,
  },
) => {
  useEffect(() => {
    if (status === statuses.SUCCESS) resetHandler();
  }, [status]);

  useEffect(() => {
    if (channelId) {
      actions.getChannel({
        brandId: currentBrand.id,
        walletId: match.params.wid,
        channelId,
      });
    }
  }, [channelId]);

  const resetHandler = () => {
    actions.clearChannel();
    onSidepanelClose();
  };

  const validators = {
    name: [required({ i18n })],
    description: [required({ i18n })],
    exchange_percentage: [isValidPercentage({ i18n })],
  };

  const onSubmit = (formData) => {
    const receiveCurrency = formData.exchange_to_currency
    && formData.exchange_to_currency.value
      ? formData.exchange_to_currency.value : formData.exchange_to_currency;
    const valueData = {
      ...formData,
      wallet: match.params.wid,
      exchange_to_currency: receiveCurrency,
    };
    if (channelId) {
      actions.updateChannel({
        channelId,
        brandId: currentBrand.id,
        walletId: match.params.wid,
        valueData });
    } else {
      actions.createChannel({
        brandId: currentBrand.id,
        walletId: match.params.wid,
        valueData });
    }
  };

  const avatar = () => {
    if (channelId) {
      return (
        <div className="icon-wrapper">
          <FaIcon colorName="text-brand-50" name="Pen" />
        </div>
      );
    }
    return (
      <div className="icon-wrapper">
        <FaIcon faIcon colorName="text-brand-50" name="Link" />
      </div>
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      loading={loading}
      errors={errors}
      validators={validators}
      defaultValues={{ ...address }}
    >
      {({ submitHandler, loader }) => (
        <>
          <Sidepanel
            className="side-panel"
            title={<Trans>Generate channel</Trans>}
            avatar={avatar}
            onCloseIconClick={resetHandler}
            visible={open}
            footerRender={() => AddressFooter({
              submitHandler,
              resetHandler,
              channelId,
            })}
          >
            <div className="address-content">
              {loader}
              <InputText
                name="name"
                placeholder={i18n._(t`Name`)}
                label={i18n._(t`Name`)}
              />
              <Space size={11} />
              <InputText
                name="description"
                placeholder={i18n._(t`Description`)}
                label={i18n._(t`Description`)}
              />
              <Divider spaceBottom={23} spaceTop={23} />
              <Accordion
                title="Enable Automatic Conversion"
                titleDescription="(optional)"
                arrowRight
                open
              >
                <SelectField
                  name="exchange_to_currency"
                  placeholder={i18n._(t`Receive currency`)}
                  label={i18n._(t`Receive currency`)}
                  options={currencyOptions}
                  type="single"
                  disabled={isEdit}
                />
                <Space size={11} />
                <InputText
                  name="exchange_percentage"
                  type="number"
                  placeholder="0.00%"
                  pattern="(\d{1,2}(\.\d{0,5})?)|100"
                  max="100"
                  label={i18n._(t`Exchange percentage`)}
                />
              </Accordion>
            </div>
          </Sidepanel>
        </>
      )}
    </Form>
  );
};
AddressSidePanel.propTypes = propTypes;
AddressSidePanel.defaultProps = defaultProps;
export default AddressSidePanel;
