import React, { useEffect } from 'react';
import { object } from 'prop-types';

import MainRouter from './MainRouter';
import MainSidebar from './MainSidebar';
import { addSmartlookIdentify, addIntercomSnippet } from '../../../widgets';

import './Main.scss';

const propTypes = {
  user: object,
};

const defaultProps = {
  user: null,
};

const MainLayout = ({ user }) => {
  useEffect(() => {
    if (user && process.env.SMARTLOOK) addSmartlookIdentify(user);
  }, [user]);

  useEffect(() => {
    if (user && user.customer && process.env.INTERCOM) addIntercomSnippet(user);
  }, [user]);

  return (
    <div className="main-page">
      {user && (
        <div className="main-page__sidebar">
          <MainSidebar user={user} />
        </div>
      )}
      <div className="main-page__content">
        <MainRouter />
      </div>
    </div>
  );
};

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

export default MainLayout;
