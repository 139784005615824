import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions as authActions } from 'Auth/redux/models/auth';

import App from './App';

const mapStateToProps = ({ translate }) => ({ language: translate.lang });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadCurrentUser: authActions.loadCurrentUser,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
