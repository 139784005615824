export const GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_FAILURE = 'GET_BRANDS_FAILURE';

export const UPDATE_CURRENT_BRAND = 'UPDATE_CURRENT_BRAND';

const initialState = {
  brands: [],
  count: null,
  next: null,
  previous: null,
  currentBrand: null,
  loading: false,
  errors: null,
};

const getBrands = initial => ({ type: GET_BRANDS_REQUEST, initial });
const setCurrentBrand = brand => ({
  type: UPDATE_CURRENT_BRAND,
  currentBrand: brand,
});

export const actions = {
  getBrands,
  setCurrentBrand,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BRANDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        brands: action.brands,
        count: action.count,
        next: action.next,
        previous: action.previous,
      };
    case GET_BRANDS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case UPDATE_CURRENT_BRAND:
      return {
        ...state,
        currentBrand: action.currentBrand,
      };
    default:
      return state;
  }
}
