import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import Checkbox from 'tc-biq-design-system/build/Checkbox';
import Icon from 'tc-biq-design-system/build/Icon';
import classNames from 'classnames';
import Field from '../Form/Field';

import './MultiSelect.scss';

const propTypes = {
  title: string.isRequired,
  name: string.isRequired,
  icon: string,
  getData: func,
};

const defaultProps = {
  icon: null,
  getData: null,
};

const MultiSelect = ({ icon, title, name, getData }) => {
  const [dataOptions, setDataOptions] = useState(null);

  useEffect(() => {
    fetchData(getData);
  }, []);

  const getChecked = (value, id) => {
    if (!value) return false;
    return value.filter(item => item === id).length > 0;
  };

  const changeValues = (value, id) => {
    const arrayValues = value ? [...value] : [];
    if (getChecked(value, id)) {
      return arrayValues.filter(item => item !== id);
    }
    arrayValues.push(id);
    return arrayValues;
  };

  const checkSelectAll = value => value && value.length === dataOptions.length;

  const fetchData = async (fn) => {
    const res = await fn();
    setDataOptions(res.data.results);
  };

  const selectAll = (value) => {
    if (checkSelectAll(value)) return null;
    return dataOptions.map(item => item.id);
  };

  const getClassNames = error => classNames(
    'multi-select',
    { 'has-error': error },
  );

  const renderHelpText = (helpText) => {
    if (!helpText) return null;
    return <div className="help-text">{helpText}</div>;
  };

  return (
    <Field name={name}>
      {({ onChange, value, error }) => (
        <div>
          <div className={getClassNames(error && error[0])}>
            <div className="multi-select__header">
              {icon && (
                <Icon
                  name={icon}
                  colorName="text-primary-500"
                  size="large"
                />
              )}
              {title}
            </div>
            <div className="multi-select__content">
              {!dataOptions && (<div className="multi-select__nodata">No data</div>)}
              {dataOptions && (
                <div>
                  <div className="multi-select__content__top multi-select__content__item">
                    <Checkbox
                      checked={checkSelectAll(value)}
                      onChange={() => onChange(selectAll(value))}
                    >
                      <span className="multi-select__content__select-all">
                SELECT ALL
                      </span>
                    </Checkbox>
                  </div>
                  <div className="multi-select__content__scroll">
                    {dataOptions.map(item => (
                      <div className="multi-select__content__item" key={item.id}>
                        <Checkbox
                          checked={getChecked(value, item.id)}
                          onChange={() => onChange(changeValues(value, item.id))}
                        >
                          <span className="multi-select__content__name">{item.name}</span>
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          {renderHelpText(error && error[0])}
        </div>
      )}
    </Field>
  );
};

MultiSelect.propTypes = propTypes;
MultiSelect.defaultProps = defaultProps;

export default MultiSelect;
