import React from 'react';
import { Row, Col } from 'tc-biq-design-system/build/Grid';
import { object } from 'prop-types';
import Header from 'App/components/Header';
import FaIcon from 'App/components/FaIcon';
import { t } from '@lingui/macro';
import useTable from 'App/components/Table';
import { formatDate } from 'App/helpers/date';
import { capitalizeFirstLetter, downloadResponse } from 'App/helpers';
import { Pill } from 'tc-biq-design-system/build/Pill';
import http from 'App/http';
import { resources } from '../../services/transactionhistory';

const TABLE_ID = 'TRANSACTION_HISTORY_BANK_WITHDRAWALS_TABLE';

const propTypes = {
  i18n: object.isRequired,
  currentBrand: object.isRequired,
};

const BankWithdrawals = ({ i18n, currentBrand }) => {
  const [Table] = useTable(TABLE_ID, resources.bankWithdrawals(currentBrand.id));

  const columnDefs = [
    {
      title: i18n._(t`Date`),
      key: 'created_at',
      render: data => formatDate(data.created_at),
    },
    {
      title: i18n._(t`Amount`),
      key: 'amount',
      render: data => `${data.amount} ${data.amount_currency}`,
    },
    {
      title: i18n._(t`To`),
      key: 'to',
      render: data => `${data.to_currency} Bank Account`,
    },
    {
      title: i18n._(t`Fee amount`),
      key: 'fee',
      render: (data) => {
        if (data.fee_currency !== 'XYZ' && data.fee !== null) {
          return `${data.fee} ${data.fee_currency}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Status`),
      key: 'status',
      render: data => <Pill type={['executed', 'filled'].includes(data.status) ? 'status01' : 'status03'}>{capitalizeFirstLetter(data.status)}</Pill>,
    },
  ];

  const filterFields = [
    {
      type: 'date',
      input: 'date',
      key: 'created_at',
      id: 'created_at',
      label: 'Date',
    },
    {
      type: 'string',
      input: 'text',
      key: 'status',
      id: 'status',
      label: 'Status',
    },
    {
      type: 'number',
      input: 'number',
      key: 'amount',
      id: 'amount',
      label: 'Amount',
    },
    {
      type: 'string',
      input: 'text',
      key: 'amount_currency',
      id: 'currency',
      label: 'Currency',
    },
  ];

  return (
    <div>
      <Header
        title={i18n._(t`Bank Withdrawals`)}
        icon={<FaIcon round size="regular" name="Notes" color="text-neutral-50" bgColor="bgc-primary-500" />}
        actions={(
          <>
            <Header.Separator />
            <Header.Action
              title="Download as .CSV"
              icon="Download"
              onAction={() => {
                http.get(
                  resources.bankWithdrawals(currentBrand.id),
                  {
                    headers: {
                      Accept: 'text/csv',
                    },
                  },
                ).then(response => downloadResponse(response));
              }}
            />
          </>
        )}
      />
      <Row>
        <Col>
          <Table
            columnDefs={columnDefs}
            filterable
            fields={filterFields}
            query={{
              ordering: '-created_at',
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

BankWithdrawals.propTypes = propTypes;

export default BankWithdrawals;
