import React, { useEffect, useState } from 'react';
import { bool, func, string, object } from 'prop-types';
import Modal from 'tc-biq-design-system/build/Modal';
import InvoiceService from 'App/services/invoice';
import useApi from 'App/hooks/useApi';
import Spinner from 'tc-biq-design-system/build/Spinner';
import Divider from 'App/components/Divider';
import Button from 'tc-biq-design-system/build/Button';
import InfoBox from 'tc-biq-design-system/build/InfoBox';
import Space from 'tc-biq-design-system/build/Space';
import { notifier } from 'tc-biq-design-system/build/Notification';
import { t } from '@lingui/macro';

import './InvoiceExchangeOverageModal.scss';

const propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  brand: string.isRequired,
  invoice: string.isRequired,
  i18n: object.isRequired,
};

const InvoiceExchangeOverageModal = ({ visible, onClose, brand, invoice, i18n }) => {
  const [timer, setTimer] = useState('');
  const [timerID, setTimerID] = useState(null);

  const response = useApi(InvoiceService.recalculateOverage);

  const { run, response: exchange, loading, error } = response;

  const createTimer = (expiresAt) => {
    // Update the count down every 1 second
    const x = setInterval(() => {
      const now = new Date().getTime();

      // Find the distance between now an the count down date
      const distance = expiresAt - now;

      // Time calculations for days, hours, minutes and seconds
      // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      // const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(x);
        minutes = '00';
        seconds = '00';
      }
      minutes = (`0${minutes}`).slice(-2);
      seconds = (`0${seconds}`).slice(-2);
      setTimer(`${minutes}:${seconds}`);
    }, 1000);

    setTimerID(x);
  };

  const recalculate = () => {
    run(brand, invoice);
    const expiresAt = new Date().getTime() + 30000;
    createTimer(expiresAt);
  };

  useEffect(() => {
    if (brand && invoice) {
      recalculate();
      const interval = setInterval(recalculate, 30000);
      return () => {
        clearInterval(interval);
        clearInterval(timerID);
      };
    }
  }, [brand, invoice]);

  useEffect(() => {
    if (error) {
      notifier.error(i18n._(t`Get exchange info error.`));
    }
  }, [error]);

  const showSpinner = loading;
  const showExchange = !loading && exchange;

  const submitExchange = () => {
    InvoiceService.exchangeOverage(brand, invoice).then(() => {
      notifier.success('Invoice overage exchanged successfully');
    }, () => {
      notifier.error('Error exchanging invoice overage');
    }).finally(() => {
      onClose();
    });
  };

  const footerRender = onSubmit => (
    <div>
      <Button color="ghost" onClick={onClose}>Cancel</Button>
      <Button onClick={onSubmit}>Exchange</Button>
    </div>
  );

  return (
    <Modal
      visible={visible}
      icon="Payments"
      title="Exchange Overage"
      closable
      onCloseIconClick={onClose}
      footerRender={() => footerRender(submitExchange)}
    >
      {showSpinner && <Spinner />}
      {showExchange && (
        <>
          <div className="modal__item">
            <div className="modal__item__title">Refreshes in:</div>
            <div className="modal__item__value">{timer}</div>
          </div>
          <div className="modal__item">
            <div className="modal__item__title">Overage:</div>
            <div className="modal__item__value">{exchange.overage} {exchange.pay_currency}</div>
          </div>
          <div className="modal__item">
            <div className="modal__item__title">Service fee:</div>
            <div className="modal__item__value">
              {exchange.exchange_fee} {exchange.exchange_fee_currency}
            </div>
          </div>
          <div className="modal__item">
            <div className="modal__item__title">Receive amount:</div>
            <div className="modal__item__value">
              {exchange.to_receive} {exchange.to_receive_currency}
            </div>
          </div>

          <Divider spaceBottom={10} spaceTop={24} />


          <div className="modal__item">
            <div className="modal__item__title">Exchange rate:</div>
            <div className="modal__item__value">
              {exchange.current_exchange_rate} {exchange.exchange_symbol}
            </div>
          </div>

          <Space size={10} />

          <InfoBox
            header="Displayed exchange rate is descriptive. It may change while completing your transaction due to exchange rate volatility."
          />
        </>
      )}
    </Modal>
  );
};

InvoiceExchangeOverageModal.propTypes = propTypes;

export default InvoiceExchangeOverageModal;
