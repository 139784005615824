import React from 'react';
import { object, string } from 'prop-types';
import UserCell from 'App/components/TableCells/UserCell';

const propTypes = {
  target: object.isRequired,
  type: string.isRequired,
};

const AuditLogTargetCell = (target, type) => {
  const bankAccountCell = () => (
    <div>
      {target.name} ({target.IBAN} - {target.currency})
    </div>
  );

  const exchangeCell = () => (
    <div>
      {target.exchange_amount} {target.from_currency} - {target.status}
    </div>
  );

  const withdrawalCell = () => (
    <div>
      {target.amount }
      -
      { target.status}
    </div>
  );

  const brandCell = () => (
    <div>
      {target.name}
    </div>
  );

  const addressCell = () => (
    <div>
      {target.deposit_address}
    </div>
  );

  const addressPaymentCell = () => (
    <div>
      {target.address }
      -
      { target.deposited}
    </div>
  );

  const apiKeyCell = () => (
    <div>
      {target.description}
    </div>
  );

  const invoiceCell = () => (
    <div>
      {target.address}
    </div>
  );

  const channelCell = () => (
    <div>
      {target.deposit_address}
    </div>
  );

  let component = null;

  const data = target;
  switch (type) {
    case 'bank account':
      component = bankAccountCell();
      break;
    case 'exchange':
      component = exchangeCell();
      break;
    case 'bank withdrawal':
      component = withdrawalCell();
      break;
    case 'brand':
      component = brandCell();
      break;
    case 'address':
      component = addressCell();
      break;
    case 'user':
      component = UserCell({ data });
      break;
    case 'address payment':
      component = addressPaymentCell();
      break;
    case 'api key':
      component = apiKeyCell();
      break;
    case 'invoice':
      component = invoiceCell();
      break;
    case 'channel':
      component = channelCell();
      break;
    default:
      component = null;
  }

  return component;
};

AuditLogTargetCell.propTypes = propTypes;

export default AuditLogTargetCell;
