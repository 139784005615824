import statuses from 'App/constants/statuses';

export const GET_BANK_ACCOUNT_ITEM_REQUEST = 'GET_BANK_ACCOUNT_ITEM_REQUEST';
export const GET_BANK_ACCOUNT_ITEM_SUCCESS = 'GET_BANK_ACCOUNT_ITEM_SUCCESS';
export const GET_BANK_ACCOUNT_ITEM_FAILURE = 'GET_BANK_ACCOUNT_ITEM_FAILURE';

export const CREATE_BANK_ACCOUNT_ITEM_REQUEST = 'CREATE_BANK_ACCOUNT_ITEM_REQUEST';
export const CREATE_BANK_ACCOUNT_ITEM_SUCCESS = 'CREATE_BANK_ACCOUNT_ITEM_SUCCESS';
export const CREATE_BANK_ACCOUNT_ITEM_FAILURE = 'CREATE_BANK_ACCOUNT_ITEM_FAILURE';

export const UPDATE_BANK_ACCOUNT_ITEM_REQUEST = 'UPDATE_BANK_ACCOUNT_ITEM_REQUEST';
export const UPDATE_BANK_ACCOUNT_ITEM_SUCCESS = 'UPDATE_BANK_ACCOUNT_ITEM_SUCCESS';
export const UPDATE_BANK_ACCOUNT_ITEM_FAILURE = 'UPDATE_BANK_ACCOUNT_ITEM_FAILURE';

export const CLEAR_BANK_ACCOUNT_ITEM = 'CLEAR_BANK_ACCOUNT_ITEM';

const getBankAccount = ({ bid, aid }) => ({
  type: GET_BANK_ACCOUNT_ITEM_REQUEST,
  bid,
  aid,
});

const createBankAccount = ({ bid, valueData }) => ({
  type: CREATE_BANK_ACCOUNT_ITEM_REQUEST,
  bid,
  valueData,
});

const updateBankAccount = ({ bid, aid, valueData }) => ({
  type: UPDATE_BANK_ACCOUNT_ITEM_REQUEST,
  bid,
  aid,
  valueData,
});

const clearBankAccount = () => ({
  type: CLEAR_BANK_ACCOUNT_ITEM,
});

export const actions = {
  getBankAccount,
  createBankAccount,
  updateBankAccount,
  clearBankAccount,
};

const initialState = {
  bankAccount: null,
  loading: false,
  errors: null,
  status: statuses.DEFAULT,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BANK_ACCOUNT_ITEM_REQUEST:
    case CREATE_BANK_ACCOUNT_ITEM_REQUEST:
    case UPDATE_BANK_ACCOUNT_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        status: statuses.LOADING,
      };
    case GET_BANK_ACCOUNT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        bankAccount: action.payload,
        status: statuses.DEFAULT,
      };
    case CREATE_BANK_ACCOUNT_ITEM_SUCCESS:
    case UPDATE_BANK_ACCOUNT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        bankAccount: action.payload,
        status: statuses.SUCCESS,
      };
    case GET_BANK_ACCOUNT_ITEM_FAILURE:
    case CREATE_BANK_ACCOUNT_ITEM_FAILURE:
    case UPDATE_BANK_ACCOUNT_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        status: statuses.ERROR,
      };
    case CLEAR_BANK_ACCOUNT_ITEM:
      return initialState;
    default:
      return state;
  }
}
