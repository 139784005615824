import React, { useState, useEffect, useCallback } from 'react';
import { object } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import Modal from 'tc-biq-design-system/build/Modal';
import InfoBox from 'tc-biq-design-system/build/InfoBox';
import Button from 'tc-biq-design-system/build/Button';
import Checkbox from 'tc-biq-design-system/build/Checkbox';

import 'tc-biq-design-system/build/Modal.css';
import 'tc-biq-design-system/build/InfoBox.css';

import './CallbackSecretModal.scss';

const propTypes = {
  callbackSecret: object,
  i18n: object.isRequired,
};

const defaultProps = {
  callbackSecret: null,
};

const CallbackSecretModal = ({ callbackSecret, i18n }) => {
  const [checked, setChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (callbackSecret) setOpenModal(true);
  }, [callbackSecret]);

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setChecked(false);
  }, []);

  const toggleCheck = useCallback(() => {
    setChecked(!checked);
  }, []);

  const callbackSecretFooter = () => (
    <div>
      <Button
        color={!checked ? 'ghost' : 'primary'}
        disabled={!checked}
        onClick={closeModal}
      >
        <Trans>Close Window</Trans>
      </Button>
    </div>
  );

  return (
    <Modal
      visible={openModal}
      title={i18n._(t`Callback secret created successfully`)}
      icon="Approve"
      type="success"
      size="large"
      footerRender={callbackSecretFooter}
    >
      <InfoBox header={i18n._(t`Warning: You will see the secret only once`)} type="warning">
        <Trans>
          As a safety measure, we will not show this secret again. Make sure to save it.
        </Trans>
      </InfoBox>
      <div>
        <div className="callback-secret__item">
          <small><Trans>Callback Secret</Trans></small>
          <div>
            { callbackSecret }
          </div>
        </div>
      </div>
      <Checkbox onChange={toggleCheck} checked={checked}>
        <Trans>
          I saved the secret and I&apos;m aware that as a safety measure,
          I will not be shown this secret again.
        </Trans>
      </Checkbox>
    </Modal>
  );
};

CallbackSecretModal.propTypes = propTypes;
CallbackSecretModal.defaultProps = defaultProps;

export default CallbackSecretModal;
