import React from 'react';
import { bool, object, func } from 'prop-types';
import Modal from 'tc-biq-design-system/build/Modal';
import Space from 'tc-biq-design-system/build/Space';
import Button from 'tc-biq-design-system/build/Button';
import { t, Trans } from '@lingui/macro';
import InputText from 'App/components/Form/Field/InputText';
import Form from 'App/components/Form';
import { maxLength, required, minLength } from 'App/components/Form/validators';

const propTypes = {
  visible: bool.isRequired,
  loading: bool.isRequired,
  i18n: object.isRequired,
  sendCode: func.isRequired,
  onClose: func.isRequired,
  errors: object,
};

const defaultProps = {
  errors: null,
};

const ConfirmAuthModal = ({ visible, loading, errors, i18n, onClose, sendCode }) => {
  const validators = {
    code: [maxLength({ i18n }), required({ i18n }), minLength({ i18n, length: 6 })],
  };
  const footerRender = submitHandler => (
    <div className="withdraw-footer">
      <Button color="ghost" onClick={onClose}>
        <Trans>Cancel</Trans>
      </Button>
      <Button onClick={submitHandler}>
        <Trans>Verify</Trans>
      </Button>
    </div>
  );
  return (
    <Form
      loading={loading}
      errors={errors}
      validators={validators}
      onSubmit={sendCode}
    >
      {({ submitHandler, loader }) => (
        <>
          <Modal
            footerRender={() => footerRender(submitHandler)}
            visible={visible}
            title="Two-factor authentication"
            icon="Warning"
          >
            {loader}
            <div>
              <Trans>
                Enter the 6-digit code you see in the Google Authentication app.
              </Trans>
            </div>
            <Space size={30} />
            <InputText
              name="code"
              type="number"
              label={i18n._(t`Enter code`)}
            />
          </Modal>
        </>
      )}
    </Form>
  );
};

ConfirmAuthModal.propTypes = propTypes;
ConfirmAuthModal.defaultProps = defaultProps;

export default ConfirmAuthModal;
