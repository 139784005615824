import React from 'react';
import { DatePicker } from 'tc-biq-design-system/build/DatePicker';
import { string } from 'prop-types';
import moment from 'moment';

const propTypes = {
  value: string,
};

const defaultProps = {
  value: null,
};

const DateInput = (props) => {
  const { value } = props;
  const date = value || moment().format('YYYY-MM-DD');
  return <DatePicker {...props} date={date} />;
};

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default DateInput;
