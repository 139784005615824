import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from 'Auth/redux/models/auth';
import brand from 'Brand/redux/models/brand';
import brandItem from 'Brand/redux/models/brandItem';
import wallet from 'Wallet/redux/models/wallet';
import userItem from 'User/redux/models/user';
import addressItem from 'Wallet/redux/models/address';
import addressPayment from 'Wallet/redux/models/addressPayment';
import apiKey from 'Brand/redux/models/apiKey';
import bankAccount from 'BankAccount/redux/models/bankAccount';
import bankAccountItem from 'BankAccount/redux/models/bankAccountItem';
import withdraw from 'Wallet/redux/models/withdraw';
import qrCode from 'User/redux/models/qrCode';
import exchange from 'Wallet/redux/models/exchange';

import { tableReducerGenerator } from '../components/Table/generators/model';
import translate from './models/translate';

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  translate,
  auth,
  brand,
  wallet,
  brandItem,
  userItem,
  addressItem,
  addressPayment,
  apiKey,
  bankAccount,
  bankAccountItem,
  withdraw,
  qrCode,
  exchange,
  tables: combineReducers({
    BRANDS_TABLE: tableReducerGenerator('BRANDS_TABLE'),
    ADDRESSES_TABLE: tableReducerGenerator('ADDRESSES_TABLE'),
    ADDRESS_PAYMENT_TABLE: tableReducerGenerator('ADDRESS_PAYMENT_TABLE'),
    USERS_TABLE: tableReducerGenerator('USERS_TABLE'),
    API_KEYS_TABLE: tableReducerGenerator('API_KEYS_TABLE'),
    BANK_ACCOUNT_TABLE: tableReducerGenerator('BANK_ACCOUNT_TABLE'),
    AUDIT_LOGS_TABLE: tableReducerGenerator('AUDIT_LOGS_TABLE'),
    WALLET_TRANSACTIONS_TABLE: tableReducerGenerator('WALLET_TRANSACTIONS_TABLE'),
    TRANSACTION_HISTORY_ADDRESS_PAYMENTS_TABLE: tableReducerGenerator('TRANSACTION_HISTORY_ADDRESS_PAYMENTS_TABLE'),
    TRANSACTION_HISTORY_BANK_WITHDRAWALS_TABLE: tableReducerGenerator('TRANSACTION_HISTORY_BANK_WITHDRAWALS_TABLE'),
    TRANSACTION_HISTORY_CRYPTO_WITHDRAWALS_TABLE: tableReducerGenerator('TRANSACTION_HISTORY_CRYPTO_WITHDRAWALS_TABLE'),
    TRANSACTION_HISTORY_INVOICES_TABLE: tableReducerGenerator('TRANSACTION_HISTORY_INVOICES_TABLE'),
    PENDING_WITHDRAWALS_TABLE: tableReducerGenerator('PENDING_WITHDRAWALS_TABLE'),
  }),
});

export default createRootReducer;
