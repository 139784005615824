import http from 'App/http';

export const resources = {
  accountList: () => 'bank-account/',
  createItem: () => 'bank-account/',
  accountItem: ({ aid }) => `bank-account/${aid}/`,
};

const getAll = () => http.get(resources.accountList());
const getItem = ({ aid }) => http.get(resources.accountItem({ aid }));
const createItem = props => http.post(resources.createItem(props[0]), props[1]);
const updateItem = props => http.put(resources.accountItem(props[0]), props[1]);
const deleteItem = ({ aid }) => http.delete(resources.accountItem({ aid }));

export default {
  getAll,
  getItem,
  createItem,
  updateItem,
  deleteItem,
};
