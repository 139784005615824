/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { object, func, bool } from 'prop-types';
import Spinner from 'tc-biq-design-system/build/Spinner';
import { notifier } from 'tc-biq-design-system/build/Notification';

import FormContext from './FormContext';

import './Form.scss';

const errorHandler = (errors) => {
  const { non_field_errors, messages } = errors;
  if (non_field_errors) {
    notifier.error(non_field_errors.map((err, index) => <span key={index}>{err}</span>));
  } else if (messages) {
    notifier.error(messages.map(err => <span>{err.text}</span>));
  } else {
    notifier.error(<Trans>Something went wrong</Trans>);
  }
};

const propTypes = {
  defaultValues: object,
  children: func.isRequired,
  onSubmit: func.isRequired,
  validators: object,
  loading: bool.isRequired,
  errors: object,
};

const defaultProps = {
  defaultValues: {},
  validators: {},
  errors: null,
};

const Form = ({ defaultValues, onSubmit, children, validators, loading, errors }) => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormValues({ ...defaultValues });
  }, [defaultValues]);

  const onChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });

    const messages = validate(name, value);
    setFormError(name, messages);
  };

  const setFormError = (name, error) => {
    setFormErrors({
      ...formErrors,
      [name]: error,
    });
  };

  const validate = (name, value) => {
    const fieldValidators = validators[name];
    return fieldValidators ? fieldValidators.reduce((acc, validator) => {
      const message = !validator.test(value, formValues) && validator.message;
      return message ? [...acc, message] : acc;
    }, []) : [];
  };

  const validateAll = () => Object.keys(validators).reduce((acc, name) => {
    const value = formValues[name];
    const messages = validate(name, value);
    return {
      ...acc,
      [name]: messages,
    };
  }, {});

  const isValidForm = validationErrors => Object.keys(validationErrors)
    .some(name => !!validationErrors[name] && validationErrors[name].length > 0);

  const submitHandler = (event) => {
    if (event) event.preventDefault();
    const validationErrors = validateAll();
    if (isValidForm(validationErrors)) {
      setFormErrors(validationErrors);
    } else {
      onSubmit(formValues);
    }
  };

  const resetForm = () => {
    setFormValues(defaultValues);
  };

  useEffect(() => {
    if (errors && !errors.non_field_errors && !errors.messages) {
      setFormErrors(errors);
    } else if (errors) {
      errorHandler(errors);
    }
  }, [errors]);

  const loader = <div className={`orb-form__loader ${loading && 'orb-form__loader--loading'}`}><Spinner /></div>;

  return (
    <div className="orb-form">
      <FormContext.Provider
        value={{ onChange, formValues, formErrors }}
      >
        {children({ submitHandler, resetForm, loader, formValues })}
      </FormContext.Provider>
    </div>
  );
};

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
