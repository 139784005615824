import React, { useEffect } from 'react';
import { string, object, bool } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import QRCode from 'qrcode.react';
import Button from 'tc-biq-design-system/build/Button';
import Icon from 'tc-biq-design-system/build/Icon';
import Form from 'App/components/Form';
import InputText from 'App/components/Form/Field/InputText';

import './QRCode.scss';

const propTypes = {
  actions: object.isRequired,
  code: string,
  open: bool.isRequired,
  i18n: object.isRequired,
  loading: bool.isRequired,
  errors: object,
  confirm: bool.isRequired,
  info: object,
};

const defaultProps = {
  errors: null,
  code: null,
  info: null,
};

const QRCodeWrap = ({ open, i18n, loading, errors, actions, code, confirm, info }) => {
  const validators = {};

  const getCode = () => {
    const label = encodeURIComponent('OrbPay Account');
    return `otpauth://totp/${label}?secret=${code}`;
  };

  useEffect(() => {
    if (open && !confirm) actions.getQRCode();
    if (open && confirm) actions.getQRCodeInfo();
  }, [open, confirm]);

  const onSubmit = (data) => {
    const { token } = data;
    if (confirm) {
      const { id } = info && info[0];
      actions.deleteQRCode({ token, id });
    } else {
      actions.confirmQRCode(token);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      loading={loading}
      errors={errors}
      validators={validators}
    >
      {({ submitHandler, loader }) => (
        <>
          {loader}
          <small className="qrcode__descr">
            <Trans>
              Two-factor authentication (2FA) is an extra layer of protection
              in addition to your password.
            </Trans>
          </small>
          {
            !confirm && (
            <div className="qrcode__wrapper">
              <div className="qrcode__item">
                {
                  code && (<QRCode value={getCode(code)} size={120} />)
                }
              </div>
              <div className="qrcode__item">
                <ul className="qrcode__list">
                  <li><Trans>Download Google Authenticator app</Trans></li>
                  <li><Trans>Scan the QR code bellow.</Trans></li>
                  <li><Trans>Enter the validation code from the app.</Trans></li>
                </ul>
                <div className="qrcode__form">
                  <InputText
                    name="token"
                    placeholder={i18n._(t`Enter the validation code`)}
                  />
                  <Button
                    onClick={submitHandler}
                    color="ghost"
                  >
                    <Trans>Enable 2FA</Trans>
                  </Button>
                </div>
              </div>
            </div>
            )
          }
          {
            confirm && (
            <div>
              <div className="success-message text-status01-600">
                <Icon name="Approve" color="text-status01-600" size="large" />
                 Enabled
              </div>
            </div>
            )
          }
        </>
      )}
    </Form>
  );
};

QRCodeWrap.propTypes = propTypes;
QRCodeWrap.defaultProps = defaultProps;

export default QRCodeWrap;
