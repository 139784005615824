import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon from 'tc-biq-design-system/build/Icon';
import { bool, string } from 'prop-types';
import * as icons from './components';
import './FaIcon.scss';

const propTypes = {
  faIcon: bool,
  round: bool,
  name: string.isRequired,
  bgColor: string,
  color: string,
  className: string,
  size: string,
};

const defaultProps = {
  faIcon: false,
  round: false,
  bgColor: null,
  color: null,
  className: null,
  size: null,
};

const getSize = size => SIZES[size] || SIZES.regular;

const SIZES = {
  extraSmall: 8,
  small: 12,
  regular: 16,
  large: 24,
  extraLarge: 48,
};

const FaIcon = ({ faIcon, name, round, bgColor, color, className, size, ...props }) => {
  const [iconSize, setIconSize] = useState(null);
  const IconComponent = icons[name];
  const getClassNames = classNames(
    className,
    'fa-icon',
    { 'fa-icon__rounded': round },
    bgColor,
    color,
    size,
  );

  useEffect(() => {
    setIconSize(getSize(size));
  }, [size]);

  return (
    <div className={getClassNames} style={{ width: iconSize * 2, height: iconSize * 2 }}>
      {!faIcon && (<Icon name={name} size={size} {...props} />)}
      {faIcon && (
        <svg width={iconSize} height={iconSize} viewBox="0 0 24 24" version="1.1">
          <defs />
          <IconComponent />
        </svg>
      )}
    </div>
  );
};

FaIcon.propTypes = propTypes;
FaIcon.defaultProps = defaultProps;

export default FaIcon;
