import React from 'react';
import { string } from 'prop-types';

import CurrencyIcon from '../../../../App/components/CurrencyIcon';

import './PaymentHeader.scss';

const propTypes = {
  amount: string,
  currency: string,
};

const defaultProps = {
  amount: 0,
  currency: '',
};

const PaymentHeader = ({ amount, currency }) => (
  <div className="payment-header">
    <span className="payment-header__amount text-neutral-500 tc-paragraph-strong">{`${amount} ${currency}`}</span>
    <CurrencyIcon currency={currency} />
  </div>
);

PaymentHeader.propTypes = propTypes;
PaymentHeader.defaultProps = defaultProps;

export default PaymentHeader;
