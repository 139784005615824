import React from 'react';
import Input from 'tc-biq-design-system/build/Input';
import { func } from 'prop-types';

const propTypes = { onChange: func.isRequired };

const NumberInput = (props) => {
  const { onChange } = props;

  // Validates number input so that it matches the following regexp
  // It matches any amount of numbers followed by an optional dot and up to 8 numbers after the dot
  const validateInput = (event) => {
    const { value } = event.target;
    const allowedCharacters = /^[\d.,-]$/;
    const pattern = new RegExp(/(-?((\d+.\d{0,8})|(\d+)))?/);

    if (!allowedCharacters.test(event.key) || !pattern.test(value)) {
      event.preventDefault();
    }
  };

  return (
    <Input
      type="number"
      {...props}
      onKeyPress={e => validateInput(e)}
      onChange={e => onChange(e.target.value)}
    />
  );
};

NumberInput.propTypes = propTypes;

export default NumberInput;
