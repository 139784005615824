import React from 'react';
import { number } from 'prop-types';
import Space from 'tc-biq-design-system/build/Space';

import './Divider.scss';

const propTypes = {
  spaceTop: number,
  spaceBottom: number,
};

const defaultProps = {
  spaceTop: null,
  spaceBottom: null,
};

const Divider = ({ spaceTop, spaceBottom }) => (
  <div>
    {spaceTop && (<Space size={spaceTop} />)}
    <div className="divider-line" />
    {spaceBottom && (<Space size={spaceBottom} />)}
  </div>
);

Divider.propTypes = propTypes;
Divider.defaultProps = defaultProps;

export default Divider;
