import { useContext } from 'react';
import { string, func } from 'prop-types';
import FormContext from '../FormContext';

const propTypes = {
  name: string.isRequired,
  children: func.isRequired,
};

const Field = ({ name, children, ...rest }) => {
  const { formValues, formErrors, onChange } = useContext(FormContext);
  const value = formValues[name];
  const error = formErrors[name];

  return children({
    value,
    error,
    onChange: val => onChange(name, val),
    ...rest,
  });
};

Field.propTypes = propTypes;

export default Field;
