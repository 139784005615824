import React, { useState, useCallback } from 'react';
import { object } from 'prop-types';
import useTable from 'App/components/Table';
import { formatDate } from 'App/helpers/date';
import { stringTruncate, truncateText, downloadResponse } from 'App/helpers';
import { t } from '@lingui/macro';
import Header from 'App/components/Header';
import FaIcon from 'App/components/FaIcon';
import InvoiceSidepanel from 'App/sidepanels/InvoiceSidepanel';
import { Pill } from 'tc-biq-design-system/build/Pill';
import http from 'App/http';
import Button from 'tc-biq-design-system/build/Button';
import { Row, Col } from 'tc-biq-design-system/build/Grid';
import { invoiceStatuses, filterFields } from 'App/constants/invoices';
import { resources } from '../../services/transactionhistory';

import './InvoiceHistory.scss';

const TABLE_ID = 'TRANSACTION_HISTORY_INVOICES_TABLE';

const propTypes = {
  i18n: object.isRequired,
  currentBrand: object.isRequired,
};

const InvoiceHistory = ({ currentBrand, i18n }) => {
  const [Table] = useTable(TABLE_ID, resources.invoices(currentBrand.id));

  const [sidepanelOpen, setSidepanelOpen] = useState(false);
  const [invoiceID, setInvoiceID] = useState(null);

  const onSidepanelClose = useCallback(() => setSidepanelOpen(false));

  const onSidepanelOpen = ((id) => {
    setInvoiceID(id);
    setSidepanelOpen(true);
  });

  const columnDefs = [
    {
      title: i18n._(t`Date`),
      key: 'created_at',
      render: data => formatDate(data.created_at),
    },
    {
      title: i18n._(t`Status`),
      key: 'status',
      render: data => (
        <Pill
          type={invoiceStatuses[data.status].pill}
        >
          {invoiceStatuses[data.status].title}
        </Pill>
      ),
    },
    {
      title: i18n._(t`Custom ID`),
      key: 'custom_id',
    },
    {
      title: i18n._(t`Address`),
      key: 'address',
      render: data => `${stringTruncate(data.address, 5, 5)}`,
    },
    {
      title: i18n._(t`Name`),
      key: 'name',
    },
    {
      title: i18n._(t`Description`),
      key: 'description',
      render: data => `${truncateText(data.description, 12)}`,
    },
    {
      title: i18n._(t`Invoice amount`),
      key: 'exchanged_amount',
      render: data => `${data.amount} ${data.currency}`,
    },
    {
      title: i18n._(t`Guaranteed price`),
      key: 'original_exchange_rate',
      render: (data) => {
        if (data.original_exchange_rate !== null && data.exchange_symbol) {
          return `${data.original_exchange_rate} ${data.exchange_symbol}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Pay amount`),
      key: 'pay_amount',
      render: data => `${data.pay_amount} ${data.pay_currency}`,
    },
    {
      title: i18n._(t`Deposited amount`),
      key: 'deposited_amount',
      render: (data) => {
        if (data.deposited_amount && data.pay_currency !== 'XYZ') {
          return `${data.deposited_amount} ${data.pay_currency}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Deposit fee amount`),
      key: 'deposit_fee_amount',
      render: (data) => {
        if (data.deposit_fee && data.deposit_fee_currency !== 'XYZ') {
          return `${data.deposit_fee} ${data.deposit_fee_currency}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Exchanged amount`),
      key: 'exchange_amount',
      render: (data) => {
        if (data.exchanged_amount && data.pay_currency !== 'XYZ') {
          return `${data.exchanged_amount} ${data.pay_currency}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Exchange fee amount`),
      key: 'exchange_fee_amount',
      render: (data) => {
        if (data.exchange_fee && data.exchange_fee_currency !== 'XYZ') {
          return `${data.exchange_fee} ${data.exchange_fee_currency}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Credited amount`),
      key: 'credited',
      render: (data) => {
        if (data.credited && data.credited_currency !== 'XYZ') {
          return `${data.credited} ${data.credited_currency}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Refunded amount`),
      key: 'refunded_amount',
      render: (data) => {
        if (data.refunded_amount && data.pay_currency !== 'XYZ') {
          return `${data.refunded_amount} ${data.pay_currency}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Overage amount`),
      key: 'overage_amount',
      render: (data) => {
        if (data.overage && data.pay_currency !== 'XYZ') {
          return `${data.overage} ${data.pay_currency}`;
        }
        return '-';
      },
    },
    {
      title: i18n._(t`Reference`),
      key: 'id',
      render: data => (data.id ? (
        <Button
          onClick={() => onSidepanelOpen(data.id)}
          icon="View"
          color="transparent"
          title={data.id}
        >
          {`${stringTruncate(data.id, 4, 4)}`}
        </Button>
      ) : null),
    },
  ];

  return (
    <div>
      <Header
        title={i18n._(t`Invoice Payment`)}
        icon={<FaIcon round size="regular" name="Notes" color="text-neutral-50" bgColor="bgc-primary-500" />}
        actions={(
          <>
            <Header.Separator />
            <Header.Action
              title="Download as .CSV"
              icon="Download"
              onAction={() => {
                http.get(
                  resources.invoices(currentBrand.id),
                  { headers: { Accept: 'text/csv' } },
                ).then(response => downloadResponse(response));
              }}
            />
          </>
        )}
      />
      <Row>
        <Col>
          <Table
            columnDefs={columnDefs}
            filterable
            fields={filterFields}
            query={{
              ordering: '-created_at',
            }}
          />
        </Col>
      </Row>

      {invoiceID && (
        <InvoiceSidepanel
          onSidepanelClose={onSidepanelClose}
          open={sidepanelOpen}
          brand={currentBrand.id}
          invoiceID={invoiceID}
        />
      )}
    </div>
  );
};

InvoiceHistory.propTypes = propTypes;

export default InvoiceHistory;
