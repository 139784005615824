import React, { useState } from 'react';
import { object } from 'prop-types';
import { Trans, t } from '@lingui/macro';
import Space from 'tc-biq-design-system/build/Space';
import Form from 'App/components/Form';
import { InputText } from 'App/components/Form/Field';
import LandingPageLayout from 'Auth/layouts/LandingPageLayout';
import { email } from 'App/components/Form/validators';
import useApi from 'App/hooks/useApi';
import authService from 'Auth/services/auth';

const propTypes = {
  i18n: object.isRequired,
};

const ResetPassword = ({ i18n }) => {
  const [emailSubmitted, setSubmitted] = useState(false);
  const validators = {
    email: [email({ i18n })],
  };
  const { loading, run } = useApi(authService.requestPasswordReset, () => setSubmitted(true));
  return (
    <LandingPageLayout>
      <div className="form">
        <Form onSubmit={run} loading={loading} validators={validators}>
          {({ submitHandler, loader }) => (
            <form onSubmit={submitHandler}>
              {loader}
              <p className="title48 proxima">
                <Trans>Reset password</Trans>
              </p>
              {emailSubmitted ? (
                <p className="tc-paragraph-regular">
                  <Trans>
                    Request successful. Check your inbox for further
                    instructions.
                  </Trans>
                </p>
              ) : (
                <>
                  <InputText
                    name="email"
                    type="text"
                    label={i18n._(t`Email`)}
                    placeholder={i18n._(t`Enter Email`)}
                  />
                  <Space size={40} />
                  <button
                    className="biq-button primary regular"
                    type="submit"
                    style={{ width: '100%', margin: '0px' }}
                  >
                    <Trans>Reset password</Trans>
                  </button>
                </>
              )}
            </form>
          )}
        </Form>
      </div>
    </LandingPageLayout>
  );
};

ResetPassword.propTypes = propTypes;

export default ResetPassword;
