import { put, takeEvery, call, select } from 'redux-saga/effects';
import { tableActionsGenerator } from 'App/components/Table/generators/model';
import { notifier } from 'tc-biq-design-system/build/Notification';
import { Trans } from '@lingui/macro';
import React from 'react';
import {
  CREATE_BANK_ACCOUNT_ITEM_FAILURE,
  CREATE_BANK_ACCOUNT_ITEM_REQUEST,
  CREATE_BANK_ACCOUNT_ITEM_SUCCESS,
  GET_BANK_ACCOUNT_ITEM_FAILURE,
  GET_BANK_ACCOUNT_ITEM_REQUEST,
  GET_BANK_ACCOUNT_ITEM_SUCCESS,
  UPDATE_BANK_ACCOUNT_ITEM_FAILURE,
  UPDATE_BANK_ACCOUNT_ITEM_REQUEST,
  UPDATE_BANK_ACCOUNT_ITEM_SUCCESS,
} from '../models/bankAccountItem';
import BankAccountService from '../../services/bankAccount';

const bankAccountTableKey = 'BANK_ACCOUNT_TABLE';
const actions = tableActionsGenerator(bankAccountTableKey);

export function* getBankAccountItemSaga({ bid, aid }) {
  try {
    const { data } = yield call(BankAccountService.getItem, { bid, aid });
    yield put({
      type: GET_BANK_ACCOUNT_ITEM_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_BANK_ACCOUNT_ITEM_FAILURE,
      payload: data,
    });
  }
}

export function* createBankAccountItemSaga({ bid, valueData }) {
  try {
    const { data } = yield call(BankAccountService.createItem, [bid, valueData]);
    yield put({
      type: CREATE_BANK_ACCOUNT_ITEM_SUCCESS,
      payload: data,
    });
    yield call(notifier.success, <Trans>Bank account created successfully</Trans>);
    const query = yield select(({ tables }) => tables[bankAccountTableKey].query);
    yield put(actions.fetchTableData(query));
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: CREATE_BANK_ACCOUNT_ITEM_FAILURE,
      payload: data,
    });
  }
}

export function* updateBankAccountItemSaga({ bid, aid, valueData }) {
  try {
    const { data } = yield call(BankAccountService.updateItem, [{ bid, aid }, valueData]);
    yield put({
      type: UPDATE_BANK_ACCOUNT_ITEM_SUCCESS,
      payload: data,
    });
    yield call(notifier.success, <Trans>Success update</Trans>);
    const query = yield select(({ tables }) => tables[bankAccountTableKey].query);
    yield put(actions.fetchTableData(query));
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: UPDATE_BANK_ACCOUNT_ITEM_FAILURE,
      payload: data,
    });
  }
}

export const bankAccountItemSaga = [
  takeEvery(GET_BANK_ACCOUNT_ITEM_REQUEST, getBankAccountItemSaga),
  takeEvery(CREATE_BANK_ACCOUNT_ITEM_REQUEST, createBankAccountItemSaga),
  takeEvery(UPDATE_BANK_ACCOUNT_ITEM_REQUEST, updateBankAccountItemSaga),
];
