import { bindActionCreators, compose } from 'redux';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { actions as bankAccountItemActions } from 'BankAccount/redux/models/bankAccountItem';
import BankAccountSidepanel from './BankAccountSidepanel';

const mapStateToProps = (
  {
    bankAccountItem: { bankAccount, loading, errors, status },
    brand: { currentBrand },
  },
) => ({
  bankAccount, loading, errors, status, currentBrand,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getBankAccount: bankAccountItemActions.getBankAccount,
    createBankAccount: bankAccountItemActions.createBankAccount,
    updateBankAccount: bankAccountItemActions.updateBankAccount,
    clearBankAccount: bankAccountItemActions.clearBankAccount,
  }, dispatch),
});

export default compose(
  withI18n(),
  connect(mapStateToProps, mapDispatchToProps),
)(BankAccountSidepanel);
