import { withI18n } from '@lingui/react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { actions as walletActions } from 'Wallet/redux/models/wallet';
import Wallets from './Wallets';

const mapStateToProps = ({
  wallet: { wallets, loading: walletsLoading },
  brand: { brands, currentBrand } }) => ({
  wallets,
  walletsLoading,
  brands,
  currentBrand,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...walletActions,
    },
    dispatch,
  ),
});

export default compose(
  withI18n(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Wallets);
