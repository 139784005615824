const operators = {
  icontains: { id: 'icontains', label: 'Contains' },
  in: { id: 'in', label: 'In' },
  iexact: { id: 'iexact', label: 'Is' },
  exact: { id: 'exact', label: 'Is' },
  gt: { id: 'gt', label: 'Greater than' },
  lt: { id: 'lt', label: 'Less than' },
  range: { id: 'range', label: 'Between' },
  date: { id: 'date', label: 'Is' },
  not_icontains: { id: 'not_icontains', label: 'Doesn\'t contain' },
  not_in: { id: '!in', label: 'Not in' },
  not_iexact: { id: 'not_iexact', label: 'Is not' },
  not_range: { id: 'not_range', label: 'Not between' },
  not_date: { id: 'not_date', label: 'Is not' },
};

const types = {
  string: 'string',
  number: 'number',
  date: 'date',
};

function operatorsForType(type) {
  switch (type) {
    case types.string:
      return [
        operators.icontains,
        operators.iexact,
      ];
    case types.number:
      return [
        operators.exact,
        operators.gt,
        operators.lt,
      ];
    case types.date:
      return [
        operators.date,
        operators.gt,
        operators.lt,
      ];
    default:
      return [];
  }
}

export { operators, types, operatorsForType };
