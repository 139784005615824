import { compose } from 'redux';
import { connect } from 'react-redux';
import { withI18n } from '@lingui/react';
import BankWithdrawals from './BankWithdrawals';

const mapStateToProps = (
  {
    brand: { currentBrand },
  },
) => ({
  currentBrand,
});

export default compose(
  withI18n(),
  connect(mapStateToProps),
)(BankWithdrawals);
