import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { actions as brandActions } from 'Brand/redux/models/brandItem';

import { withI18n } from '@lingui/react';
import BrandItem from './BrandItem';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getBrand: brandActions.getBrand,
    updateBrand: brandActions.updateBrand,
    createBrand: brandActions.createBrand,
    clearBrand: brandActions.clearBrand,
  }, dispatch),
});

const mapStateToProps = ({ brandItem: { brand, loading, errors } }) => ({
  brand,
  loading,
  errors,
});

export default compose(
  withI18n(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(BrandItem);
