import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withI18n } from '@lingui/react';

import { actions as authActions } from '../../redux/models/auth';
import Login from './Login';

const mapStateToPorps = ({ auth: { token, confirmAuth, loading, errors } }) => ({
  isAuthenticated: !!token,
  confirmAuth,
  loading,
  errors,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      login: authActions.login,
      setConfirmAuth: authActions.setConfirmAuth,
    },
    dispatch,
  ),
});

export default withI18n()(
  connect(
    mapStateToPorps,
    mapDispatchToProps,
  )(Login),
);
