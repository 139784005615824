import React from 'react';
import { t, Trans } from '@lingui/macro';
import { object, string, func } from 'prop-types';
import { capitalize } from 'lodash';
import { notifier } from 'tc-biq-design-system/build/Notification';
import useTable from 'App/components/Table';
import Icon from 'tc-biq-design-system/build/Icon';
import Tooltip from 'tc-biq-design-system/build/Tooltip';
import ActionCellRenderer from 'App/components/TableCells/ActionCell';
import UserCell from 'App/components/TableCells/UserCell';
import BrandsCell from 'App/components/TableCells/BrandsCell';
import SwitchCell from 'App/components/TableCells/SwitchCell';

import UserService from '../../../services/user';

const TABLE_ID = 'USERS_TABLE';

const propTypes = {
  resource: string.isRequired,
  i18n: object.isRequired,
  query: object.isRequired,
  actions: object.isRequired,
  editUser: func.isRequired,
};

const UsersTable = (
  {
    resource,
    i18n,
    query,
    actions,
    editUser,
  },
) => {
  const [Table] = useTable(TABLE_ID, resource);

  const columnDefs = [
    {
      title: '',
      key: 'is_active',
      width: '40px',
      render: data => (SwitchCell({
        data,
        handleClick: userActiveToggle,
      })),
    },
    {
      title: i18n._(t`Full name`),
      key: 'first_name',
      render: data => UserCell({ data }),
    },
    {
      title: i18n._(t`Email`),
      key: 'email',
    },
    {
      title: i18n._(t`Role`),
      key: 'role',
      render: data => (
        <span>{capitalize(data.role)}</span>
      ),
    },
    {
      title: i18n._(t`Brands`),
      key: 'available_brands',
      render: data => <BrandsCell data={data} />,
    },
    {
      title: '',
      key: 'resend_invite',
      width: '30px',
      suppressSizeToFit: true,
      render: data => data.is_invited && !data.has_accepted_invite && (
        <div className="action-wrapper">
          <div
            className="action-item"
            role="button"
            tabIndex={0}
          >
            <Tooltip title="Resend invite">
              <Icon
                name="Pending"
                size="small"
                colorName="text-neutral-900"
                onClick={() => onResendInvite(data.id)}
              />
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      title: '',
      key: 'action',
      suppressSizeToFit: true,
      width: '100px',
      render: data => (
        ActionCellRenderer({
          onDelete,
          onEdit,
          data,
        })
      ),
    },
  ];

  const fields = [
    {
      key: 'full_name',
      label: 'Full name',
      id: 'full_name',
      input: 'string',
      type: 'string',
    },
    {
      key: 'email',
      label: 'Email',
      id: 'email',
      input: 'string',
      type: 'string',
    },
    {
      key: 'role',
      label: 'Role',
      id: 'role',
      input: 'string',
      type: 'string',
    },
  ];

  const onDelete = (id) => {
    UserService.deleteItem(id)
      .then(() => {
        notifier.success(<Trans>User was deleted successfully</Trans>);
        actions.fetchTableData(query);
      })
      .catch(() => {
        notifier.error(<Trans>Delete failed</Trans>);
      });
  };

  const onEdit = (id) => {
    editUser(id);
  };

  const userActiveToggle = (id, value) => {
    UserService.updateItem([id, { is_active: !value }])
      .then(() => {
        notifier.success(<Trans>Success update</Trans>);
        actions.fetchTableData(query);
      })
      .catch(() => {
        notifier.error(<Trans>Update failed</Trans>);
      });
  };

  const onResendInvite = (id) => {
    UserService.resendInvite(id)
      .then(() => {
        notifier.success(<Trans>Invite successfully sent</Trans>);
      })
      .catch(() => {
        notifier.error(<Trans>There was an error sending the invite</Trans>);
      });
  };

  return (
    <Table
      columnDefs={columnDefs}
      fields={fields}
      filterable
    />
  );
};

UsersTable.propTypes = propTypes;

export default UsersTable;
