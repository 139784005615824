import statuses from 'App/constants/statuses';

export const CREATE_WITHDRAW_REQUEST = 'CREATE_WITHDRAW_REQUEST';
export const CREATE_WITHDRAW_SUCCESS = 'CREATE_WITHDRAW_SUCCESS';
export const CREATE_WITHDRAW_FAILURE = 'CREATE_WITHDRAW_FAILURE';

const initialState = {
  withdraw: null,
  loading: false,
  errors: null,
  status: statuses.DEFAULT,
};

const createWithdraw = ({ brandId, valueData }) => ({
  type: CREATE_WITHDRAW_REQUEST,
  brandId,
  valueData,
});

export const actions = {
  createWithdraw,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_WITHDRAW_REQUEST:
      return {
        ...state,
        loading: true,
        status: statuses.LOADING,
      };
    case CREATE_WITHDRAW_SUCCESS:
      return {
        ...state,
        loading: false,
        withdraw: action.payload,
        status: statuses.SUCCESS,
      };
    case CREATE_WITHDRAW_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        status: statuses.ERROR,
      };
    default:
      return state;
  }
}
