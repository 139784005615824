import { create } from 'axios';
import { attachToken, logoutOnLossOfAuth } from 'App/http/interceptors';

const client = create({
  baseURL: process.env.API,
});

client.interceptors.request.use(attachToken);
client.interceptors.response.use(response => response, logoutOnLossOfAuth);

export default client;
