import React from 'react';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { tableActionsGenerator } from 'App/components/Table/generators/model';
import { Trans } from '@lingui/macro';
import { notifier } from 'tc-biq-design-system/build/Notification';
import {
  GET_ADDRESS_FAILURE,
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  CREATE_ADDRESS_FAILURE,
  CREATE_ADDRESS_REQUEST,
  CREATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILURE,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
} from '../models/address';
import AddressService from '../../services/address';

const addressTableKey = 'ADDRESSES_TABLE';
const actions = tableActionsGenerator(addressTableKey);

export function* getChannelSaga({ brandId, walletId, channelId }) {
  try {
    const { data } = yield call(AddressService.getItem, { brandId, walletId, channelId });
    yield put({
      type: GET_ADDRESS_SUCCESS,
      address: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_ADDRESS_FAILURE,
      payload: data,
    });
  }
}

export function* createChannelSaga({ valueData, brandId, walletId }) {
  try {
    const { data } = yield call(AddressService.createItem, [{ brandId, walletId }, valueData]);
    yield put({
      type: CREATE_ADDRESS_SUCCESS,
      address: data,
    });
    yield call(notifier.success, <Trans>Address generated successfully</Trans>);
    const query = yield select(({ tables }) => tables[addressTableKey].query);
    yield put(actions.fetchTableData(query));
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: CREATE_ADDRESS_FAILURE,
      payload: data,
    });
  }
}

export function* updateChannelSaga({ valueData, brandId, walletId, channelId }) {
  try {
    const { data } = yield call(AddressService.updateItem,
      { brandId, walletId, channelId, valueData });
    yield put({
      type: UPDATE_ADDRESS_SUCCESS,
      address: data,
    });
    yield call(notifier.success, <Trans>Success update</Trans>);
    const query = yield select(({ tables }) => tables[addressTableKey].query);
    yield put(actions.fetchTableData(query));
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: UPDATE_ADDRESS_FAILURE,
      payload: data,
    });
  }
}

export const addressSaga = [
  takeEvery(CREATE_ADDRESS_REQUEST, createChannelSaga),
  takeEvery(GET_ADDRESS_REQUEST, getChannelSaga),
  takeEvery(UPDATE_ADDRESS_REQUEST, updateChannelSaga),
];
