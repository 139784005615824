export const GET_BRAND_REQUEST = 'GET_BRAND_REQUEST';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_FAILURE = 'GET_BRAND_FAILURE';
export const UPDATE_BRAND_REQUEST = 'UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_FAILURE = 'UPDATE_BRAND_FAILURE';
export const CREATE_BRAND_REQUEST = 'CREATE_BRAND_REQUEST';
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_FAILURE = 'CREATE_BRAND_FAILURE';

export const CLEAR_BRAND = 'CLEAR_BRAND';

const initialState = {
  brand: null,
  loading: false,
  errors: null,
};

const getBrand = id => ({
  type: GET_BRAND_REQUEST,
  id,
});

const updateBrand = ({ id, valueData }) => ({
  type: UPDATE_BRAND_REQUEST,
  id,
  valueData,
});

const createBrand = valueData => ({
  type: CREATE_BRAND_REQUEST,
  valueData,
});

const clearBrand = () => ({
  type: CLEAR_BRAND,
});

export const actions = {
  getBrand,
  updateBrand,
  createBrand,
  clearBrand,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BRAND_REQUEST:
    case UPDATE_BRAND_REQUEST:
    case CREATE_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case GET_BRAND_SUCCESS:
    case UPDATE_BRAND_SUCCESS:
    case CREATE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        brand: action.brand,
        errors: null,
      };
    case GET_BRAND_FAILURE:
    case UPDATE_BRAND_FAILURE:
    case CREATE_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CLEAR_BRAND:
      return {
        ...state,
        brand: null,
        loading: false,
        errors: null,
      };
    default:
      return state;
  }
}
