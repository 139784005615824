import React from 'react';
import { array, object } from 'prop-types';
import { formatDate } from 'App/helpers/date';
import { uniqueId } from 'lodash';

import './InvoiceHistoryEvents.scss';

const propTypes = {
  events: array.isRequired,
  invoice: object.isRequired,
};

const DefaultHistoryEvent = ({ config, timestamp }) => (
  <>
    <div className="event-history-item-header">
      <span>{config.title}</span>
      <span className="event-history-item-header-right">{formatDate(timestamp)}</span>
    </div>
  </>
);

const PaymentCreated = ({ config, data, timestamp }) => (
  <>
    <div className="event-history-item-header">
      <span>{config.title}</span>
      <span className="event-history-item-header-right">{formatDate(timestamp)}</span>
    </div>
    <div className="event-history-item-content">
      <span>Amount:</span>
      <span className="event-history-item-content-right" style={{ float: 'right' }}>{data.amount} {data.instrumentId}</span>
    </div>
  </>
);

const PaymentConfirmed = ({ config, data, timestamp }) => (
  <>
    <div className="event-history-item-header">
      <span>{config.title}</span>
      <span className="event-history-item-header-right">{formatDate(timestamp)}</span>
    </div>
    <div className="event-history-item-content">
      <span>Amount:</span>
      <span className="event-history-item-content-right" style={{ float: 'right' }}>{data.amount} {data.instrumentId}</span>
    </div>
  </>
);

const InvoiceExchanged = ({ config, data, invoice, timestamp }) => (
  <>
    <div className="event-history-item-header">
      <span>{config.title}</span>
      <span className="event-history-item-header-right">{formatDate(timestamp)}</span>
    </div>
    <div className="event-history-item-content">
      <span>Sold:</span>
      <span className="event-history-item-content-right" style={{ float: 'right' }}>{parseFloat(data.executionSummary.quantityExecuted).toFixed(8)} {invoice.pay_currency}</span>
    </div>
    <div className="event-history-item-content">
      <span>Received:</span>
      <span className="event-history-item-content-right" style={{ float: 'right' }}>{parseFloat(data.executionSummary.orderAmount).toFixed(2)} {invoice.currency}</span>
    </div>
    <div className="event-history-item-content">
      <span>Exchange Rate:</span>
      <span className="event-history-item-content-right" style={{ float: 'right' }}>1 {invoice.pay_currency} = {parseFloat(data.executionSummary.averageTransactionPrice).toFixed(2)} {invoice.currency}</span>
    </div>
  </>
);

const InvoicePriceGuaranteed = ({ config, data, invoice, timestamp }) => (
  <>
    <div className="event-history-item-header">
      <span>{config.title}</span>
      <span className="event-history-item-header-right">{formatDate(timestamp)}</span>
    </div>
    <div className="event-history-item-content">
      <span>Amount:</span>
      <span className="event-history-item-content-right" style={{ float: 'right' }}>{parseFloat(data.guarantee_diff_amount).toFixed(2)} {data.guarantee_diff_currency}</span>
    </div>
    <div className="event-history-item-content">
      <span>Exchange Rate:</span>
      <span className="event-history-item-content-right" style={{ float: 'right' }}>1 {invoice.pay_currency} = {parseFloat(data.guarantee_exchange_rate).toFixed(2)} {invoice.currency}</span>
    </div>
  </>
);


const KeepAsCrypto = ({ config, data, invoice, timestamp }) => (
  <>
    <div className="event-history-item-header">
      <span>{config.title}</span>
      <span className="event-history-item-header-right">{formatDate(timestamp)}</span>
    </div>
    <div className="event-history-item-content">
      <span>Amount:</span>
      <span className="event-history-item-content-right" style={{ float: 'right' }}>{parseFloat(data.amount).toFixed(8)} {invoice.pay_currency}</span>
    </div>
  </>
);

const RefundEvent = ({ config, data, invoice, timestamp }) => (
  <>
    <div className="event-history-item-header">
      <span>{config.title}</span>
      <span className="event-history-item-header-right">{formatDate(timestamp)}</span>
    </div>
    <div className="event-history-item-content">
      <span>Amount:</span>
      <span className="event-history-item-content-right" style={{ float: 'right' }}>{parseFloat(data.amount).toFixed(8)} {data.amount_currency}</span>
    </div>
    <div className="event-history-item-content">
      <span>Address:</span>
      <span className="event-history-item-content-right" style={{ float: 'right' }}>{data.destination_address}</span>
    </div>
  </>
);

const eventConfigs = {
  'invoice.created': {
    color: 'gray',
    title: 'Invoice created',
    component: DefaultHistoryEvent,
  },
  'invoice.payment_created': {
    color: 'blue',
    title: 'Transaction detected',
    component: PaymentCreated,
  },
  'invoice.payment_confirmed': {
    color: 'lightgreen',
    title: 'Transaction confirmed',
    component: PaymentConfirmed,
  },
  'invoice.paid': {
    color: 'lightgreen',
    title: 'Invoice paid',
    component: DefaultHistoryEvent,
  },
  'invoice.completed': {
    color: 'lightgreen',
    title: 'Invoice completed',
    component: DefaultHistoryEvent,
  },
  'invoice.refund_requested': {
    color: 'blue',
    title: 'Invoice refund requested',
    component: RefundEvent,
  },
  'invoice.refunded': {
    color: 'blue',
    title: 'Invoice refunded',
    component: RefundEvent,
  },
  'invoice.refund_rejected': {
    color: 'red',
    title: 'Invoice refund rejected',
    component: DefaultHistoryEvent,
  },
  'invoice.refund_approved': {
    color: 'blue',
    title: 'Invoice refund approved',
    component: DefaultHistoryEvent,
  },
  'invoice.refund_failed': {
    color: 'red',
    title: 'Invoice refund failed',
    component: DefaultHistoryEvent,
  },
  'invoice.refund_cancelled': {
    color: 'blue',
    title: 'Invoice refund cancelled',
    component: DefaultHistoryEvent,
  },
  'invoice.overage_refund_requested': {
    color: 'blue',
    title: 'Overage refund requested',
    component: RefundEvent,
  },
  'invoice.overage_refunded': {
    color: 'blue',
    title: 'Invoice overage refunded',
    component: RefundEvent,
  },
  'invoice.overage_refund_rejected': {
    color: 'red',
    title: 'Invoice overage refund rejected',
    component: DefaultHistoryEvent,
  },
  'invoice.overage_refund_approved': {
    color: 'blue',
    title: 'Invoice overage refund approved',
    component: DefaultHistoryEvent,
  },
  'invoice.overage_refund_failed': {
    color: 'red',
    title: 'Invoice overage refund failed',
    component: DefaultHistoryEvent,
  },
  'invoice.overage_refund_cancelled': {
    color: 'blue',
    title: 'Invoice overage refund cancelled',
    component: DefaultHistoryEvent,
  },
  'invoice.overage_exchanged': {
    color: 'blue',
    title: 'Overage exchanged',
    component: InvoiceExchanged,
  },
  'invoice.overage_exchange_requested': {
    color: 'blue',
    title: 'Overage exchange requested',
    component: DefaultHistoryEvent,
  },
  'invoice.exchanged': {
    color: 'blue',
    title: 'Invoice exchanged',
    component: InvoiceExchanged,
  },
  'invoice.exchange_requested': {
    color: 'blue',
    title: 'Invoice exchange requested',
    component: DefaultHistoryEvent,
  },
  'invoice.price_guaranteed': {
    color: 'blue',
    title: 'Price guarantee correction',
    component: InvoicePriceGuaranteed,
  },
  'invoice.kept_as_crypto': {
    color: 'blue',
    title: 'Invoice kept as crypto',
    component: KeepAsCrypto,
  },
  'invoice.underpaid': {
    color: 'blue',
    title: 'Invoice underpaid',
    component: DefaultHistoryEvent,
  },
  'invoice.overpaid': {
    color: 'blue',
    title: 'Invoice overpaid',
    component: DefaultHistoryEvent,
  },
  'invoice.paid_late': {
    color: 'blue',
    title: 'Invoice paid late',
    component: DefaultHistoryEvent,
  },
  'invoice.cancelled': {
    color: 'red',
    title: 'Invoice cancelled',
    component: DefaultHistoryEvent,
  },
  default: {
    color: 'gray',
    title: 'Invoice event',
    component: DefaultHistoryEvent,
  },
};

const InvoiceHistoryEvent = ({ event, data, invoice, timestamp }) => {
  const eventConfig = eventConfigs[event] || eventConfigs.default;
  const Component = eventConfig.component;
  return (
    <li
      className="event-history-item"
      style={{ color: eventConfig.color }}
    >
      <Component config={eventConfig} data={data} invoice={invoice} timestamp={timestamp} />
    </li>
  );
};

const InvoiceHistoryEvents = ({ events, invoice }) => (
  <ul className="event-history">
    {events.length && events.map(event => (
      <InvoiceHistoryEvent
        key={uniqueId('event_')}
        event={event.event}
        data={event.data}
        invoice={invoice}
        timestamp={event.created_at}
      />
    ))}
  </ul>
);

InvoiceHistoryEvents.propTypes = propTypes;

export default InvoiceHistoryEvents;
