import React from 'react';
import { bool, func, string } from 'prop-types';
import { Sidepanel } from 'tc-biq-design-system/build/Sidepanel';
import { Trans } from '@lingui/macro';
import FaIcon from 'App/components/FaIcon';

const propTypes = {
  open: bool.isRequired,
  title: string,
  icon: string,
  onSidepanelClose: func.isRequired,
};

const defaultProps = {
  title: null,
  icon: null,
};

const AddressSidepanel = ({ open, title, icon, onSidepanelClose }) => (
  <Sidepanel
    className="side-panel"
    hideFooter
    title={<Trans>{title}</Trans>}
    onCloseIconClick={onSidepanelClose}
    avatar={() => (
      <div className="icon-wrapper">
        <FaIcon
          faIcon
          round
          color="text-primary-600"
          bgColor="bgc-primary-200"
          visible={open}
          name={icon}
        />
      </div>
    )}
    visible={open}
  >
    <div>
      Works!!!!
    </div>
  </Sidepanel>
);

AddressSidepanel.propTypes = propTypes;
AddressSidepanel.defaultProps = defaultProps;

export default AddressSidepanel;
