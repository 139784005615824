import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withI18n } from '@lingui/react';
import { actions as authActions } from 'Auth/redux/models/auth';
import NewPassword from './NewPassword';

const mapStateToProps = ({
  auth: { passwordChanged, passwordChangeLoading },
}) => ({
  passwordChanged,
  loading: passwordChangeLoading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setNewPassword: authActions.setNewPassword,
    },
    dispatch,
  ),
});

export default withI18n()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewPassword),
);
