import { put, takeEvery, call } from 'redux-saga/effects';
import UserService from '../../services/user';
import {
  CONFIRM_QRCODE_FAILURE,
  CONFIRM_QRCODE_REQUEST,
  CONFIRM_QRCODE_SUCCESS,
  DELETE_MFA_FAILURE,
  DELETE_MFA_REQUEST,
  DELETE_MFA_SUCCESS,
  GET_QRCODE_FAILURE,
  GET_QRCODE_REQUEST,
  GET_QRCODE_SUCCESS,
  GET_QRCODE_INFO_FAILURE,
  GET_QRCODE_INFO_REQUEST,
  GET_QRCODE_INFO_SUCCESS,
} from '../models/qrCode';

export function* getQRCodeSaga() {
  try {
    const { data } = yield call(UserService.getCode);
    yield put({
      type: GET_QRCODE_SUCCESS,
      payload: data.key,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_QRCODE_FAILURE,
      payload: data,
    });
  }
}

export function* getQRCodeInfoSaga() {
  try {
    const { data } = yield call(UserService.getCodeInfo);
    yield put({
      type: GET_QRCODE_INFO_SUCCESS,
      payload: data.results,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: GET_QRCODE_INFO_FAILURE,
      payload: data,
    });
  }
}

export function* confirmQRCodeSaga({ token }) {
  try {
    const { data } = yield call(UserService.confirmCode, token);
    yield put({
      type: CONFIRM_QRCODE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: CONFIRM_QRCODE_FAILURE,
      payload: data,
    });
  }
}

export function* deleteMfaSaga({ token, id }) {
  try {
    yield call(UserService.deleteCode, { token, id });
    yield put({
      type: DELETE_MFA_SUCCESS,
      payload: false,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({
      type: DELETE_MFA_FAILURE,
      payload: data,
    });
  }
}

export const twoFaAuthSaga = [
  takeEvery(GET_QRCODE_REQUEST, getQRCodeSaga),
  takeEvery(GET_QRCODE_INFO_REQUEST, getQRCodeInfoSaga),
  takeEvery(CONFIRM_QRCODE_REQUEST, confirmQRCodeSaga),
  takeEvery(DELETE_MFA_REQUEST, deleteMfaSaga),
];
