import statuses from 'App/constants/statuses';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const CLEAR_USER = 'CLEAR_USER';

const initialState = {
  user: null,
  loading: false,
  errors: null,
  submitLoading: false,
  submitStatus: statuses.DEFAULT,
  submitErrors: null,
};

const getUser = id => ({
  type: GET_USER_REQUEST,
  id,
});

const updateUser = ({ id, valueData, refreshTable, updateCurrentUser }) => ({
  type: UPDATE_USER_REQUEST,
  id,
  valueData,
  refreshTable,
  updateCurrentUser,
});

const inviteUser = ({ valueData }) => ({
  type: INVITE_USER_REQUEST,
  valueData,
});

const clearUser = () => ({
  type: CLEAR_USER,
});

const changePassword = ({ id, valueData }) => ({
  type: CHANGE_PASSWORD_REQUEST,
  id,
  valueData,
});

export const actions = {
  getUser,
  updateUser,
  inviteUser,
  clearUser,
  changePassword,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_REQUEST:
    case INVITE_USER_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        submitLoading: true,
        submitStatus: statuses.LOADING,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
      };
    case UPDATE_USER_SUCCESS:
    case INVITE_USER_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        user: action.user,
        submitLoading: false,
        submitStatus: statuses.SUCCESS,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case UPDATE_USER_FAILURE:
    case INVITE_USER_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        submitLoading: false,
        submitErrors: action.payload,
        submitStatus: statuses.ERROR,
      };
    case CLEAR_USER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
