import { withI18n } from '@lingui/react';
import { compose } from 'redux';

import { withRouter } from 'react-router';
import PendingWithdrawalsTable from './PendingWithdrawalsTable';

export default compose(
  withI18n(),
  withRouter,
)(PendingWithdrawalsTable);
