import React from 'react';
import Icon from 'tc-biq-design-system/build/Icon';
import { string, func } from 'prop-types';

import './SidebarFooter.scss';

const propTypes = {
  text: string.isRequired,
  onClick: func.isRequired,
};

const SidebarFooter = ({ text, onClick }) => (
  <div role="button" tabIndex="0" className="sidebar-footer" onClick={onClick}>
    <Icon colorName="text-neutral-700" name="Logout" />
    <span className="sidebar-footer__text">{text}</span>
  </div>
);

SidebarFooter.propTypes = propTypes;

export default SidebarFooter;
