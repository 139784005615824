import React, { useCallback } from 'react';
import { bool, object } from 'prop-types';
import Avatar from 'tc-biq-design-system/build/Avatar';
import Header from 'App/components/Header/Header';
import Page from 'App/components/Page';
import Form from 'App/components/Form';
import InputText from 'App/components/Form/Field/InputText';
import { t, Trans } from '@lingui/macro';
import Space from 'tc-biq-design-system/build/Space';
import Button from 'tc-biq-design-system/build/Button';
import { isSameValue, required } from 'App/components/Form/validators';
import FaIcon from 'App/components/FaIcon';
import { displayName, getInitials } from '../../../helpers/user';

import '../Profile.scss';

const propTypes = {
  user: object,
  loading: bool,
  errors: object,
  i18n: object.isRequired,
  actions: object.isRequired,
  history: object.isRequired,
};
const defaultProps = {
  loading: false,
  errors: null,
  user: null,
};

const ChangePassword = ({ user, loading, errors, i18n, actions, history }) => {
  const onSubmit = useCallback((data) => {
    // eslint-disable-next-line
    const { old_password, new_password } = data;
    const valueData = {
      ...user,
      new_password,
      old_password,
    };
    actions.changePassword({
      id: user.id,
      valueData,
    });
  }, []);

  const resetHandler = () => {
    history.push('/user/me/');
  };

  const validators = {
    old_password: [required({ i18n })],
    new_password: [required({ i18n })],
    confirm_password: [required({ i18n }), isSameValue({ i18n, field: 'new_password' })],
  };

  return (
    <>
      <Header
        title={displayName(user)}
        icon={<Avatar initials={getInitials(user)} />}
      />
      <Page wrapped>
        <Form
          onSubmit={onSubmit}
          loading={loading}
          errors={errors}
          validators={validators}
        >
          {({ submitHandler, loader }) => (
            <>
              <Page.Content>
                {loader}
                <h2 className="profile__title">
                  <FaIcon faIcon name="Lock" color="text-primary-600" />
                  {' '}
Change Password
                </h2>
                <Space size={20} />
                <InputText
                  name="old_password"
                  type="password"
                  placeholder={i18n._(t`Old Password`)}
                  label={i18n._(t`Old Password`)}
                />
                <Space size={11} />
                <InputText
                  name="new_password"
                  type="password"
                  placeholder={i18n._(t`New Password`)}
                  label={i18n._(t`New Password`)}
                />
                <Space size={11} />
                <InputText
                  name="confirm_password"
                  type="password"
                  placeholder={i18n._(t`Confirm New Password`)}
                  label={i18n._(t`Confirm New Password`)}
                />
                <Space size={11} />
              </Page.Content>
              <Page.Footer>
                <Button
                  color="ghost"
                  onClick={resetHandler}
                >
                  <Trans>Discard</Trans>
                </Button>
                <Button
                  onClick={submitHandler}
                >
                  <Trans>Update password</Trans>
                </Button>
              </Page.Footer>
            </>
          )}
        </Form>
      </Page>
    </>
  );
};

ChangePassword.propTypes = propTypes;
ChangePassword.defaultProps = defaultProps;

export default ChangePassword;
